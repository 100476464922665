import {
    Badge,
    Button,
    Modal,
    Table,
    Pagination,
    DatePicker,
    Tooltip,
  } from "antd";
  import moment from "moment";
  import React,{useState} from 'react'
import { notify_error } from "../Notify";
import AdminAPI from "../../utils/api/AdminAPI";

function ExportData() {
    const [open, setOpen] = useState(true);
    const [enquiry, setEnquiry] = useState({
        database: "",
        startDate: "",
        lastDate: "",
        
      });
    // CONST []

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setEnquiry({ ...enquiry, [name]: value });
      };

    const handleCancel=()=>{
        setOpen(false);
    }
   const handleOk=()=>{
    if(enquiry.database ==""){
        notify_error("Please Select a database")
        return;
    }
    if(!enquiry.startDate && !enquiry.lastDate){
        notify_error("Please Select a Range or a single date");
        return;
    }
        // console.log(enquiry);
        // console.log(new Date(enquiry.startDate));

        AdminAPI.countAdEnquiry(enquiry).then((result)=>{
            // console.log(result);
        }).catch((error)=>{
            // console.log(error);
        })
        setEnquiry({
            database: "",
            startDate: "",
            lastDate: "",
            
          })

    }

  return (
    <>
    <Button className="bg-primary">EXPORT DATA</Button>
    <Modal
    title="Create a New User"
    open={open}
    onOk={handleOk}
    // confirmLoading={confirmLoading}
    onCancel={handleCancel}
  >
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label htmlFor="database">Database</label>
          <select
            class="form-control"
            style={{ padding: "15px" }}
            id="database"
            name="database"
            onChange={handleOnChange}
          >
            <option selected value={enquiry.database}>
              Select a Database
            </option>
            <option value="walk-in">WALK IN</option>
            <option value="online">WEBSITE ENQUIRY</option>
            <option value="job">JOB APPLICATION</option>
            <option value="contact">CONTACTS</option>
            <option value="ad-page">AD-PAGE</option>
            <option value="law-conference">LAW CONFERENCE</option>
            <option value="icds">ICDS</option>
          </select>
          <span id="show_program" class="err">
            Please Select the database 
          </span>
        </div>
        <div className="form-group">
          <label htmlFor="startDate">starting date</label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            name="startDate"
            placeholder="Enter Your Starting Date"
            onChange={handleOnChange}
            value={enquiry.startDate}
          />
          <span id="show_name" class="err">
            Please Enter starting
          </span>
        </div>
        <div className="form-group">
          <label htmlFor="lastDate">Ending Date</label>
          <input
            type="date"
            className="form-control"
            id="lastDate"
            name="lastDate"
            placeholder="Enter Your Ending Date"
            onChange={handleOnChange}
            value={enquiry.lastDate}
          />
          <span id="show_name" class="err">
            Please Enter Ending Date
          </span>
        </div>
        <div className="form-group">
          <label htmlFor="format">Export Format</label>
          <select
            class="form-control"
            style={{ padding: "15px" }}
            id="format"
            name="format"
            // onChange={handleOnChange}
          >
            <option selected disabled value={enquiry.program} >
               Select a Format
            </option>
            <option selected>CSV</option>
           
          </select>
          <span id="show_password" class="err">
            Please Enter a Format
          </span>
        </div>
      </div>
    </div>
  </Modal>
  </>

  )
}

export default ExportData;
