import React, { useEffect, useState } from "react";

const UllasStudentInfo= ({data}) => {

        return(
                <>
                  {data && data._id && (   <div className="lead-remarks mx-3" style={{marginTop:"96px" , overflowY:"sroll"}}>
                    <div className="">
                        <div className="" style={{fontSize:"22px", fontWeight:"500", paddingLeft:"22px" }}>
                            OTHER INFORMATION
                        </div>
                        <div className="" style={{marginInline:"15px", marginTop:"5px", height:"2px" , backgroundColor:"#E5E9EB", width:"97%"}}> </div>
                    </div>
        
                    <div className="col-md-6 mt-3">
                        
                        <div className="spContent31 mx-3">
                                <div className="lpContent32">Course :</div>
                                <div className="lpContent33">{data.course}</div>
                        </div>
        
                        <div className="spContent31 mx-3">
                                <div className="lpContent32"> Father Name :</div>
                                <div className="lpContent33">{data.fatherName}</div>
                        </div>
        
                        <div className="spContent31 mx-3">
                                <div className="lpContent32"> Institute Name :</div>
                                <div className="lpContent33">{data.instituteName}</div>
                        </div>
        
                        <div className="spContent31 mx-3">
                                <div className="lpContent32">Institute Address :</div>
                                <div className="lpContent33">{data.instituteAddress}</div>
                        </div>
        
                        <div className="spContent31 mx-3">
                                <div className="lpContent32">Institute Number :</div>
                                <div className="lpContent33">{data.instituteNumber}</div>
                        </div>
        
                        <div className="spContent31 mx-3">
                                <div className="lpContent32">Institute Contact Person Name :</div>
                                <div className="lpContent33">{data.instituteContactPersonName}</div>
                        </div>
                        <div className="spContent31 mx-3">
                                <div className="lpContent32">Coordinator Mobile Number :</div>
                                <div className="lpContent33">{data.coordinatorMobileNumber}</div>
                        </div>
                        <div className="spContent31 mx-3">
                                <div className="lpContent32">Coordinator Email :</div>
                                <div className="lpContent33">{data.coordinatorMailid}</div>
                        </div>

                        <div className="spContent31 mx-3">
                            <div className="lpContent32">Selected Activities :</div>
                            <div className="lpContent33">
                                <ol>
                                    {data.selectedActivities.map((activity, index) => (
                                        <li key={index}>{activity}</li>
                                    ))}
                                </ol>
                            </div>
                        </div>
        
                    
                    </div>
                    </div>)}
                </>
            )
}

export default UllasStudentInfo;