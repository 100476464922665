import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from "js-cookie";
const LawAPI = {
  getAllLaw: (params) => {
    // let url = serverAddress + `/api/law/get?page=${params?.page}&limit=${params?.limit}`;
    let url =
      serverAddress +
      `/law/conference/2024/get/all?page=${params?.page}&limit=${params?.limit}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  // send payment link
  sendLinkLaw: (params) => {
    // let url = serverAddress + `/api/law/get/${params}`;
    let url = serverAddress + `/law/conference/2024/get/${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //confirm payment status law
  confirmPaymentLaw: (params) => {
    // let url= `${serverAddress}/api/law/success/?_id=${params}`;
    let url = `${serverAddress}/law/success/?_id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  getOneEnquiry: (params) => {
    // let url=serverAddress+`/api/law/get/${params}`
    let url = serverAddress + `/law/get/${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  // getOneEnquiry

  //updateTransactionID
  updateTransactionId: (id, transactionId) => {
    // let url= `${serverAddress}/api/law/transaction?_id=${id}&transactionId=${transactionId}`;
    let url = `${serverAddress}/law/transaction?_id=${id}&transactionId=${transactionId}`;
    return axios.post(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //law dynamic filter
  dynamicFilter: (params, page, limit) => {
    // let url=serverAddress+`/api/law/dynamicFilters?page=${page}&limit=${limit}`;
    let url = serverAddress + `/law/dynamicFilters?page=${page}&limit=${limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  downloadDynamicFilter: (params, page, limit) => {
    // let url=serverAddress+`/api/law/ExportDynamic?page=${page}&limit=${limit}`;
    let url = serverAddress + `/law/ExportDynamic?page=${page}&limit=${limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  // search in law conference

  searchQuery: (params, page, limit) => {
    // let url=serverAddress+`/api/law/search?page=${page}&limit=${limit}`;
    let url =
      serverAddress + `/law/conference/2024/search?page=${page}&limit=${limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  /* Forget Password */
};

export default LawAPI;
