import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { exportToCSV } from "../../utils/ExportUtils";
import AlumniApi from "../../utils/api/AlumniAPI";

const LLBFilter = ({ isOpen, handleFilterChange, refreshed }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (refreshed) {
      setSelectedCategory(null);
    }
  }, [refreshed]);

  const handleButtonClickProgramCategory = async (category) => {
    if (selectedCategory === category) {
      // Unselect category and call the API for unselection
      // console.log("check 1");
      setSelectedCategory(null);
      handleFilterChange({ params: {} }); // Assuming empty params to unselect
    } else {
      // Select the new category and call the API for selection
      setSelectedCategory(category);
      // console.log("check 2");
      const apiParams = {
        program: "LLB",
        batch: category,
      };
      handleFilterChange({ params: apiParams });
    }
  };

  const handleDownload = (data) => {
    // Data to be exported (replace with your actual data)
    
  
    // Convert data to CSV
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(',')); // Add headers to the CSV
  
    data.forEach(row => {
      const values = headers.map(header => {
        const escaped = `${row[header]}`.replace(/"/g, '""'); // Escape quotes
        return `"${escaped}"`; // Wrap values in double quotes
      });
      csvRows.push(values.join(','));
    });
  
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    // Create a temporary link to download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'LLBdata.csv';
    a.click();
  
    // Clean up
    URL.revokeObjectURL(url);
  };

  const handleExportClick = () => {
    makeApiCalls();
  };

  const makeApiCalls = useCallback(async () => {
    try {
      setIsLoadingData(true);
      setError(null);

      let category = "";
      if (selectedCategory !== null) {
        category = selectedCategory;
      }
      const exportFilter = await AlumniApi.exportFilterByBatch("LLB", category);
      handleDownload(exportFilter.data);

      
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoadingData(false);
    }
  }, [selectedCategory]);

  return (
    <Container
      className="py-3 mx-auto"
      style={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "white",
        boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
        paddingLeft: "30px",
        fontFamily: "Inter",
        paddingRight: "40px",
      }}
    >
      <Row className="team-member-1 text-start my-2">
        <Col>Filter By Batch :</Col>
      </Row>
      <Row className="d-flex row-cols-3">
        {[
          "2005-08",
          "2006-09",
          "2007-10",
          "2008-11",
          "2009-12",
          "2010-13",
          "2011-14",
          "2012-15",
          "2013-16",
          "2014-17",
          "2015-18",
          "2016-19",
          "2017-20",
          "2018-21",
          "2019-22",
          "2020-23",
          "2021-24",
        ].map((category) => (
          <Col className="my-1" key={category}>
            <Badge
              bg=""
              onClick={() => handleButtonClickProgramCategory(category)}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor:
                  selectedCategory === category ? "#663399" : "#F2F2F2",
                color: selectedCategory === category ? "#FFFFFF" : "#663399",
              }}
            >
              {category}
            </Badge>
          </Col>
        ))}
      </Row>
      <button
        style={{
          backgroundColor: "#663399",
          border: "none",
          borderRadius: "8px",
          fontWeight: "500",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "6px",
        }}
        className="mt-3 text-white py-2 px-4"
        onClick={handleExportClick}
      >
        {isLoadingData && (
          <ClipLoader
            color="white"
            size={20}
            loading={isLoadingData}
            speedMultiplier={0.8}
          />
        )}
        {isLoadingData ? "Exporting" : "Export"}
      </button>
      {error && (
        <div style={{ color: "red", marginTop: "10px" }}> Error : {error}</div>
      )}
    </Container>
  );
};

export default LLBFilter;
