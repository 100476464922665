import { combineReducers } from 'redux'
import loginReducers from './loginReducers';
import topLoaderReducer from './topLoaderReducer';
import organisationReducer from './organisationReducer';

const reducers = combineReducers({
    admin_login: loginReducers,
    top_loader: topLoaderReducer,
    organisation:organisationReducer
});

export default reducers;