import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';
const ContactUsAPI = {

    getAllContactUs: (params) => {
        // let url = serverAddress + `/api/contact/us/get-all?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/contact/us/get-all?page=${params?.page}&limit=${params?.limit}`;

        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },
    /* Forget Password */


    searchQuery: (params,page,limit)=>{
        // let url=serverAddress+`/api/contact/us/search?page=${page}&limit=${limit}`;
        let url=serverAddress+`/contact/us/search?page=${page}&limit=${limit}`;

        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },




      //contact dynamic filter
      dynamicFilter:(params,page,limit)=>{
          // let url=serverAddress+`/api/contact/us/dynamicFilters?page=${page}&limit=${limit}`;
          let url=serverAddress+`/contact/us/dynamicFilters?page=${page}&limit=${limit}`;
          return axios.post(url,params,{
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${cookie.get("ajwt")}`,
            },
          })
        },



//download dynamic filter api
///dynamic filter api
downloadDynamicFilter:(params,page,limit)=>{
  // let url=serverAddress+`/api/contact/us/downloadDynamicFilters?page=${page}&limit=${limit}`;
  let url=serverAddress+`/contact/us/downloadDynamicFilters?page=${page}&limit=${limit}`;

  return axios.post(url,params,{
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    },
    responseType: 'blob', 
  })
},

    
};

export default ContactUsAPI;
