import React, { useState, useEffect } from "react";
import AlumniApi from "../../utils/api/AlumniAPI";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { notify_error, notify_success } from "../Notify";
import CreateAlumni from "../CreateAlumni";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Badge } from "antd";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useRef } from "react";
import LLBFilter from "./LLBFilter";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const Llb = () => {
  const navigate = useNavigate();
  const filterPanelRef = useRef(null);
  const [data, setDate] = useState([]);
  const [activepage, SetActivePage] = useState(1);
  const [recperpage, SetRecPerPage] = useState(25);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [search, SetSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [isFilterApplied,setIsFilterApplied]= useState(false);
  const [filterParameters, setFilterParameters]= useState();
  const [isRefreshed, setIsRefreshed]=useState(false);
  let timer;
  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleEnquiryOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };
  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note that months are zero-based, so we add 1
    const year = date.getFullYear().toString(); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  // Use useEffect to fetch alumni data when the component mounts
  const fetchAlumniData = async (params) => {
    // Include the program parameter
    const apiParams = {
      page: params.page, // Pass the page parameter
      limit: params.limit, // Pass the limit parameter
      program: "LLB", // Set the program parameter to 'BBA'
    };

    await AlumniApi.getalumnins(apiParams)
      .then((res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      if (search.trim() === "") {
        // If search is empty, reload the page
        fetchAlumniData({ page: activepage, limit: recperpage });
      }
      AlumniApi.SearchAlumni(
        { search: search, program: "LLB" },
        activepage,
        recperpage
      ).then((res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        SetActivePage(1);
        // console.log(res.data)
      });
    }
  };

  const handleRefresh = () => {
    // Clear search
    SetSearch("");

    // Fetch data again
    fetchAlumniData({ page: activepage, limit: recperpage });
    setIsRefreshed(true)
  };

  // Use useEffect to fetch alumni data when the component mounts
  useEffect(() => {
    if(!isFilterApplied){
      fetchAlumniData({ page: activepage, limit: recperpage });
    }
    else{
      handleFilterChange(filterParameters);
    }
  }, [activepage, recperpage]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      if (value.trim() === "") {
        fetchAlumniData({ page: activepage, limit: recperpage }); // You can define fetchData to handle empty search case
      } else {
        fetchDataWithSearch(value.trim());
      }
    }, 300);
  };

  const fetchDataWithSearch = async (searchTerm) => {
    try {
      // setLoading(true);

      const res = await AlumniApi.SearchAlumni(
        { search: searchTerm, program: "BCA" },
        activepage,
        recperpage
      );

      setDate(res.data.data);
      setTotalData(res.data.total);
      SetActivePage(1);

      // setLoading(false);
    } catch (error) {
      console.error("Error in handleSearch:", error);
      // setLoading(false);
    }
  };

  const handleFilterChange = async (filterParams) => {
    setIsRefreshed(false)
    try {
      // console.log(filterParams);
      if (
        Object.keys(filterParams.params).length === 0 &&
        filterParams.params.constructor === Object
      ) {
        fetchAlumniData({ page: activepage, limit: recperpage });
      } else {
        const res = await AlumniApi.filterAlumni(
          filterParams,
          activepage,
          recperpage
        );
        setDate(res.data.data);
        setTotalData(res.data.total);
        setIsFilterApplied(true);
        setFilterParameters(filterParams);
      }
    } catch (err) {
      notify_error(err.message || "Something Went Wrong");
    }
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  return (
    <>
      <Container
        fluid
        className="p-0 custom-sidebar"
        style={{
          backgroundColor: "#F2F5F7",
          fontFamily: "Inter",
          // transition: "margin-left 0.3s",
          // position: "relative",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                lg={12}
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  {" "}
                  {/* Set width to 20% */}
                  <InputGroup
                    className="my-2"
                    style={{
                      borderRadius: "8px", // Rounded corners
                      border: "1px solid #D1D5DB", // Light border
                      backgroundColor: "#F8F9FB", // Light background
                      height: "43px", // Fixed height
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        height: "100%", // Ensures the icon container takes the full height
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                    </div>

                    <Form.Control
                      placeholder="Search In LLB Alumni by Name, Email and Mobile Number"
                      aria-label="Search"
                      name="search"
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none", // No inner border
                        fontFamily: "Inter",
                        paddingLeft: "0px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        fontSize: "16px", // Adjust font size
                        color: "#6B7280", // Placeholder color
                        height: "100%", // Ensures the input field takes the full height
                        fontSize: "12px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  {/* Button Group */}
                  <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                    {/* Add Alumni Button */}
                    <div style={{ padding: "0", margin: "0" }}>
                      <Button
                        onClick={handleEnquiryOpen}
                        style={{
                          backgroundColor: "#663399",
                          borderColor: "#663399",
                          width: "auto",
                          padding: "12px 16px",
                          height: "43px",
                          whiteSpace: "nowrap",
                          borderRadius: "8px",
                        }}
                      >
                        <i className="fa-solid fa-user-plus me-2"></i>Add Alumni
                      </Button>
                    </div>

                    {/* Filter Button */}
                    <div className="d-flex align-items-center mx-2">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        onClick={toggleFilterPanel}
                        style={{
                          backgroundColor: "#BBC5EA",
                          borderColor: "#BBC5EA",
                          color: "#000000",
                          height: "43px",
                          padding: "11px 15px",
                          borderRadius: "8px",
                          // marginLeft: "12px", // Added margin to give more space between buttons
                        }}
                      >
                        Filter&nbsp;
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>

                    {/* Refresh Icon */}
                    <div className="d-flex align-items-center mx-1">
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="maintable p-0 "
                id="table-container"
                style={{ height: "auto", width: "auto" }}
              >
                {data && data.length > 0 ? (
                  <table className="col-md-12 enquiryTable">
                    <thead className="tableHeadAllEnquiry">
                      <tr className="tableHeading">
                        <th style={{ width: "5%" }}>S.No</th>
                        <th style={{ width: "20%" }}>Name</th>
                        <th style={{ width: "20%" }}>Email</th>
                        {/* <th>Location</th> */}
                        <th style={{ width: "15%" }}>Company</th>
                        <th style={{ width: "15%" }}>Designation</th>
                        {/* <th>Date of birth</th> */}
                        <th style={{ width: "15%" }}>Mobile Number</th>
                        <th style={{ width: "15%" }}>Batch</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#FFFFFF" }}>
                      {data &&
                        data.length > 0 &&
                        data.map((item, index) => (
                          <tr
                            className="alumniTableRows"
                            key={index}
                            style={{ height: "66px" }}
                          >
                            <td>
                              {!item.repeat && <span>{sno + index + 1}</span>}
                              {item.repeat && (
                                <span
                                  style={{
                                    display: "flex",
                                    // position:'relative',
                                    justifyContent: "space-around",
                                    alignItems: "",
                                  }}
                                >
                                  {sno + index + 1}
                                  <Badge.Ribbon
                                    style={{
                                      fontSize: "8px",
                                      right: "-20px",
                                      position: "absolute",
                                    }}
                                    color="#f50"
                                    placement="end"
                                    text="DUP"
                                  />
                                </span>
                              )}
                            </td>

                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.name}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.email_address}
                            </td>
                            {/* <td
                            onClick={() => {
                              // setActiveId(item._id);
                              window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                              // setStep(2);
                            }}
                          >
                            {item.city}
                          </td> */}

                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.company}
                            </td>
                            <td
                              style={{ textTransform: "capitalize" }}
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.designation}
                            </td>
                            {/* <td
                            onClick={() => {
                              // setActiveId(item._id);
                              window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                              // setStep(2);
                            }}
                          >
                            {item.dob}
                          </td> */}
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.mobile_number}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.batch}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div
                    className="text-center my-4"
                    style={{ fontSize: "18px" }}
                  >
                    No data available
                  </div>
                )}
              </div>

              <div className="tableEnd"></div>
              <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    // onChange={(event) => applyFilter(event)}
                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div
                  className="col-md-8 d-flex gap-2"
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                    paddingRight: "60px",
                  }}
                >
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    current={activepage}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {open && <CreateAlumni open={open} course="LLB" setOpen={setOpen} />}

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "76px",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 1s ease-in-out",
        }}
      >
        <LLBFilter
          isOpen={isFilterPanelOpen}
          handleFilterChange={handleFilterChange}
          refreshed={isRefreshed}
        />
      </div>
    </>
  );
};

export default Llb;
