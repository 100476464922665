import React, { useEffect, useState } from "react";
import { serverAddress } from "../../state/constants/content_types";
import LawAPI from "../../utils/api/LawAPI";
import { notify_error, notify_success } from "../Notify";

const StudentInfo = ({ data }) => {
  const [key, setKey] = useState("");
  const [viewPaper, setViewPaper] = useState(false);
  const [abstractVisibility, setAbstractVisibility] = useState(false);

  useEffect(() => {
    console.log(data);
    // console.log(key);
  }, [key]);

  const handleViewAbstract = () => {
    setAbstractVisibility(!abstractVisibility);
  };

  return (
    <>
      {data && data._id && (
        <div
          className="lead-remarks mx-3"
          style={{ marginTop: "96px", height: "100vh", overflowY: "auto" }}
        >
          <div className="">
            <div
              className=""
              style={{
                fontSize: "22px",
                fontWeight: "500",
                paddingLeft: "22px",
              }}
            >
              PERSONAL INFORMATION
            </div>
            <div
              className=""
              style={{
                marginInline: "15px",
                marginTop: "5px",
                height: "2px",
                backgroundColor: "#E5E9EB",
                width: "97%",
              }}
            >
              {" "}
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="spContent31 mx-3">
              <div className="lpContent32">Name :</div>
              <div className="lpContent33">
                {data.firstName} {data.lastName}
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Mobile :</div>
              <div className="lpContent33">{data?.mobile}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">E-mail :</div>
              <div className="lpContent33">{data?.email}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Designation :</div>
              <div className="lpContent33">{data?.designation}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Organization :</div>
              <div className="lpContent33">{data?.organization}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Address :</div>
              <div className="lpContent33">{data?.address}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">City:</div>
              <div className="lpContent33">{data?.city}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">State :</div>
              <div className="lpContent33">{data.state} </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Country :</div>
              <div className="lpContent33">{data?.country}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Category:</div>
              <div className="lpContent33">{data?.category}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Paper Title :</div>
              <div className="lpContent33">{data?.paperTitle}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32 font-bold mb-2">Co-Authors:</div>
              {data?.coauthors?.length > 0 ? (
                data.coauthors.map((coauthor, index) => (
                  <div key={index} className="mb-4">
                    <div className="lpContent33">
                      <strong>Name:</strong> {coauthor.name}
                    </div>
                    <div className="lpContent33">
                      <strong>Email:</strong> {coauthor.email}
                    </div>
                    <div className="lpContent33">
                      <strong>Mobile:</strong> {coauthor.number}
                    </div>
                  </div>
                ))
              ) : (
                <div className="lpContent33">No co-authors available.</div>
              )}
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Abstract Status:</div>
              <div className="lpContent33">
                {data?.abstractStatus === 1
                  ? "Accepted"
                  : data?.abstractStatus === 2
                  ? "Rejected"
                  : "Not Updated"}
              </div>
            </div>
            <div className="d-flex flex-column gap-5">
              <div className="spContent31 mx-3">
                <div className="lpContent32">Abstract :</div>
                <div className="lpContent33">
                  {!abstractVisibility && (
                    <span onClick={() => handleViewAbstract()}>
                      <a href="#" style={{ color: "#663399" }}>
                        View
                      </a>
                    </span>
                  )}
                  {abstractVisibility && (
                    <span onClick={() => handleViewAbstract()}>
                      <a href="#" style={{ color: "#663399" }}>
                        Hide
                      </a>
                    </span>
                  )}
                  |
                  <span>
                    <a
                      target="_blank"
                      href={`${serverAddress}/job_application/preview?file_path=${data.abstract}`}
                      style={{ color: "#663399" }}
                    >
                      Download
                    </a>
                  </span>
                </div>

                {abstractVisibility && (
                  <iframe
                    width="100%"
                    height="900"
                    className="mx-auto d-flex"
                    src={`${serverAddress}/job_application/preview?file_path=${data.abstract}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentInfo;
