import React, { useEffect, useState } from "react";

const StudentProfile = ({ data }) => {
  const [key, setKey] = useState("");

  useEffect(() => {
    console.log(data);
    // console.log(key);
  }, [key]);

  function getFirstWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords;
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  return (
    <>
      {data && data._id && (
        <div
          className="spWrapper  "
          style={{
            height: "400px",
            boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
          }}
        >
          <div className="spContent">
            <div className="spContent01">
            {getFirstWords(data.firstName)}
            {getFirstWords(data.lastName)}
            </div>

            <div className="spContent02">
              {data.firstName} {data.lastName}
            </div>
            <div className="spContent03">+91{data.mobile}</div>
          </div>
          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Created at:</div>
            <div className="spContent33">{data.created_at?.slice(0, 10)}</div>
          </div>

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Payment Status :</div>
            <div className="spContent33">
              {data.paymentStatus ? "Paid" : "Not Paid"}
            </div>
          </div>

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Payment Required :</div>
            <div className="spContent33">{data.amount} INR</div>
          </div>
        </div>
      )}
    </>
  );
};
export default StudentProfile;
