import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import StudentProfile from "./StudentProfile";
import StudentInfo from "./StudentInfoProfile.js";
import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import LawAPI from "../../utils/api/LawAPI";
import { serverAddress } from "../../state/constants/content_types";

const MICProfile = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

   // Function to fetch user data by ID
   const fetchUserData = async (id) => {
    try {
     // setLoading(true); // Start loading
      const response = await fetch(`${serverAddress}/mic-2025/get-one/${id}`); // Assuming this is the correct API endpoint

      if (!response.ok) {
        throw new Error('Failed to fetch user data'); // Handle if the response is not OK
      }

      const result = await response.json(); // Parse the response to JSON
      setData(result); 
      console.log(data);// Set the user data to state
      //setError(null); // Clear any previous errors
    } catch (err) {
     // setError(err.message); // Set error state
      notify_error(err.message || 'Something Went Wrong'); // Show notification
    } finally {
     // setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserData(id); // Fetch the user data when the component mounts or `id` changes
    }
  }, [id]);

  return (
    <div
      className="container-fluid page-body-wrapper"
      style={{
        fontFamily: "Inter",
        position: "relative",
        height: "100vh", // Full height for the container
        display: "flex", // Use flexbox for layout
      }}
    >
      <NavBar />

      {/* Main content area with scroll */}
      <div
        style={{
          flex: 1, // Allow this section to grow and take available space
          display: "flex", // Use flexbox to arrange children
          flexDirection: "column", // Stack elements vertically
          overflowY: "auto", // Enable vertical scrolling
          padding: "20px", // Optional: Add padding for aesthetics
        }}
      >
        {/* Back Button - Fixed Position */}
        <div
          style={{
            position: "absolute", // Position absolutely within the content area
            top: "128px",
            left: "-27px",
            zIndex: "90",
          }}
        >
          <button
            style={{
              border: "none",
              background: "transparent",
              borderRadius: "50%",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/dashboard/mic-2025"); // Change the path as needed
            }}
          >
            <img
              width={"36px"}
              height={"36px"}
              src="/assets/images/dashboard/back-button.png"
              alt="Back"
            />
          </button>
        </div>

        {/* Flex container for side-by-side layout */}
        <div
          style={{
            display: "flex", // Use flexbox for side-by-side layout
            flex: 1, // Allow to fill the remaining space
          }}
        >
          <div style={{ flex: "0 0 25%" }}>
            {" "}
            <StudentProfile data={data} />
          </div>
          <div style={{ flex: "0 0 75%" }}>
            {" "}
            <StudentInfo data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MICProfile;
