import { createContext, useEffect, useState } from 'react';
import OnlineEnquiryAPI from '../../utils/api/OnlineEnquiryAPI';
import { notify_error } from '../../components/Notify';
import { top_loader } from '../action-creators';
import { SetFilter } from 'ag-grid-enterprise';

export const EnquiryContext = createContext();


export default function EnquiryProvider({children}) {
    const [activepage,SetActivePage]=useState(1);
    const [recperpage,SetRecPerPage]=useState(25);
    const [data, setDate] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [step,setStep]=useState(1);
    const [isBack,setIsBack]=useState(false)
    const [activeData,setActiveData]=useState({})


    const [filter,setFilter]=useState([
    { field: "form_location", selected: [] },
    { field: "program", selected: [] },
    { field: "handled_by", selected: [] },
    { field: "assignedTo", selected: [] },
    { field: "admissionStatus", selected: [] },
    {field:"hasNewAssigneeResponded",selected:[]},
    {field:"isReassigned",selected: []},
    { field: "date", selected: { start: "", end: "" } },]);


    
    const [search,SetSearch] = useState("");




    const filterEmptySelected = (arr) => {
        return arr.filter((obj) => {
          if (obj.field === "date" && obj.selected && obj.selected.start !== "" && obj.selected.end !== "") {
            return true; // Retain the "date" object if start and end values are not empty
          }
          if (obj.field === "admissionStatus" && (Array.isArray(obj.selected) && obj.selected.includes("all-enquiry"))) {
             obj.selected.length=0;
            return false; // Empty out the object if admissionStatus includes "all-enquiry"
          }

          if (obj.field === "isReassigned" && (Array.isArray(obj.selected) && obj.selected.includes(false))) {
            obj.selected.length=0;

            const fieldsToEmpty = ["handled_by", "assignedTo"];
            fieldsToEmpty.forEach((field) => {
              const fieldObj = arr.find((f) => f.field === field);
              console.log(fieldObj);
              if (fieldObj) {
                fieldObj.selected.length = 0;
              }
            });
           return false; // Empty out the object if admissionStatus includes "all-enquiry"
         }
          const hasSelectedValues =
            obj.selected &&
            obj.selected.length > 0 &&
            (!Array.isArray(obj.selected) ||
              (Array.isArray(obj.selected) &&
                obj.selected.length > 0 &&
                obj.selected[0] !== null));
          return hasSelectedValues;
        });
      };

      // useEffect(()=>{
      //   applyFilter();
      // },[recperpage])


      function applyFilter(event){
  
        // SetRecPerPage(event.target.value);
        // SetActivePage(1);
        if(search){
            OnlineEnquiryAPI.searchQuery({"search":search},activepage,recperpage).then((res)=>{
                 
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(res.data)
                return;
                
            })
        }

        if(filterEmptySelected(filter).length>0){
            const newData=filterEmptySelected((filter));
            OnlineEnquiryAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(totalData);
                return;
                
              })
        }else{
            OnlineEnquiryAPI.getAllOnlineEnquiry({page:activepage,limit:recperpage}).then((result)=>{
                if(result.data.status){
                    setDate(result.data.data);
                    setTotalData(result.data.total);
                    return;
                

                }else{
                    setDate([]);
                    setTotalData(0)
                    notify_error("Something went wrong")
                    return;
                }

            })
        }

      }

      const handleApplyFilter=()=>{
        const newData=filterEmptySelected((filter));
        SetActivePage(1);
        // console.log(newData);
    
    
        OnlineEnquiryAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
          setDate(res.data.data);
          setTotalData(res.data.total);
          top_loader(true, 100);
          top_loader(false, 0);
          // console.log(totalData);
        })
    
      }

    const handlePageChange=(page)=>{

      if(sessionStorage.getItem('activeData')){
        const activeData = JSON.parse(sessionStorage.getItem('activeData'))
        // window.alert(activeData.activepage)
        SetActivePage(activeData.activepage);
        setFilter(activeData.filter);
        SetRecPerPage(activeData.recperpage);
        sessionStorage.removeItem('activeData')
        if(activeData.search){
          OnlineEnquiryAPI.searchQuery({"search":activeData.search},activeData.activepage,activeData.recperpage).then((res)=>{
               
              setDate(res.data.data);
              setTotalData(res.data.total);
              // console.log(res.data)
              return;
              
          })
      }

      if(filterEmptySelected(activeData.filter).length>0){
          const newData=filterEmptySelected((activeData.filter));
          OnlineEnquiryAPI.dynamicFilter(newData,activeData.activepage,activeData.recperpage).then((res)=>{
              setDate(res.data.data);
              setTotalData(res.data.total);
              // console.log(totalData);
              return;
              
            })
      }else{
          OnlineEnquiryAPI.getAllOnlineEnquiry({page:activeData.activepage,limit:activeData.recperpage}).then((result)=>{
              if(result.data.status){
                  setDate(result.data.data);
                  setTotalData(result.data.total);
                  return;
              

              }else{
                  setDate([]);
                  setTotalData(0)
                  notify_error("Something went wrong")
                  return;
              }

          })
      }
      }else{
        SetActivePage(page);
        if(search){
            OnlineEnquiryAPI.searchQuery({"search":search},activepage,recperpage).then((res)=>{
                 
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(res.data)
                return;
                
            })
        }

        if(filterEmptySelected(filter).length>0){
            const newData=filterEmptySelected((filter));
            OnlineEnquiryAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(totalData);
                return;
                
              })
        }else{
            OnlineEnquiryAPI.getAllOnlineEnquiry({page:activepage,limit:recperpage}).then((result)=>{
                if(result.data.status){
                    setDate(result.data.data);
                    setTotalData(result.data.total);
                    return;
                

                }else{
                    setDate([]);
                    setTotalData(0)
                    notify_error("Something went wrong")
                    return;
                }

            })
        }
      }

    }



  useEffect(()=>{
    const active={
      filter,
      activepage,
      recperpage,
      search
    }
  setActiveData(active)
  handlePageChange(activepage);
  },[activepage,recperpage,filter,search])

    const value={
        activepage,
        recperpage,
        data,
        SetActivePage,
        SetRecPerPage,
        setDate,
        totalData,
        setTotalData,
        filter,
        setFilter,
        search,
        SetSearch,
        handlePageChange,
        applyFilter,
        filterEmptySelected,
        handleApplyFilter,
        step,
        setStep,
        setIsBack,
        isBack,
        activeData
    }

    return (
        <EnquiryContext.Provider value={value}>{children}</EnquiryContext.Provider>
    )


}