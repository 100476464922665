import React from 'react'
import { Checkbox,Col, Row  } from 'antd';


const ViewUllasData = ({props}) => {
    const day_one_activity = ["Gyaat Agyaat (Quiz Competition)","Mr. & Ms. Communicator","Gali Gali Me Shor Hai ( Nukkad Natak)","Racket Rockstars ( Badminton Competition)","Aaj Ka Arjun ( Blind Coding)","Mind Hunters ( Chess Competition)","Ignite The Intellect ( Team Panel Discussion)","Business Haat ( Business Plan Presentation)","Discovery Of IPEM ( Treasure Hunt)","Junk Genius ( Best Use OF Waste))"];
    const day_two_activity = ["Beat The Beats ( Duet/ Group Dance)","Mr. & Ms. Ullas 2023","Racket Rockstars ( Badminton Competition)","Business Haat ( Business Plan Exhibition)","Discovery Of IPEM (Treasure Hunt)"];
    const default_day_one = props.day_one_activity;
    const default_day_two = props.day_two_activity;
  return (
    <>
     <Row>
      <Col span={12}>
      <h5>Day One Activity</h5>
      {day_one_activity.map((activity)=>{
      return(
        <>
        <Checkbox.Group options={[activity]} defaultValue={default_day_one} disabled/>
        <br/>
        </>
      )
        
    })}
      </Col>
      <Col span={12}>
      <h5>Day Two Activity</h5>
      {day_two_activity.map((activity)=>{
      return(
        <>
        <Checkbox.Group options={[activity]} defaultValue={default_day_two} disabled/>
        <br/>
        </>
      )
        
    })}
      </Col>
    </Row>
    
    
    </>
    
  )
}

export default ViewUllasData