import React, { useState } from "react";
import { Button } from "antd";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";

const CsvUploader = ({ fileId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [showFileInput, setShowFileInput] = useState(false); // State to manage visibility of file input

  const handleUploadRemarks = () => {
    setShowFileInput((prev) => !prev); // Show the file input when "Upload Remarks" button is clicked
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Call the API here using the file object and the fileId prop
    // Replace 'api/upload' with the actual API endpoint
    // Make sure the API returns the status in the response
    // For example, the response should be { status: true } on successful upload
    // You can use fetch or any other HTTP library to make the API call
    const formData = new FormData();
    formData.append("file", file);
    formData.append("_id", fileId); // Set the _id from props

    OnlineEnquiryAPI.uploadRemarks(formData)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setUploadStatus("File uploaded successfully.");
          // Empty the input field after successful upload
          setSelectedFile(null);
          window.location.reload();
        } else {
          setUploadStatus("File upload failed.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setUploadStatus("An error occurred while uploading the file.");
      });
  };

  const handleDownloadSample = () => {
    // Replace 'api/download/sample' with the actual API endpoint to download the sample file
    // For example, you can generate the sample file on the server and send it as a response
    // or serve a static sample file stored on the server.
    // The API should set appropriate headers for file download.
    // For simplicity, we'll simulate a download here using a sample CSV content.
    const sampleCsvContent = `remarkData,handler,createdAt\nchanged Something,john cane,12-12-2012\nchanged Something,john cane,12-12-2012`;
    const blob = new Blob([sampleCsvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.csv";
    a.click();
  };

  return (
    <div style={{ marginTop: "50px", height: "auto" }}>
      <Button
        onClick={handleDownloadSample}
        style={{
          backgroundColor: "#ffffff", // Updated color
          borderColor: "#663399", // Updated border color
          color: "#663399", // Text color updated to white
          width: "auto",
          padding: "auto",
          height: "38px",
          // whiteSpace: "nowrap",
          borderRadius: "8px",
        }}
        className="mx-2 btn float-end me-4"
      >
        Download Sample
      </Button>
      <Button
        className="btn float-end me-2"
        style={{
          backgroundColor: "#663399", // Updated color
          borderColor: "#663399", // Updated border color
          color: "white", // Text color updated to white
          width: "auto",
          padding: "auto",
          height: "38px",
          // whiteSpace: "nowrap",
          borderRadius: "8px",
        }}
        onClick={handleUploadRemarks}
      >
        Upload Remarks
      </Button>

      {showFileInput && ( // Render the file input only when showFileInput is true
        <div className="border float-start p-2">
          <input type="file" onChange={handleFileChange} />
          {selectedFile && <p>Selected File: {selectedFile.name}</p>}
        </div>
      )}
      <div className="p-2"> {uploadStatus && <p>{uploadStatus}</p>}</div>
    </div>
  );
};

export default CsvUploader;
