import React, { useState } from "react";
import { Modal, Input, DatePicker, Button } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import { notify_error, notify_success } from "../Notify";
import AlumniApi from "../../utils/api/AlumniAPI";

function EditAlumni({ alumniID, editalumniopen, seteditalumniopen, data }) {
  const [newAlumni, setNewAlumni] = useState({ ...data });
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile_number") {
      // Allow only digits and ensure length is at most 10
      const mobileValue = value.replace(/\D/g, ""); // Remove non-digit characters
      if (mobileValue.length <= 10) {
        setNewAlumni({ ...newAlumni, [name]: mobileValue });
      }
    } else {
      setNewAlumni({ ...newAlumni, [name]: value });
    }
  }
 

  const handleDateChange = (date, dateString) => {
    setNewAlumni({ ...newAlumni, dob: dateString });
  };

  const handleEditDetailsCancel = () => {
    seteditalumniopen(false);
  };

  const handleEnquiryOk = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/;

    if (!emailRegex.test(newAlumni.email_address)) {
      setEmailError(true);
      notify_error("Please enter a valid email address.");
      return; // Prevent form submission if email is invalid
    } else {
      setEmailError(false); // Reset error state if the email is valid
    }

    setLoading(true);

    const updatedData = { ...newAlumni };
    await AlumniApi.UpdateAlumni(alumniID, updatedData).then((result) => {
      if (result.status) {
        console.log(result.data.message);
        notify_success(result.data.message);
        seteditalumniopen(false);
        setLoading(false);
        resetAlummniFormData();
        window.location.reload();
      } else {
        notify_error(result.data.message);
        setLoading({ ...data });
      }
    });
  };

  const resetAlummniFormData = () => {
    setNewAlumni({ ...data });
  };
  return (
    <Modal
      visible={editalumniopen}
      footer={null}
      onCancel={handleEditDetailsCancel}
      style={{
        marginBlock: "auto",
        paddingTop: "20px",
        borderRadius: "44px",
        fontFamily: "Inter",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "32px",
          lineHeight: "38px",
          fontWeight: "700",
        }}
      >
        Edit Alumni Data
      </h2>

      <div className="row">
        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="name">Name</label>
            <Input
              type="text"
              name="name"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.name}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="company">Company</label>
            <Input
              type="text"
              name="company"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.company}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              name="email_address"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.email_address}
            />
            
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="mobile">Mobile</label>
            <Input
              type="text"
              name="mobile_number"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.mobile_number}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="program">Program</label>
            <Input
              type="text"
              name="program"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.program}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="designation">Designation</label>
            <Input
              type="text"
              name="designation"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.designation}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="dob">Date of Birth</label>
            <DatePicker
              name="dob"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              value={newAlumni.dob ? moment(newAlumni.dob) : null}
              onChange={handleDateChange}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="city">City</label>
            <Input
              type="text"
              name="city"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.city}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="state">State</label>
            <Input
              type="text"
              name="state"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.state}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6" style={{ padding: "0 15px" }}>
          <div className="form-group" style={{ marginBottom: "1rem" }}>
            <label htmlFor="batch">Batch</label>
            <Input
              type="text"
              name="batch"
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "1px solid #ced4da",
              }}
              onChange={handleInputChange}
              value={newAlumni.batch}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="d-flex gap-3 align-items-center justify-content-center">
          <div className="">
            <button
              className=" py-2"
              onClick={handleEnquiryOk}
              style={{
                backgroundColor: "#663399",
                borderRadius: "8px",
                color: "white",
                fontFamily: "Inter",
                border: "none",
                fontSize: "15px",
                fontWeight: "700",
                paddingInline: "40px",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              Submit
            </button>
          </div>
          <div className="">
            <button
              onClick={handleEditDetailsCancel}
              className="spButton2"
              style={{
                // backgroundColor: "white",
                // borderRadius: "3px",
                // color: "black",
                fontFamily: "Inter",
                // border: "2px solid",
                fontSize: "15px",
                padding: "6px 40px",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditAlumni;
