import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import Main from "./Main";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import User from "./User";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/index";
import { notify_error, notify_success } from "./Notify";
import cookie from "js-cookie";

import EnquiryTable from "./enquiry/EnquiryTable";
import OnlineEnquiryTable from "./onlineEnquiry/OnlineEnquiryTable";
import ContactUsTable from "./contactUs/ContactUsTable";
import CareerTable from "./career/CareerTable";
import UllasTable from "./ullas/UllasTable";
import IcdsTable from "./Icds/IcdsTable";
import LawTable from "./Law/LawConferenceTable";
import LeadProfile from "./leadProfile";
import LeadProfileCollegeDekho from "./leadProfileCollegeDekho";
import AllEnquiryTable from "./TotalEnquiryTable";
import FilterOnlineEnquiry from "./FilterOnlineEnquiry";
import FilterSideBar from "./FilterSideBar";
import NewLawConferenceTable from "./LawConferenceNew/LawConferenceNewUi";
import LawLeadProfile from "./LawConferenceNew/LeadProfilelawEvent";
import AlumniProfile2 from "./AlumniRegistration2024/AlumniProfile";
import MICProfile from "./mic-2025/MICprofile";
import UllasRegistrationTable from "./ullas/UllasTableNewUi";
import LeadProfileUllas from "./ullas/LeadProfileUllas";
import ContactUsNewTable from "./contactUs/ContactUsTableUi";
import NewCreateUser from "./createUser/CreaterUserUi";
import ICDSNewTable from "./Icds/ICDSTableNewUi";
import AlumniRegistrationttable from "./AlumniRegistration2024/AlumniRegistrationtable";
import MICtable from "./mic-2025/table";

import CreateUser from "./createUser/createUser";
import ExportData from "./exportData/ExportData";
// import FilterLawConference from "./Law/FilterLawConference";
// import NewLawConferenceTable from "./Law/NewLawConferencetable";
import FilterContactUs from "./contactUs/FilterContactus";
import FilterCareerTable from "./career/FilterCareerTable";
import NewCareerTable from "./career/NewCareerTable";
import CareerLeadProfile from "./career/CareerLeadProfile";

import ProgramChart from "./ProgramChart";
import FeedbackTable from "./Feedback/FeedbackTable";
import DetailProfile from "./Feedback/DetailProfile";
import IecegnFilter from "./IecegnConference/IecgnFilter";
import IecegnConferenceTable from "./IecegnConference/IecegnTable";
import IecegnLeadProfile from "./IecegnConference/IecegnLeadProfile";
import IcdsLeadProfile from "./Icds/IcdsLeadProfile";
import FileUploadComponent from "./FileUploadComponent";
import PsbmConferenceTable from "./PsbmConference/PsbmConferenceTable";
import PsbmLeadProfile from "./PsbmConference/PsbmLeadProfile";
import NationalSeminarTable from "./NationalSeminar/NationalSeminarTable";
import JobFairDetails from "./jobfair/JobFairDetails";
import BbaAlumni from "./ALUMNI/BbaAlumni";
import BcaAlumni from "./ALUMNI/BcaAlumni";
import BALLb from "./ALUMNI/BALLb";
import BComLLb from "./ALUMNI/BComLLb";
import Bcom from "./ALUMNI/Bcom";
import Llb from "./ALUMNI/Llb";
import Mba from "./ALUMNI/Mba";
import Mca from "./ALUMNI/Mca";
import Bed from "./ALUMNI/Bed";
import DeledAlumni from "./ALUMNI/DeledAlumni";
import EmploymentHistory from "./ALUMNI/EmploymentHistory";
import AlumniProfile from "./ALUMNI/alumniProfile";
import Nsat from "./NSAT/Nsat";
import CollegeDekhoTable from "./CollegeDekhoTable";
import FDPTable from "./FacultyDevelopmentProgram/FDPTable";
import LeadCountByOrigin from "./LeadCountByOrigin";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);

  const logout = async () => {
    top_loader(true, 10);
    top_loader(true, 70);
    top_loader(true, 90);
    top_loader(true, 100);
    cookie.remove("ajwt");
    cookie.remove("user");
    cookie.remove("role");
    cookie.remove("name");
    cookie.remove("image");
    cookie.remove("firstname");
    cookie.remove("lastname");

    notify_success("Logout successfully...");
    navigate("/");
    top_loader(false, 0);
  };
  let role, feature;

  if (cookie.get("role") !== undefined) {
    // Split the string and join with ', ' if it's not undefined
    role = cookie.get("role").split(",").join(", ");
  } else {
    // Handle the case where the "role" cookie is not set
    role = "Default Role";
  }

  if (cookie.get("feature") !== undefined) {
    feature = cookie.get("feature").split(",").join(",");
  } else {
    feature = "default feature";
  }

  return (
    <>
      <div className="container-fluid page-body-wrapper  ">
        <NavBar logout={logout} admin_name={cookie.get("name")} />
        <SideBar
          name={cookie.get("name")}
          role={role}
          feature={feature}
          style={{ zIndex: "100" }}
        />
        {/* <FilterOnlineEnquiry/> */}
        {/* <FilterSideBar/> */}
        {location.pathname === `/dashboard/` ||
        location.pathname === `/dashboard` ? (
          <Main />
        ) : (
          ""
        )}
        <Routes>
          <Route exact path="/user/" element={<User />} />
          <Route exact path="/view/enquiry" element={<EnquiryTable />} />
          <Route
            exact
            path="/view/online/enquiry"
            element={<OnlineEnquiryTable />}
          />
          <Route exact path="/view/contact/us" element={<ContactUsTable />} />
          <Route exact path="/view/career" element={<CareerTable />} />
          <Route exact path="/view/Ullas" element={<UllasTable />} />
          <Route exact path="/view/Icds" element={<IcdsTable />} />
          <Route
            exact
            path="/view/lawConference"
            element={<NewLawConferenceTable />}
          />
          <Route exact path="/view/CreateUser" element={<CreateUser />} />
          <Route exact path="/export" element={<ExportData />} />
          <Route exact path="/lead-profile/:id" element={<LeadProfile />} />
          <Route
            exact
            path="college-dekho/lead-profile/:id"
            element={<LeadProfileCollegeDekho />}
          />
          <Route exact path="/enquiry-table" element={<AllEnquiryTable />} />
          <Route
            exact
            path="/law-conference"
            element={<NewLawConferenceTable />}
          />
          <Route
            exact
            path="/alumni-registration"
            element={<AlumniRegistrationttable />}
          />
          <Route
            exact
            path="/mic-2025"
            element={<MICtable />}
          />
          <Route exact path="/law-profile/:id" element={<LawLeadProfile />} />
          <Route exact path="/alumniregistrationprofile/:id" element={<AlumniProfile2 />} />
          <Route exact path="/micprofile/:id" element={<MICProfile />} />
          <Route
            exact
            path="/ullas-registration"
            element={<UllasRegistrationTable />}
          />
          <Route
            exact
            path="/student-profile/:id"
            element={<LeadProfileUllas />}
          />
          <Route exact path="/contact-us" element={<ContactUsNewTable />} />
          <Route exact path="/icds" element={<ICDSNewTable />} />
          <Route exact path="/icds-profile/:id" element={<IcdsLeadProfile />} />
          <Route exact path="/admins-corner" element={<NewCreateUser />} />
          <Route exact path="/ContactFilter" element={<FilterContactUs />} />
          <Route exact path="/CareerTable" element={<NewCareerTable />} />
          <Route
            exact
            path="/careerProfile/:id"
            element={<CareerLeadProfile />}
          />
          <Route exact path="/feedback" element={<FeedbackTable />} />
          <Route exact path="/feedback/:id" element={<DetailProfile />} />
          <Route exact path="/job-application" element={<NewCareerTable />} />
          <Route
            exact
            path="/candidate-profile/:id"
            element={<CareerLeadProfile />}
          />
          <Route
            exact
            path="/iecegn-table"
            element={<IecegnConferenceTable />}
          />
          <Route
            exact
            path="/national-seminar"
            element={<NationalSeminarTable />}
          />
          <Route
            exact
            path="/iecegn-profile/:id"
            element={<IecegnLeadProfile />}
          />
          <Route exact path="/psbm-table" element={<PsbmConferenceTable />} />
          <Route exact path="/psbm-profile/:id" element={<PsbmLeadProfile />} />
          <Route exact path="/upload" element={<FileUploadComponent />} />

          <Route exact path="/jobfair-details" element={<JobFairDetails />} />
          <Route exact path="/nsat" element={<Nsat />} />
          <Route
            exact
            path="/college-dekho-table"
            element={<CollegeDekhoTable />}
          />
          <Route
            exact
            path="/faculty-development-table"
            element={<FDPTable />}
          />

          {/* job fair routes */}

          {/* alumni routes */}
          <Route
            exact
            path="/employeeHistory/:id"
            element={<EmploymentHistory />}
          />
          <Route exact path="/bbaAlumni" element={<BbaAlumni />} />
          <Route exact path="/bcaAlumni" element={<BcaAlumni />} />
          <Route exact path="/ballb" element={<BALLb />} />
          <Route exact path="/bcomllb" element={<BComLLb />} />
          <Route exact path="/bcom" element={<Bcom />} />
          <Route exact path="/llb" element={<Llb />} />
          <Route exact path="/mba" element={<Mba />} />
          <Route exact path="/mca" element={<Mca />} />
          <Route exact path="/bed" element={<Bed />} />
          <Route exact path="/deled" element={<DeledAlumni />} />
          <Route exact path="/alumni-profile" element={<AlumniProfile />} />
          <Route
            exact
            path="/lead-count-by-origin"
            element={<LeadCountByOrigin />}
          />
        </Routes>
      </div>
    </>
  );
};

export default Dashboard;
