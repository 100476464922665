import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
// import { useNavigate } from "react-router-dom";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UllasStudentInfo from "./UllasStudentInfo";
import UllasStudentProfile from "./UllasStudentprofile";
import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import UllasAPI from "../../utils/api/UllasAPI";

const LeadProfileUllas = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    console.log("hi");
  }, []);

  useEffect(() => {
    console.log(id);
    UllasAPI.getOneEnquiry(id)
      .then((res) => {
        // console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
    // console.log(id);
  }, []);

  console.log(data);

  return (
    <div
      className="container-fluid page-body-wrapper"
      style={{ fontFamily: "Inter" , position:"relative"}}
    >
      <NavBar />
      <div
        className=""
        style={{
          top: "128px",
          position: "absolute",
          left: "-27px",
          zIndex: "90",
        }}
      >
        <button
          className=""
          style={{
            border: "none",
            background: "transparent",
            borderRadius: "50%",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate("/dashboard/ullas-registration");
          }}
        >
          <img
            width={"36px"}
            height={"36px"}
            src="/assets/images/dashboard/back-button.png"
            alt="Back"
          />
        </button>
      </div>
      <UllasStudentProfile data={data} />
      <UllasStudentInfo data={data} />
    </div>
  );
};

export default LeadProfileUllas;
