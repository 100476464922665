import React, { useEffect, useState } from "react";
import { notify_error, notify_success } from "../Notify";
import { serverAddress } from "../../constant";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";

const UllasStudentProfile = ({data}) => {

  function getFirstWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords;
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }
  

  return (
    <>
      {data && data._id && (
        <div className="spWrapper" style={{boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)"}}>
          <div className="spContent">
            <div className="spContent01">
              {getFirstWords(data.participantsName)}
              
            </div>

            <div className="spContent02">
              {data.participantsName} 
            </div>
            <div className="spContent03">+91{data.contactNumber}</div>
          </div>

          <div className="spContent1">
            <img
              height={"20px"}
              src="/assets/images/dashboard/calender.png"
            ></img>
            <div className="spContent11">Created at:</div>
            <div className="spContent12">{data.created_at?.slice(0, 10)}</div>
          </div>

          <div className="spContent2"></div>

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Email :</div>
            <div className="spContent33">{data.emailAddress}</div>
          </div>

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Amount :</div>
            <div className="spContent33">{data.amount}</div>
          </div>

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Payment Status :</div>
            <div className="spContent33">{data.paymentStatus}</div>
          </div>

          <div className="spContent2 mt-3"></div>

          <div className="spContent4">
            <div className="spContent41">
              <a>
                <img
                  width={"26px"}
                  height={"26px"}
                  src="/assets/images/dashboard/Phone.png"
                ></img>
              </a>
            </div>

            <div className="spContent42"></div>

            {/* <div className="spContent43">
              <div>
                {" "}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    window.open(
                      `${serverAddress}/job_application/preview?file_path=${data.image}`
                    );
                  }}
                  className="downloadResume"
                >
                  Download Resume
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default UllasStudentProfile;
