import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import EnquiryAPI from "../../utils/api/EnquiryAPI";
import UserAPI from "../../utils/api/UserAPI";
import { notify_error, notify_success } from "../Notify";
import { format } from "date-fns";
import { PlusCircleOutlined, RetweetOutlined } from "@ant-design/icons";

import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import moment from "moment";
import "antd/dist/antd.css";
const CreateUser = () => {
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFormValidate, setFormValidate] = useState(false);
  const [enquiry, setEnquiry] = useState({
    role: "",
    username: "",
    firstname: "",
    lastname: "",
    password: "",
  });
  useEffect(() => {
    top_loader(true, 45);
    getAllUsers({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const getAllUsers = (params) => {
    setLoading(true);
    UserAPI.getAllUsers(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        // // console.log(result?.data?.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handleRefresh = () => {
    getAllUsers({ page: activepage, limit: recperpage });
  };

  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  function handlePageChange(page) {
    SetActivePage(page);
    getAllUsers({ page: page, limit: recperpage });
  }

  const handleDelete = (params) => {
    const body = JSON.stringify({ _id: params });
    if (window.confirm("Do you want to Delete this user?")) {
      UserAPI.deleteUser(body)
        .then((result) => {
          // console.log("confirm");
          top_loader(true, 45);
          getAllUsers({ page: activepage, limit: recperpage });
          notify_success(result?.message || "User Successfully Deleted");
        })
        .catch((err) => {
          notify_error(err?.message || err);
        });
    } else {
      return;
    }
  };

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllUsers({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    validateFormData();
    if (isFormValidate) {
      UserAPI.createUser(enquiry).then((result) => {
        if (result.data.status) {
          notify_success(result.data.message);
          setOpen(false);
          setConfirmLoading(false);
          setFormValidate(false);
          resetFormData();
          getAllUsers({ page: activepage, limit: recperpage });
        }
      });
    } else {
      setConfirmLoading(false);
    }
  };
  const validateFormData = () => {
    let check = 0;
    Object.entries(enquiry).forEach(([key, value]) => {
      if ((key && value === undefined) || value === null || value === "") {
        notify_error("Please enter " + key.split("_").join(" "));
        check = 1;
      }
    });
    if (check !== 1) {
      if (isEmail(enquiry.username)) {
        setFormValidate(true);
      } else {
        notify_error("Please enter valid email ");
      }
    }
  };
  const isEmail = (email) => {
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  };
  const validateMobileNumber = (mobileNumber) => {
    const indianRegex = /^[6-9]\d{9}$/;
    if (indianRegex.test(mobileNumber)) {
      return true;
    } else {
      return false;
    }
  };
  const resetFormData = () => {
    setEnquiry({
      role: "",
      username: "",
      firstname: "",
      lastname: "",
      password: "",
    });
  };

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.sno}</span>
          </div>
        </div>
      ),
    },
    {
      title: "ROLE",
      width: 100,
      dataIndex: "role",
      key: "role",
      //   sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => (
        <div className="table-td-div  fw-bold ">
          {record.role === "super_admin" ? (
            <div className="table-td-div-inner text-white bg-success py-2 d-flex justify-content-center">
              <span>{record.role}</span>
            </div>
          ) : (
            <div className="table-td-div-inner  py-2 d-flex justify-content-center">
              <span>{record.role}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "NAME",
      width: 100,
      dataIndex: "name",
      key: "name",
      //   sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{capitalizeWords(record?.name)}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      width: 100,
      dataIndex: "username",
      key: "username",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.username}</span>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Password",
    //   width: 100,
    //   dataIndex: "password",
    //   key: "password",
    //   render: (text, record, index) => (
    //     <div className="table-td-div">
    //       <div className="table-td-div-inner">
    //         <span>{record.password}</span>
    //       </div>
    //     </div>
    //   ),
    // },
    //     {
    //       title: "Program",
    //       width: 100,
    //       dataIndex: "program",
    //       key: "program",
    //       filters: [
    //         {
    //           text: "BBA",
    //           value: "bba"
    //         },
    //         {
    //           text: "BCA",
    //           value: "bca"
    //         },
    //         {
    //           text: "B.COM",
    //           value: "b.com"
    //         },
    //         {
    //           text: "MBA",
    //           value: "mba"
    //         },
    //         {
    //           text: "MCA",
    //           value: "mca"
    //         },
    //         {
    //           text: "B.A.LLB",
    //           value: "b.a.llb"
    //         },  // const color='red'
    //         {
    //           text: "LLB",
    //           value: "llb"
    //         },
    //         {
    //           text: "B.COM LLB",
    //           value: "b.com llb"
    //         },
    //       ],
    //       onFilter: (value, record) => record.program.indexOf(value) === 0,
    //       filterSearch: true,
    //       render: (text, record, index) => (
    //         <div className="table-td-div">
    //           <div className="table-td-div-inner">
    //             <span>{record.program.toUpperCase()}</span>
    //           </div>
    //         </div>
    //       )
    //     },
    //     {
    //       title: "Status",
    //       width: 100,
    //       dataIndex: "is_enquiry_done",
    //       key: "is_enquiry_done",
    //       render: (text, record, index) => (
    //         <div className="table-td-div">
    //           <div className="table-td-div-inner">
    //             <span>
    //               {record.is_enquiry_done ? (
    //                 <Badge
    //                   className="site-badge-count-109"
    //                   count={"Handled"}
    //                   style={{
    //                     backgroundColor: "#52c41a"
    //                   }}
    //                 />
    //               ) : (
    //                 <Badge count={"NA"} color="#faad14" />
    //               )}
    //             </span>
    //           </div>
    //         </div>
    //       )
    //     },
    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
            onChange={(date) =>
              setSelectedKeys(date ? [date.startOf("day").toDate()] : [])
            }
            onClear={() => {
              setSelectedKeys([]);
              clearFilters();
              confirm();
            }}
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
          >
            Filter
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const date = moment(record.created_at).format("yyyy-MM-DD");
        return moment(date).isSame(moment(value).format("yyyy-MM-DD"));
      },
      filters: [
        {
          text: "This week",
          value: [
            moment().startOf("week").toDate(),
            moment().endOf("week").toDate(),
          ],
        },
        {
          text: "This month",
          value: [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ],
        },
      ],
      filterMultiple: false,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "dd/MM/yyyy")}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Delete",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index1) => (
        <div d-flex justify-content-center align-items-center>
          <button
            className="btn btn-danger btn-xs "
            style={{ backgroundColor: "red" }}
            onClick={() => handleDelete(record.operation._id)}
          >
            X
          </button>
        </div>
      ),
    },
  ];

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        name: `${item.firstname} ${item.lastname}`,
        username: item.username,
        password: item.password,
        role: item.role,

        created_at: item.created_at,
        operation: {
          _id: item._id,
        },
      });
    });
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEnquiry({ ...enquiry, [name]: value });
  };
  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title="Create a new User" />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
            <div className="row">
              <div className="col-md-3 form-inline">
                <div className="row align-items-center mb-3">
                  <div className="col-md-4">
                    <label className="control-label">Show :</label>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      onChange={(event) => applyFilter(event)}
                      value={recperpage}
                    >
                      {/* <option value="5">5</option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-4">per page</div>
                </div>
              </div>
              <div className="col-md-9">
                <Tooltip placement="top" title="Create New User" color="lime">
                  <Button
                    type="primary"
                    className="mb-3"
                    style={{ float: "right" }}
                    onClick={showModal}
                    icon={<PlusCircleOutlined />}
                    size={"large"}
                  />
                </Tooltip>
                <Tooltip placement="top" title="refresh" color="geekblue">
                  <Button
                    type="primary"
                    className="mb-3 me-1"
                    style={{ float: "right", backgroundColor: "green" }}
                    onClick={handleRefresh}
                    icon={<i class="fa-solid fa-rotate-right"></i>}
                    size={"large"}
                  />
                </Tooltip>
              </div>
            </div>

            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              //   scroll={{ x: 1500, y: 300 }}
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>

            <Modal
              title="Create a New User"
              open={open}
              onOk={handleOk}
              // confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <select
                      class="form-control"
                      style={{ padding: "15px" }}
                      id="role"
                      name="role"
                      onChange={handleOnChange}
                    >
                      <option selected disabled value={enquiry.program}>
                        Select a Role
                      </option>
                      <option value="super_admin">SUPER-ADMIN</option>
                      <option value="admin">ADMIN</option>
                      <option value="user">USER</option>
                      <option value="admission">ADMISSIONS</option>
                      <option value="reception">RECEPTION</option>
                      <option value="event">EVENT</option>
                      <option value="counsellor">COUNSELLOR</option>
                    </select>
                    <span id="show_program" class="err">
                      Please Select a Role
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="firstname"
                      placeholder="Enter Your First Name"
                      onChange={handleOnChange}
                      value={enquiry.firstname}
                    />
                    <span id="show_name" class="err">
                      Please Enter FIRST Name
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="lastname"
                      placeholder="Enter Your Last Name"
                      onChange={handleOnChange}
                      value={enquiry.lastname}
                    />
                    <span id="show_name" class="err">
                      Please Enter Last Name
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="username"
                      placeholder="Enter email"
                      onChange={handleOnChange}
                      value={enquiry.email}
                    />
                    <span id="show_email" class="err">
                      Please Enter Email
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="password"
                      onChange={handleOnChange}
                      value={enquiry.password}
                    />
                    <span id="show_password" class="err">
                      Please Enter a Password
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
