import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import IcdsAPI from "../../utils/api/IcdsAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import { IcdsContext } from "../../state/context/IcdsProvider";
import GradingIcon from "@mui/icons-material/Grading";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { notify_error, notify_success } from "../Notify";
import { useRef } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import IcdsFilter from "./IcdsFilter";

const ICDSNewTable = ({ collapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    filter,
    setFilter,
    search,
    SetSearch,
    recperpage,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(IcdsContext);
  const filterPanelRef = useRef(null);
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  // const [recperpage, SetRecPerPage] = useState(25);
  // const [activepage, SetActivePage] = useState(1);
  // const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  // const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [paymentEmail, setPaymentEmail] = useState("");

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };
  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    top_loader(true, 45);
    getAllIcds({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const handleRefresh = () => {
    top_loader(true, 45);
    getAllIcds({ page: activepage, limit: recperpage });
  };

  const getAllIcds = (params) => {
    setLoading(true);
    IcdsAPI.getAllIcds(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handlePaymentStatus = (_id, email) => {
    setOpen(true);
    setCandidateId(_id);
    setPaymentEmail(email);
  };

  const handleApprove = (id, email) => {
    IcdsAPI.approveAbstract(id, email)
      .then((res) => {
        if (res.status) {
          notify_success("Successfully approved");
          top_loader(true, 45);
          getAllIcds({ page: activepage, limit: recperpage });
        } else {
          notify_error("Error:Couldn't Update Approval status ");
        }
      })
      .catch((err) => {
        notify_error(err.message);
      });
  };

  const handleReject = (id, email) => {
    IcdsAPI.rejectAbstract(id, email)
      .then((res) => {
        if (res.status) {
          notify_success("Successfully Rejected Following Abstract");
          top_loader(true, 45);
          getAllIcds({ page: activepage, limit: recperpage });
        } else {
          notify_error("Error:Couldn't Update Approval status ");
        }
      })
      .catch((err) => {
        notify_error(err.message);
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };
  function verifyPaytmTransactionId(transactionId) {
    const regex = /^.{9,33}$/;

    return regex.test(transactionId);
  }

  function transactionVerification(params) {
    if (verifyPaytmTransactionId(params)) {
      return true;
    } else {
      return false;
    }
  }

  const handleOk = () => {
    setConfirmLoading(true);

    if (transactionId) {
      const validate = transactionVerification(transactionId);

      if (validate) {
        IcdsAPI.updateTransactionId(candidateId, transactionId)
          .then((result) => {
            // // console.log('confirm');
            IcdsAPI.confirmPaymentICDS(candidateId)
              .then((result) => {
                IcdsAPI.paymentEmailIcds(candidateId, paymentEmail)
                  .then((result) => {
                    top_loader(true, 45);
                    getAllIcds({ page: activepage, limit: recperpage });

                    setCandidateId("");
                    setTransactionId("");
                    notify_success(
                      "Candidate Payment Status Updated Successfully."
                    );
                    setOpen(false);
                  })
                  .catch((err) => {
                    notify_error(err?.message || err);
                  });
              })
              .catch((err) => {
                notify_error(err?.message || err);
              });
          })
          .catch((err) => {
            notify_error(err?.message || err);
          });
      } else {
        notify_error("Not a Valid transaction ID");
      }
    }
    // validateFormData();
    // if (isFormValidate) {
    //   UserAPI.createUser(enquiry).then((result) => {
    //     if (result.data.status) {
    //       notify_success(result.data.message);
    //       setOpen(false);
    //       setConfirmLoading(false);
    //       setFormValidate(false);
    //       resetFormData();
    //       getAllUsers({ page: activepage, limit: recperpage });
    //     }
    //   });
    // } else {
    //   setConfirmLoading(false);
    // }
  };

  const handleOnChange = (e) => {
    setTransactionId(e.target.value);
  };

  const handleSearch = () => {
    IcdsAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res.data)
        return;
      }
    );
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);

    // If the search field is emptied, reload all records
    if (value.trim() === "") {
      getAllIcds({ page: activepage, limit: recperpage });
    }
  };

  return (
    <>
      <Container
        fluid
        className="p-0 custom-sidebar"
        style={{
          backgroundColor: "#F2F5F7",
          fontFamily: "Inter",
          // transition: "margin-left 0.3s",
          // position: "relative",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "60px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  {" "}
                  {/* Set width to 20% */}
                  <InputGroup
                    className="my-2"
                    style={{
                      borderRadius: "8px", // Rounded corners
                      border: "1px solid #D1D5DB", // Light border
                      backgroundColor: "#F8F9FB", // Light background
                      height: "43px", // Fixed height
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        height: "100%", // Ensures the icon container takes the full height
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                    </div>

                    <Form.Control
                      placeholder="Search In ICDS Table by Name , Email , Mobile"
                      aria-label="Search"
                      name="search"
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none", // No inner border
                        fontFamily: "Inter",
                        paddingLeft: "0px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        fontSize: "16px", // Adjust font size
                        color: "#6B7280", // Placeholder color
                        height: "100%", // Ensures the input field takes the full height
                        fontSize: "12px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  {/* Button Group */}
                  <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                    {/* Filter Button */}
                    <div className="d-flex align-items-center mx-2">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        onClick={toggleFilterPanel}
                        style={{
                          backgroundColor: "#BBC5EA",
                          borderColor: "#BBC5EA",
                          color: "#000000",
                          height: "43px",
                          padding: "11px 15px",
                          borderRadius: "8px",
                          // marginLeft: "12px", // Added margin to give more space between buttons
                        }}
                      >
                        Filter&nbsp;
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>

                    {/* Refresh Icon */}
                    <div className="d-flex align-items-center mx-1">
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "auto", width: "auto" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  <thead className="bg-transparent ">
                    <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th style={{ width: "20%" }}>Institute</th>

                      <th style={{ width: "10%" }}>Payment Status</th>
                      <th style={{ width: "15%" }}>Action</th>
                      <th>Created At</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            <tr
                              className="tableRows "
                              key={item._id}
                              onClick={() =>
                                navigate(`/dashboard/icds-profile/${item._id}`)
                              }
                            >
                              <td>{sno + index + 1}</td>
                              <td>{`${item.first_name} ${item.last_name}`}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile_no}</td>
                              <td className="">{item.institute}</td>
                              {item.isPaymentDone === false && (
                                <td className="">
                                  <span
                                    className=""
                                    style={{
                                      border: "1px solid #FFE3E0",
                                      backgroundColor: "#FFE3E0",
                                      color: "#F7685B",
                                      paddingTop: "3px",
                                      paddingBottom: "3px",
                                      paddingLeft: "13px",
                                      paddingRight: "13px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Not Paid
                                  </span>
                                </td>
                              )}
                              {item.isPaymentDone === true && (
                                <td className="">
                                  <span
                                    className=""
                                    style={{
                                      border: "1px solid #CAEEDB",
                                      backgroundColor: "#CAEEDB",
                                      color: "#46CF85",
                                      paddingTop: "3px",
                                      paddingBottom: "3px",
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Payment Done
                                  </span>
                                </td>
                              )}

                              <td className=" ">
                                {!item.isPaymentDone && (
                                  <Tooltip
                                    placement="top"
                                    title="Confirm Payment"
                                  >
                                    <span
                                      className="mx-2 px-1 "
                                      style={{
                                        paddingBlock: "10px",
                                        backgroundColor: "#2B73FF",
                                        border: "none",
                                        color: "white",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                        lineHeight: "35px",
                                        borderRadius: "4px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handlePaymentStatus(
                                          item._id,
                                          item.email
                                        );
                                      }}
                                    >
                                      <CreditScoreIcon />
                                    </span>
                                  </Tooltip>
                                )}
                                {item.abstractStatus == null ||
                                  (item.abstractStatus == 0 &&
                                    item.category != "Attendee" && (
                                      <Tooltip
                                        placement="top"
                                        title="Approve Abstract"
                                      >
                                        <span
                                          className="mx-2 px-1 "
                                          style={{
                                            paddingBlock: "10px",
                                            backgroundColor: "lightseagreen",
                                            border: "none",
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "35px",
                                            borderRadius: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleApprove(item._id, item.email);
                                          }}
                                        >
                                          <GradingIcon />
                                        </span>
                                      </Tooltip>
                                    ))}
                                {item.abstractStatus == null ||
                                  (item.abstractStatus == 0 &&
                                    item.category != "Attendee" && (
                                      <Tooltip
                                        placement="top"
                                        title="Reject Abstract"
                                      >
                                        <span
                                          className="mx-2 px-1 "
                                          style={{
                                            paddingBlock: "10px",
                                            backgroundColor: "lightcoral",
                                            border: "none",
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "35px",
                                            borderRadius: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleReject(item._id, item.email);
                                          }}
                                        >
                                          <PlaylistRemoveIcon />
                                        </span>
                                      </Tooltip>
                                    ))}

                                {item.abstractStatus == 1 && (
                                  <>
                                    <span className="text-success">
                                      Approved
                                    </span>
                                  </>
                                )}
                                {item.abstractStatus == 2 && (
                                  <>
                                    <span className="text-danger">
                                      Rejected
                                    </span>
                                  </>
                                )}
                              </td>
                              <td>
                                {format(
                                  new Date(item.created_at),
                                  "dd/MM/yyyy"
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div className="tableEnd "></div>

              <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    // onChange={(event) => applyFilter(event)}
                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div
                  className="col-md-8 d-flex gap-2"
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                    paddingRight: "60px",
                  }}
                >
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    current={activepage}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          style={{ fontFamily: "Inter" }}
          open={open}
          footer={null}
          onOk={() => handleOk(false)}
          onCancel={() => handleCancel(false)}
        >
          <div className="mb-3  col-md-10 mx-auto bg-transparent">
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "24px",
                lineHeight: "38px",
                fontWeight: "700",
              }}
            >
              Transaction Id Verification
            </h2>
            <div className="form-group mt-4">
              <label htmlFor="last_name">Transaction Id</label>
              <input
                type="text"
                style={{ border: "2px solid", borderRadius: "12px" }}
                className="form-control border-secondary"
                id="transactionId"
                name="transactionId"
                placeholder="Please Enter Transaction Id"
                onChange={handleOnChange}
                value={transactionId}
              />
              <span id="show_name" class="err">
                Please Enter Transaction ID Name
              </span>
            </div>

            <div className="text-center">
              <button
                className=" py-2 "
                onClick={handleOk}
                style={{
                  backgroundColor: "#663399",
                  borderRadius: "8px",
                  color: "white",
                  fontFamily: "Inter",
                  border: "none",
                  fontSize: "15px",
                  fontWeight: "700",
                  paddingInline: "40px",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      </Container>

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <IcdsFilter />
      </div>
    </>
  );
};

export default ICDSNewTable;
