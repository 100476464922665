import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import CareerAPI from "../../utils/api/CareersAPI";
import { format } from "date-fns";
import { PlusCircleOutlined , RetweetOutlined} from "@ant-design/icons";
import {  Tooltip } from 'antd';

import {
  MoreOutlined,
  EyeOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";
import { notify_error, notify_success } from "../Notify";
import { Table, Pagination, Dropdown, Space, Menu,Button} from "antd";
import { serverAddress } from "../../constant";
import "antd/dist/antd.css";
const CareerTable = () => {
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    top_loader(true, 45);
    getAllCareer({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  const handleRefresh=() => {
    // top_loader(true, 45);
    getAllCareer({ page: activepage, limit: recperpage });
  }

  const getAllCareer = (params) => {
    setLoading(true);
    CareerAPI.getAllCareer(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  function handlePageChange(page) {
    SetActivePage(page);
    getAllCareer({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllCareer({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      fixed: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.sno}</span>
          </div>
        </div>
      )
    },
    {
      title: "Name",
      width: 100,
      dataIndex: "firstname",
      key: "firstname",
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>
              {record.firstname} {record.lastname}
            </span>
          </div>
        </div>
      )
    },
    {
      title: "Email",
      width: 100,
      dataIndex: "email",
      key: "email",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.email}</span>
          </div>
        </div>
      )
    },
    {
      title: "Mobile",
      width: 100,
      dataIndex: "contactno",
      key: "contactno",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.contactno}</span>
          </div>
        </div>
      )
    },
    {
      title: "Current Emp. Name",
      width: 100,
      dataIndex: "current_employer_name",
      key: "current_employer_name",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.current_employer_name}</span>
          </div>
        </div>
      )
    },
    {
      title: "Current Designation",
      width: 100,
      dataIndex: "current_designation",
      key: "current_designation",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.current_designation}</span>
          </div>
        </div>
      )
    },
    {
      title: "Duration",
      width: 100,
      dataIndex: "duration",
      key: "duration",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.duration}</span>
          </div>
        </div>
      )
    },
    {
      title: "Current CTC",
      width: 100,
      dataIndex: "current_ctc",
      key: "current_ctc",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.current_ctc}</span>
          </div>
        </div>
      )
    },
    {
      title: "Total Exeperience",
      width: 100,
      dataIndex: "total_experience",
      key: "total_experience",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.total_experience}</span>
          </div>
        </div>
      )
    },
    {
      title: "Notice Period",
      width: 100,
      dataIndex: "notice_period",
      key: "notice_period",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.notice_period.split("_").join(" ")}</span>
          </div>
        </div>
      )
    },
    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      fixed: "right",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "dd/MM/yyyy")}</span>
          </div>
        </div>
      )
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index1) => (
        <div>
          <Dropdown overlay={menu(record)}>
            <a onClick={(e) => e.preventDefault()}>
              <Space size={"small"}>
                <MoreOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      )
    }
  ];

  const menu = (record) => (
    <Menu
      items={[
        {
          key: "1",
          label: "Preview",
          icon: <EyeOutlined />,
          onClick: () => {
            previewResume(record.operation.image);
          }
        },
        {
          key: "2",
          label: "Download Resume",
          icon: <CloudDownloadOutlined />,
          onClick:()=>{
            downloadResume(record.operation.downloadLink)
          }
        }
      ]}
    />
  );
  const previewResume = (value) => {
    window.open(`${serverAddress}/job_application/preview?file_path=${value}`)
  };
  const downloadResume = (downloadLink) => {
    window.open(downloadLink)
  };

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        college: item.college,
        applied_position: item.applied_position,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        contactno: item.contactno,
        current_employer_name: item.experience.current_employer_name,
        current_designation: item.experience.current_designation,
        duration: item.experience.duration,
        current_ctc: item.experience.current_ctc,
        total_experience: item.experience.total_experience,
        notice_period: item.experience.notice_period,
        created_at: item.created_at,
        operation: {
          _id: item._id,
          image: item.image,
          downloadLink: item.downloadLink,
        }
      });
    });
  }
  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title="Online Enquiry List" />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
          <div className="row">
            <div className="col-md-3 form-inline">
              <div className="row align-items-center mb-3">
                <div className="col-md-4">
                  <label className="control-label">Show :</label>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(event) => applyFilter(event)}
                    value={recperpage}
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-4">per page</div>
              </div>
            </div>
            <div className="col-md-9">
            <Tooltip placement="top" title="refresh" color="geekblue">
              <Button
                  type="primary"
                  className="mb-3 me-1"
                  style={{ float: "right",backgroundColor:'green'}}
                  onClick={handleRefresh}
                  icon={<i class="fa-solid fa-rotate-right"></i>}
                  size={"large"}
                />
                </Tooltip>
              
              </div>


            </div>
            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              scroll={{ x: 1500, y: 500 }}
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerTable;
