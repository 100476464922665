import { toast } from 'react-toastify';

const notify_success = (msg)=>{
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT
         });
    }
const notify_error = (msg)=>{
        toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT
         });
    }
  
export {notify_error,notify_success};

