import React from "react";
import { useEffect } from "react";
import PageHeader from "./PageHeader";
import StretchCard from "./StretchCard";
import Graph from "./Graph";
import RecentTickets from "./RecentTickets";
import RecentUpdate from "./RecentUpdate";
import ProjectStatus from "./ProjectStatus";
import ChartData from "./Chart";

const Main = () => {
  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  return (
    <>
      <div
        className="main-panel mt-2 custom-sidebar"
        style={{ fontFamily: "Inter", height: "100vh", overflowY: "auto" }}
      >
        <div className="content-wrapper">
          {/* <div className="dashboard-1">
          <span className="dashboard-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home" />
          </span>
          <PageHeader title="Dashboard"/>

          </div> */}
          <div
            className="content-wrapper"
            // style={{ height: "100vh", overflowY: "auto" }}
          >
            <StretchCard />
          </div>
          <footer className="footer mt-4">
            <div className="container-fluid d-flex justify-content-center">
              <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">
                Copyright ©{" "}
                <a
                  href="https://assertit.io/"
                  style={{ color: "inherit", textDecoration: "underline" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AssertIT Solutions 2022
                </a>
              </span>
            </div>
          </footer>
        </div>

        {/* <Graph/> */}
        {/* <RecentTickets/> */}
        {/* <RecentUpdate/> */}
        {/* <ProjectStatus/> */}

        {/* content-wrapper ends */}
        {/* partial:partials/_footer.html */}

        {/* partial */}
      </div>
    </>
  );
};

export default Main;
