import React, { useEffect, useState } from "react";
import CareerAPI from "../../utils/api/CareersAPI";

const CareerProfileInfo = ({data}) => {
    const [key,setKey]=useState('')

    useEffect(()=>{
        // console.log(data);
        // console.log(key);
    },[key]);

    return(
        <>
          {data && data._id && (   <div className="lead-remarks mx-3" style={{marginTop:"96px"}}>
            <div className="">
                <div className="" style={{fontSize:"22px", fontWeight:"500", paddingLeft:"22px" }}>
                    OTHER INFORMATION
                </div>
                <div className="" style={{marginInline:"15px", marginTop:"5px", height:"2px" , backgroundColor:"#E5E9EB", width:"97%"}}> </div>
            </div>

            <div className="col-md-6 mt-3">
                
                <div className="spContent31 mx-3">
                        <div className="lpContent32">Position Applied For :</div>
                        <div className="lpContent33">{data.applied_position}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32"> Current Emp. Name :</div>
                        <div className="lpContent33">{data.experience.current_employer_name}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32"> Current Designation :</div>
                        <div className="lpContent33">{data.experience.current_designation}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Duration :</div>
                        <div className="lpContent33">{data.experience.duration}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Current CTC :</div>
                        <div className="lpContent33">{data.experience.current_ctc}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Total Experience  :</div>
                        <div className="lpContent33">{data.experience.total_experience}</div>
                </div>
                <div className="spContent31 mx-3">
                        <div className="lpContent32">Notice Period :</div>
                        <div className="lpContent33">{data.experience.notice_period}</div>
                </div>

            
            </div>
            </div>)}
        </>
    )
}

export default CareerProfileInfo;