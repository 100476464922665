import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';
const UllasAPI = {

    getAllUllas: (params) => {
        // let url = serverAddress + `/api/ullash/get?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/ullas/2024/get/all?page=${params?.page}&limit=${params?.limit}`;
        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },
    /* Forget Password */

    getOneEnquiry: (id) => {
        // let url = serverAddress + `/api/law/get/${params}`;
        let url = serverAddress + `/ullas/2024/get?_id=${id}`;
        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },
};

export default UllasAPI;
