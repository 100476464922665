import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import { notify_error, notify_success } from "../Notify";
import AlumniApi from "../../utils/api/AlumniAPI";

const CreateEmployeeHis = ({ open, setOpen, id }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isFormValidate, setFormValidate] = useState(false);

  const [employeeform, setEmployeeForm] = useState({
    company_name: "",
    start_date: "",
    end_date: "",
    designation: "",
    location: "",
  });

  const handleOnEnquiryChange = (e) => {
    const { name, value } = e.target;
    setEmployeeForm({ ...employeeform, [name]: value });
    // console.log(newAlumni);
  };

  const handleEnquiryOk = () => {
    // Check if any required field is empty
    if (
      !employeeform.company_name ||
      !employeeform.start_date ||
      !employeeform.end_date
    ) {
      notify_error("Please fill in all required fields.");
      return; // Prevent form submission
    }

    setConfirmLoading(true);
    console.log(employeeform);
    AlumniApi.createEmployeeHistory(employeeform, id).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        setOpen(false);
        setConfirmLoading(false);
        resetEnquiryFormData();
        window.location.reload();
      } else {
        notify_error(result.data.message);
        setConfirmLoading(false);
      }
    });
  };

  const handleEnquiryCancel = () => {
    setOpen(false);
  };

  const resetEnquiryFormData = () => {
    setEmployeeForm({
      company_name: "",
      start_date: "",
      end_date: "",
      designation: "",
      location: "",
    });
  };

  return (
    <div>
      <Modal
        visible={open}
        footer={null}
        onCancel={handleEnquiryCancel}
        style={{
          marginBlock: "auto",
          paddingTop: "20px",
          borderRadius: "44px",
          fontFamily: "Inter",
        }}
      >
        <div className="row">
          <h2
            style={{
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "38px",
              fontWeight: "700",
            }}
          >
            Add Employement History
          </h2>
        </div>

        <div className="row">
          <div className="mb-3 col-md-6 mx-auto bg-transparent">
            <div className="form-group">
              <label>Start Date</label>
              <input
                type="date"
                className="form-control"
                id="start_date"
                name="start_date"
                placeholder="Enter start date"
                onChange={handleOnEnquiryChange}
                value={employeeform.start_date}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "14px",
                  borderRadius: "6px", // Border-radius of 6px
                  border: "1px solid #ced4da",
                }}
              />
            </div>

            <div className="form-group">
              <label>End Date</label>
              <input
                type="date"
                className="form-control"
                id="end_date"
                name="end_date"
                placeholder="End date"
                onChange={handleOnEnquiryChange}
                value={employeeform.end_date}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  border: "1px solid #ced4da",
                }}
              />
              <span id="show_mobile_number" className="err">
                Please Enter End Date
              </span>
            </div>

            <div className="form-group">
              <label>Company Name</label>
              <input
                type="text"
                className="form-control"
                id="company_name"
                name="company_name"
                placeholder="Enter Company Name"
                onChange={handleOnEnquiryChange}
                value={employeeform.company_name}
                required
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>

            <div className="form-group">
              <label>Designation</label>
              <input
                type="text"
                className="form-control"
                id="designation"
                name="designation"
                placeholder="Enter designation"
                onChange={handleOnEnquiryChange}
                value={employeeform.designation}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>

            <div className="form-group">
              <label>Location</label>
              <input
                type="text"
                className="form-control"
                id="location"
                name="location"
                placeholder="Enter Location"
                onChange={handleOnEnquiryChange}
                value={employeeform.location}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="d-flex gap-3 align-items-center justify-content-center">
            <div className="">
              <button
                className=" py-2"
                onClick={handleEnquiryOk}
                style={{
                  backgroundColor: "#663399",
                  borderRadius: "8px",
                  color: "white",
                  fontFamily: "Inter",
                  border: "none",
                  fontSize: "15px",
                  fontWeight: "700",
                  paddingInline: "40px",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                Submit
              </button>
            </div>
            <div className="">
              <button
                onClick={handleEnquiryCancel}
                className="spButton2"
                style={{
                  // backgroundColor: "white",
                  // borderRadius: "3px",
                  // color: "black",
                  fontFamily: "Inter",
                  // border: "2px solid",
                  fontSize: "15px",
                  padding: "6px 40px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateEmployeeHis;
