import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';
const CareerAPI = {

    createCareer: (params) => {
        // let url = serverAddress + `/api/job_application/apply-for-job`;
        let url = serverAddress + `/website/enquiry/exportData`;

        return axios.post(url,params,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },
    getAllCareer: (params) => {
        // let url = serverAddress + `/api/job_application/get-all?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/job_application/get-all?page=${params?.page}&limit=${params?.limit}`;

        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },

    getOneEnquiry:(params)=>{
        // let url=serverAddress+`/api/job_application/get/${params}`
        let url=serverAddress+`/job_application/get/${params}`

        return axios.get(url,{
          headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${cookie.get("ajwt")}`,
          }
        })
    },
  
    countTotatlCareer: () => {
        // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
        let url = serverAddress + `/enquiry/get/total/enquiry`;
        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },
    
    searchQuery: (params,page,limit)=>{
        // let url=serverAddress+`/api/job_application/search?page=${page}&limit=${limit}`;
        let url=serverAddress+`/job_application/search?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },




      //contact dynamic filter
      dynamicFilter:(params,page,limit)=>{
          // let url=serverAddress+`/api/job_application/dynamicFilters?page=${page}&limit=${limit}`;
          let url=serverAddress+`/job_application/dynamicFilters?page=${page}&limit=${limit}`;

          return axios.post(url,params,{
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${cookie.get("ajwt")}`,
            },
          })
        },
        downloadDynamicFilter:(params,page,limit)=>{
            // let url=serverAddress+`/api/job_application/ExportDynamic?page=${page}&limit=${limit}`;
            let url=serverAddress+`/job_application/ExportDynamic?page=${page}&limit=${limit}`;

            return axios.post(url,params,{
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${cookie.get("ajwt")}`,
              },
            })
          },

    /* Forget Password */
};

export default CareerAPI;
