
const initialState={
    isActive:false,
    progress:0
}
const reducer = (state=initialState,action)=>{
        if(action.type==='TOP_LOADER'){
            return action.payload;
        }else{
            return state;
        }
}

export default reducer;