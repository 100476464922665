import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';
const IecegnAPI = {

    getAllIecegn: (params) => {
        // let url = serverAddress + `/api/iecegn/get?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/iecegn/get?page=${params?.page}&limit=${params?.limit}`;

        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },

        //confirm payment status ICDS
        confirmPaymentIECEGN:(params)=>{
            // let url= `${serverAddress}/api/iecegn/success/?_id=${params}`;
            let url= `${serverAddress}/iecegn/success/?_id=${params}`;
            return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                }
            );
        },

        sendLinkIecegn: (params) => {
            // let url = serverAddress + `/api/law/get/${params}`;
            let url = serverAddress + `/iecegn/get/${params}`;
            return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                }
            );
        },

            //updateTransactionID
    updateTransactionIdIecegn:(id,transactionId)=>{
        // let url= `${serverAddress}/api/iecegn/transaction?_id=${id}&transactionId=${transactionId}`;
        let url= `${serverAddress}/iecegn/transaction?_id=${id}&transactionId=${transactionId}`;

        return axios.post(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },


    approveAbstractIecegn:(id,email)=>{
        let url= `${serverAddress}/iecegn/status?_id=${id}&status=1&email=${email}`
        return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                })
    },
      rejectAbstractIecegn:(id,email)=>{
        let url= `${serverAddress}/iecegn/status?_id=${id}&status=2&email=${email}`
        return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                })
    },
    
    paymentEmailIcegn:(id,email)=>{
        let url= `${serverAddress}/iecegn/status?_id=${id}&paymentstatus=1&email=${email}`
        return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                })
    },
    



    
    //iecegn dynamic filter
    dynamicFilter:(params,page,limit)=>{
        // let url=serverAddress+`/api/iecegn/dynamicFilters?page=${page}&limit=${limit}`;
        let url=serverAddress+`/iecegn/filter/dynamic?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },

      downloadDynamicFilter:(params,page,limit)=>{
        // let url=serverAddress+`/api/iecegn/ExportDynamic?page=${page}&limit=${limit}`;
        let url=serverAddress+`/iecegn/ExportDynamic?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },



// search in iecegn iecegn

searchQuery: (params,page,limit)=>{
    // let url=serverAddress+`/api/iecegn/search?page=${page}&limit=${limit}`;
    let url=serverAddress+`/iecegn/search?page=${page}&limit=${limit}`;
    return axios.post(url,params,{
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    })
  },

    /* Forget Password */
};

export default IecegnAPI;