import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import IcdsAPI from "../../utils/api/IcdsAPI";
import { format } from "date-fns";
import { Table, Pagination, Button, Badge, DatePicker, Tooltip, Modal} from "antd";
import { PlusCircleOutlined , RetweetOutlined} from "@ant-design/icons";
import { notify_error, notify_success } from "../Notify";

import moment from "moment";
import { serverAddress } from "../../constant";
import "antd/dist/antd.css";
// import ViewUllasData from "./ViewUllasData";
const IcdsTable = () => {
  
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [transactionId,setTransactionId] = useState('');
  const [candidateId,setCandidateId] = useState('');
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);


  useEffect(() => {
    top_loader(true, 45);
    getAllIcds({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  const handleRefresh=() => {
    top_loader(true, 45);
    getAllIcds({ page: activepage, limit: recperpage });
  }

  const getAllIcds = (params) => {
    setLoading(true);
    IcdsAPI.getAllIcds(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };


 const handleCancel = () => {
  setOpen(false);
};
const handleOnChange=(e)=>{
  setTransactionId(e.target.value);
}



function verifyPaytmTransactionId(transactionId) {
  const regex = /^.{9,33}$/;

  return regex.test(transactionId);
}

function transactionVerification(params){
if(verifyPaytmTransactionId(params)){
  return true
}else {
    return false
  }
}


 const handleOk = () => {
  setConfirmLoading(true);
  if(transactionId){
   const validate= transactionVerification(transactionId)

   if(validate){
    IcdsAPI.updateTransactionId(candidateId,transactionId).then((result)=>{
          // // console.log('confirm');
          IcdsAPI.confirmPaymentICDS(candidateId).then((result)=>{
                // console.log('confirm');
                top_loader(true, 45);
                getAllIcds({ page: activepage, limit: recperpage });

                setCandidateId('');
                setTransactionId('')
                notify_success("Candidate Payment Status Updated Successfully.")
           setOpen(false);
              }).catch((err)=>{
                notify_error(err?.message ||err);
              })

        }).catch((err)=>{
          notify_error(err?.message ||err);
        })

   }else{
    notify_error("Not a Valid transaction ID");
   }
  }

};


  const handlePaymentStatus=(_id)=>{
    setOpen(true);
    setCandidateId(_id);
  }



  function handlePageChange(page) {
    SetActivePage(page);
    getAllIcds({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllIcds({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      fixed: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.sno}</span>
          </div>
        </div>
      )
    },
    {
      title: "Name",
      width: 100,
      dataIndex: "applicant_name",
      key: "applicant_name",
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.name}</span>
          </div>
        </div>
      )
    },

    {
      title: "Applicant Email",
      width: 100,
      dataIndex: "email",
      key: "email",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.email}</span>
          </div>
        </div>
      )
    },
    {
      title: "Mobile",
      width: 100,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.mobile_number}</span>
          </div>
        </div>
      )
    },

    {
      title: "Institute Name",
      width: 100,
      dataIndex: "institute_name",
      key: "institute_name",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.institute}</span>
          </div>
        </div>
      )
    },


    {
      title: "Address",
      width: 100,
      dataIndex: "postal_address",
      key: "postal_address",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.address}</span>
          </div>
        </div>
      )
    },
    {
      title: "Nationality",
      width: 100,
      dataIndex: "nationality",
      key: "nationality",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.nationality}</span>
          </div>
        </div>
      )
    },
    {
      title: "Payment Status",
      width: 100,
      dataIndex: "isPaymentDone",
      key: "isPaymentDone",
      fixed: "right",
      filters: [
        {
          text: "Done",
          value: true
        },
        {
            text: "Not Paid",
            value: false
          },
   
      ],
      onFilter: (value, record) => record.isPaymentDone === value,
      filterSearch: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            {record.isPaymentDone ? (
              <Badge
                className="site-badge-count-109"
                count={"Done"}
                style={{
                  backgroundColor: "#52c41a"
                }}
              />
            ) : (
              <Badge count={"Not Paid"} color="#faad14" />
            )}
          </div>
        </div>
      )
    },
    {
      title: "Shipping Address",
      width: 100,
      dataIndex: "shipping_Address",
      key: "shipping_Address",
      fixed: "right",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record?.shipping_Address ||'N/A'}</span>
          </div>
        </div>
      )
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index1) => (
<>

        {!record?.isPaymentDone ?(  <div className='d-flex align-items-center gap-2'>
          <Button 
          style={{ boxShadow: '0 2px 4px rgba(0, 55, 200, 0.8)',fontSize:'11px',paddingBlock:'4px'}}
           onClick={() => handlePaymentStatus(record.operation._id)}
          className="bg-secondary text-white d-flex align-items-center border-0">
            Confirm Payment 
          </Button>
        </div>):(<span className="fw-bold ">PAYMENT RECEIVED</span>)}
      
</>
      ),
    },

    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      fixed: "right",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
            onChange={(date) => setSelectedKeys(date ? [date.startOf('day').toDate()] : [])}
            onClear={() => {
              setSelectedKeys([]);
              clearFilters();
              confirm();
            }}
            disabledDate={(current) => current && current > moment().endOf('day')}
            picker="year"
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
          >
            Filter
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const date = moment(record.created_at).format('yyyy-MM-DD');
        return moment(date).isSame(moment(value).format('yyyy-MM-DD'),'year');
      },
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "dd/MM/yyyy")}</span>
          </div>
        </div>
      )
    }
    /* {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index1) => (
        <div>
          <Dropdown overlay={menu(record)}>
            <a onClick={(e) => e.preventDefault()}>
              <Space size={"small"}>
                <MoreOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      )
    } */
  ];

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        name: `${item.first_name} ${item.last_name}`,
        designation: item.designation,
        email: item.email,
        mobile_number: item.mobile_no,
        institute: item.institute,
        nationality:item.nationality,
        address: `${item.state}-${item.city}`,
        category: item.category,
        title_of_paper: item.title_of_paper,
        isPaymentDone: item.isPaymentDone,
        created_at: item.created_at,
        shipping_Address:item.shipping_Address,
        operation: {
          _id: item._id,
        //   image: item.image,
        //   downloadLink: item.downloadLink
        }
      });
    });
  }

  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title="ICDS REGISTRATION List" />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
           <div className="row">
            <div className="col-md-3 form-inline">
              <div className="row align-items-center mb-3">
                <div className="col-md-4">
                  <label className="control-label">Show :</label>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(event) => applyFilter(event)}
                    value={recperpage}
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-4">per page</div>
              </div>
            </div>
            <div className="col-md-9">
            <Tooltip placement="top" title="refresh" color="geekblue"> 
              <Button
                  type="primary"
                  className="mb-3 me-1"
                  style={{ float: "right",backgroundColor:'green'}}
                  onClick={handleRefresh}
                  icon={<i class="fa-solid fa-rotate-right"></i>}
                  size={"large"}
                />
                </Tooltip>
              
              </div>




            </div>
            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              scroll={{ x: 1700, y: 500 }}
            //   expandable={{
            //     expandedRowRender: (record) =>
            //       record.sno === expandedRowKey ? (
            //         <ViewUllasData props={record}/>
            //       ) : (
            //         ""
            //       ),
            //     onExpand: (expanded, record) => {
            //       if (expanded) {
            //         handleRowClick(record);
            //       } else {
            //         setExpandedRowKey(null);
            //       }
            //     },
            //   }}
              
            //   onRow={(record) => ({
            //     onClick: () => {
            //       handleRowClick(record);
            //     }
            //   })
            //   }
              
        
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>
            <Modal
        title="Transaction Id Verification"
        centered
        open={open}
        onOk={() => handleOk(false)}
        onCancel={() => handleCancel(false)}
      >
        <div className="form-group">
                    <label htmlFor="last_name">Transaction Id</label>
                    <input
                      type="text"
                      className="form-control"
                      id="transactionId"
                      name="transactionId"
                      placeholder="Please Enter Transaction Id"
                      onChange={handleOnChange}
                      value={transactionId}
                    />
                    <span id="show_name" class="err">
                      Please Enter Last Name
                    </span>
                  </div>
      </Modal>

          </div>
        </div>
      </div>
    </>
  );
};

export default IcdsTable;
