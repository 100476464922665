import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Badge,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import cookie from "js-cookie";
import LawCalendar from "./LawCalendar";
import IcdsAPI from "../../utils/api/IcdsAPI";
import { IcdsContext } from "../../state/context/IcdsProvider";
export default function IcdsFilter() {
  const {
    filter,
    setFilter,
    search,
    SetSearch,
    recperpage,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(IcdsContext);
  const [clearDate, setClearDate] = useState(false);
  const [countPaid, setCountPaid] = useState(0);
  const [countNotPaid, setCountNotPaid] = useState(0);
  const [activeSearch, setActiveSearch] = useState(true);
  //      const [filter, setFilter] = useState([
  //     { field: "isPaymentDone", selected: [] },
  //     { field: "date", selected: { start: "", end: "" } },
  //   ]);
  //   const [activepage,SetActivePage]=useState(1);
  //   const [recperpage,SetRecPerPage]=useState(25);
  //   const [data, setDate] = useState([]);
  //   const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    // console.log(filter);
    handleApplyFilter();
  }, [filter]);

  useEffect(() => {
    handleCountPaid();
  }, []);

  const handleClearFilter = () => {
    // Reset the filter state to its initial state
    setFilter([
      { field: "isPaymentDone", selected: [] },
      { field: "date", selected: { start: "", end: "" } },
      { field: "category", selected: [] }, // Add this line if you have category filter
    ]);

    // Additionally, you might need to clear other related states
    // For example:
    SetSearch(""); // Clear the search term
    SetActivePage(1); // Reset the active page to 1
    // Clear any other related states here
  };

  const handleButtonClickProgramCategory = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "category"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "category" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "category"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleFilterExport = () => {
    const newData = filterEmptySelected(filter);
    // console.log(newData);

    IcdsAPI.downloadDynamicFilter(newData, activepage, recperpage)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );

        // Create a temporary link element to trigger the file download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "results.csv");
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the temporary link element and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error while downloading CSV:", error);
      });
  };

  const handleCountPaid = () => {
    IcdsAPI.dynamicFilter(
      [{ field: "isPaymentDone", selected: [true] }],
      1,
      25
    ).then((res) => {
      setCountPaid(res.data.total);
      // console.log(res);
    });

    IcdsAPI.dynamicFilter(
      [{ field: "isPaymentDone", selected: [false] }],
      1,
      25
    ).then((res) => {
      setCountNotPaid(res.data.total);
      // console.log(res);
    });
  };

  const filterEmptySelected = (arr) => {
    return arr.filter((obj) => {
      if (
        obj.field === "date" &&
        obj.selected &&
        obj.selected.start !== "" &&
        obj.selected.end !== ""
      ) {
        return true; // Retain the "date" object if start and end values are not empty
      }
      if (
        obj.field === "isPaymentDone" &&
        Array.isArray(obj.selected) &&
        obj.selected.includes("all-enquiry")
      ) {
        obj.selected.length = 0;
        return false; // Empty out the object if admissionStatus includes "all-enquiry"
      }
      const hasSelectedValues =
        obj.selected &&
        obj.selected.length > 0 &&
        (!Array.isArray(obj.selected) ||
          (Array.isArray(obj.selected) &&
            obj.selected.length > 0 &&
            obj.selected[0] !== null));
      return hasSelectedValues;
    });
  };

  const handleApplyFilter = () => {
    const newData = filterEmptySelected(filter);
    // console.log(newData);

    IcdsAPI.dynamicFilter(newData, activepage, recperpage).then((res) => {
      setDate(res.data.data);
      setTotalData(res.data.total);
    });
  };
  useEffect(() => {
    // console.log(data);
  }, [data]);

  const handleButtonClickStatus = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "isPaymentDone"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "isPaymentDone" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "isPaymentDone"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleSearch = () => {
    IcdsAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res.data)
        return;
      }
    );
  };

  return (
    <>
      <Container
        className="py-3 mx-auto"
        style={{
          width: "100%",
          marginTop: "80px",
          backgroundColor: "white",
          // marginBottom: "15px",
          boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
          paddingLeft: "30px",
          fontFamily: "Inter",
          paddingRight: "40px",
        }}
      >
        <Row className="team-member-1 text-start my-2">
          <Col className="team-member-1">Filter By Status :</Col>
        </Row>
        <Row
          className="text-start gap-2 my-2"
          style={{ marginLeft: "0px", paddingRight: "0px" }}
        >
          <Col
            lg={12}
            onClick={() => handleButtonClickStatus(false)}
            className={`border border-2 p-2 d-flex justify-content-between  ${
              filter.some(
                (f) => f.field === "isPaymentDone" && f.selected.includes(false)
              )
                ? "border-danger"
                : "border-danger"
            }`}
            style={{
              borderRadius: "4PX",
              cursor: "pointer",
              fontWeight: "500",
              backgroundColor: filter.some(
                (f) => f.field === "isPaymentDone" && f.selected.includes(false)
              )
                ? "#F7685B" // Dark background color when selected
                : "",
              color: filter.some(
                (f) => f.field === "isPaymentDone" && f.selected.includes(false)
              )
                ? "#FFF" // Light text color when selected
                : "#F7685B",
            }}
          >
            <span>Not Paid</span>
            <span>
              <Badge bg="danger" style={{ borderRadius: "50%" }}>
                {countNotPaid}
              </Badge>
            </span>
          </Col>

          <Col
            lg={12}
            onClick={() => handleButtonClickStatus(true)}
            className={`border border-2 p-2 d-flex justify-content-between  ${
              filter.some(
                (f) => f.field === "isPaymentDone" && f.selected.includes(true)
              )
                ? "border-success"
                : "border-success"
            }`}
            style={{
              borderRadius: "4px",
              cursor: "pointer",
              fontWeight: "500",

              backgroundColor: filter.some(
                (f) => f.field === "isPaymentDone" && f.selected.includes(true)
              )
                ? "#46CF85" // Dark background color when selected
                : "",
              color: filter.some(
                (f) => f.field === "isPaymentDone" && f.selected.includes(true)
              )
                ? "#FFF" // Light text color when selected
                : "#46CF85",
            }}
          >
            <span> Payment Done</span>
            <span>
              <Badge bg="success" style={{ borderRadius: "50%" }}>
                {countPaid}
              </Badge>
            </span>
          </Col>
        </Row>

        <Row className="team-member-1 text-start my-2">
          <Col>Filter By category :</Col>
        </Row>
        <Row className="d-flex row-cols-2">
          <Col className="my-1">
            <Badge
              bg=""
              onClick={() => handleButtonClickProgramCategory("Academic")}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "category" && f.selected.includes("Academic")
                )
                  ? "#663399"
                  : "#F2F2F2",

                color: filter.some(
                  (f) =>
                    f.field === "category" && f.selected.includes("Academic")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              Academic
            </Badge>
          </Col>
          <Col className="my-1">
            <Badge
              onClick={() =>
                handleButtonClickProgramCategory("Research Scholar")
              }
              bg=""
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "category" &&
                    f.selected.includes("Research Scholar")
                )
                  ? "#663399"
                  : "#F2F2F2",

                color: filter.some(
                  (f) =>
                    f.field === "category" &&
                    f.selected.includes("Research Scholar")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              Research Scholar
            </Badge>
          </Col>
          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgramCategory("Student")}
              bg=""
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "category" && f.selected.includes("Student")
                )
                  ? "#663399"
                  : "#F2F2F2",

                color: filter.some(
                  (f) =>
                    f.field === "category" && f.selected.includes("Student")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              Student
            </Badge>
          </Col>
          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgramCategory("Attendee")}
              bg=""
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "category" && f.selected.includes("Attendee")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) =>
                    f.field === "category" && f.selected.includes("Attendee")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              Attendee
            </Badge>
          </Col>
        </Row>

        <Row className="team-member-1 text-start ">
          <Col className="team-member-1">Filter By Date :</Col>
        </Row>

        <LawCalendar
          filter={filter}
          setFilter={setFilter}
          clearDate={clearDate}
          setClearDate={setClearDate}
        />

        <Row className="my-3">
          <Col className="d-flex align-items-center justify-content-center">
            <button
              className=" me-2 px-3 py-2"
              style={{
                backgroundColor: "#663399",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "8px",
              }}
              onClick={handleClearFilter}
            >
              Clear Filters{" "}
            </button>

            <button
              className=" me-2 px-3 py-2"
              style={{
                backgroundColor: "#663399",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "8px",
              }}
              onClick={handleFilterExport}
            >
              Export{" "}
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
