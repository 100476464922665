import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import IcdsAPI from "../../utils/api/IcdsAPI";
import IcdsInfo from "./IcdsInfoProfile";
import IcdsProfile from "./IcdsProfile";
// import IcdsAPI from "../../utils/api/IcdsAPI";
// import IecegnProfile from "./IecegnProfile";
// import IecegnInfo from "./IecegnInfoProfile";

const IcdsLeadProfile = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    console.log("hi");
  }, []);

  useEffect(() => {
    // console.log(id);
    IcdsAPI.sendLinkIcds(id)
      .then((res) => {
        // console.log(res);
        setData(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
    // console.log(id);
  }, []);

  return (
    <div
      className="container-fluid page-body-wrapper custom-sidebar"
      style={{
        fontFamily: "Inter",
        position: "relative",
        height: "100vh", // Full height for the container
        display: "flex", // Use flexbox for layout
      }}
    >
      <NavBar />

      {/* Main content area with scroll */}
      <div
        style={{
          flex: 1, // Allow this section to grow and take available space
          display: "flex", // Use flexbox to arrange children
          flexDirection: "column", // Stack elements vertically
          overflowY: "auto", // Enable vertical scrolling
          padding: "20px", // Optional: Add padding for aesthetics
        }}
      >
        {/* Back Button - Fixed Position */}
        <div
          style={{
            position: "absolute", // Position absolutely within the content area
            top: "128px",
            left: "-27px",
            zIndex: "90",
          }}
        >
          <button
            style={{
              border: "none",
              background: "transparent",
              borderRadius: "50%",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/dashboard/icds");
            }}
          >
            <img
              width={"36px"}
              height={"36px"}
              src="/assets/images/dashboard/back-button.png"
              alt="Back"
            />
          </button>
        </div>

        {/* Flex container for side-by-side layout */}
        <div
          style={{
            display: "flex", // Use flexbox for side-by-side layout
            flex: 1, // Allow to fill the remaining space
          }}
        >
          <div style={{ flex: "0 0 25%" }}>
            {" "}
            <IcdsProfile data={data} />
          </div>
          <div style={{ flex: "0 0 75%" }}>
            {" "}
            <IcdsInfo data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IcdsLeadProfile;
