import React, { useState, useEffect } from "react";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import { notify_error, notify_success } from "./Notify";

export default function Duplicates({ data }) {
  const [userDuplicates, setUserDuplicates] = useState();

  useEffect(() => {
    if (data && data.enquiry_duplicates && data.enquiry_duplicates.length > 0) {
      setUserDuplicates(data.enquiry_duplicates);
    }
  }, [data]);

  // console.log("line 766:",data)
  return (
    <div className="duplicatesContainer">
      <div>
        {userDuplicates && userDuplicates.length > 0 ? (
          <>
            <h2 style={{ fontFamily: "Inter" }}>Duplicates</h2>
            <table className="duplicate-table table table-responsive table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Program</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Origin</th>
                </tr>
              </thead>
              <tbody>
                {userDuplicates.map((item) => (
                  <tr key={item.id}>
                    <td style={{ textTransform: "capitalize" }}>{item.name}</td>
                    <td>{item.program}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile_number}</td>
                    <td>{item.form_location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>No duplicates found.</p>
        )}
      </div>
    </div>
  );
}
