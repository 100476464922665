import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state/index";
import { bindActionCreators } from "redux";
import validator from "validator";
import utf8 from "utf8";
import cookie from "js-cookie";
import MidNavbar from "./MidNavBar";
import { EditOutlined } from "@ant-design/icons";
import UserAPI from "../utils/api/UserAPI";
import { notify_error, notify_success } from "./Notify";
import "./Navbar.css";
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import { top_loader } from "../state/action-creators";
const NavBar = (props) => {
  const [profileimage, setImage] = useState(cookie.get("image"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const navigate=useNavigate();
  const [open, setOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const { top_loader } = bindActionCreators(actionCreators, dispatch);

  let fname = cookie.get("firstname");
  let lname = cookie.get("lastname");
  let _id = cookie.get("user");
  let role = cookie.get("role");
  const Admin = role == "super_admin";
  const Admission = role == "super_admin" || role == "admission";
  const Reception =
    role == "super_admin" || role == "reception" || role == "counsellor";
  const Event = role == "super_admin" || role == "event";

  const [resetPassword, setResetPassword] = React.useState({
    password: "",
    c_password: "",
    username: "",
  });
  const [changePassword, setChangePassword] = useState({
    username: "",
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const handleEdit = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setChangePasswordOpen((prev) => !prev);
  };
  const [enquiry, setEnquiry] = useState({
    firstname: "",
    lastname: "",
    file: null,
    _id: _id,
  });

  const getProfileDetails = async () => {
    const user = await UserAPI.getProfileDetails();
    const data = await user.json();
    cookie.remove("image");
    cookie.remove("lastname");

    cookie.remove("firstname");
    cookie.remove("name");

    cookie.set("image", data.data.image);
    cookie.set("name", `${data.data?.firstname} ${data.data?.lastname}`);
    cookie.set("firstname", data.data.firstname);
    cookie.set("lastname", data.data.lastname);
  };

  const logout = async () => {
    top_loader(true, 10);
    top_loader(true, 70);
    top_loader(true, 90);
    top_loader(true, 100);
    cookie.remove("ajwt");
    cookie.remove("user");
    cookie.remove("role");
    cookie.remove("name");
    cookie.remove("image");
    cookie.remove("firstname");
    cookie.remove("lastname");

    notify_success("Logout successfully...");
    navigate("/");
    top_loader(false, 0);
  };
  const handleOk = async () => {
    if (!enquiry.firstname) {
      notify_error("Please fill in First name.");
      return;
    }
    if (!enquiry.lastname) {
      notify_error("Please fill in Last name.");
      return;
    }
    if (enquiry.file == null || !enquiry.file) {
      notify_error("please Select a File");
    } else {
      const fd = await new FormData();
      fd.append("firstname", enquiry?.firstname || fname);

      fd.append("lastname", enquiry?.lastname || lname);
      fd.append("_id", enquiry._id);

      fd.append("file", enquiry.file);

      UserAPI.updateProfileDetails(fd)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == true) {
            notify_success("Profile Successfully Updated");
            handleCancel();
            setEnquiry({
              firstname: enquiry?.firstname || fname,
              lastname: enquiry?.lastname || lname,
              file: null,
              _id: _id,
            });
            getProfileDetails();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            notify_error(data.message);
          }
        })
        .catch((err) => {
          notify_error(err.message);
        });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setChangePasswordOpen(false);
  };

  const handleOnChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setEnquiry((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
    // console.log(enquiry);
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/jpg")
    ) {
    } else {
      alert("Please select a JPG/JPEG/PNG file.");
    }
  };

  const onUpdatePasswordChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setChangePassword({ ...changePassword, [name]: value });
  };
  var flag = 0;

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    // Password validation checks
    if (
      !changePassword.old_password ||
      !changePassword.new_password ||
      !changePassword.confirm_new_password
    ) {
      notify_error("Please fill all password fields.");
      return;
    }

    if (changePassword.new_password !== changePassword.confirm_new_password) {
      notify_error("New passwords do not match.");
      return;
    }

    const bodyContent = JSON.stringify({
      old_password: changePassword.old_password,
      new_password: changePassword.new_password,
      username: changePassword.username,
    });

    try {
      const response = await UserAPI.updatePasswordNew(bodyContent);
      const json = await response.json();

      if (json.success && json.user.status) {
        // Password updated successfully
        notify_success("Password Updated Successfully.");
        setChangePassword({
          old_password: "",
          new_password: "",
          confirm_new_password: "",
          username: "",
        });
        setChangePasswordOpen(false);
        // ... (remaining code to set cookies, navigate, etc.)
      } else {
        // Failed to update password
        notify_error(json.message);
      }
    } catch (err) {
      notify_error(err.message);
    }
  };

  return (
    <nav
      className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      {/* IPEM LOGO NAVBAR */}
      <div
        className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <a className="navbar-brand brand-logo" href="/dashboard">
          <img src="/assets/images/maroon.png"></img>
        </a>
        <a className="navbar-brand brand-logo-mini" href="#">
          <img src={`/assets/images/logo-mini.svg`} alt="logo" />
        </a>
      </div>

      {/* NAVBAR ITEMS */}

      <div
        className="navbar-menu-wrapper d-flex align-items-stretch mt-1 "
        style={{ paddingLeft: "30px" }}
      >
        {/* <div className="navBar1">
          <MidNavbar />
        </div> */}

        <ul className="navbar-nav navbar-nav-right">
          <li className="mx-2">
            <a>
              <img src="/assets/images/dashboard/Notification Bell.png" />
            </a>
          </li>

          <li className="">
            <div className="" style={{ marginLeft: "8px" }}>
              {!cookie.get("image") ? (
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "100%",
                  }}
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="profile"
                />
              ) : (
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "100%",
                  }}
                  src={`https://api.assertit.io/ipem/user/single?file_path=${profileimage}`}
                  alt="profile"
                />
              )}
              <span className="availability-status online" />
            </div>
          </li>

          <li className="mx-2">
            <a
              className="nav-link fw-bold text-dark"
              style={{ textDecoration: "none" }}
            >
              {cookie.get("firstname")?.toUpperCase()}
            </a>
          </li>

          <li
            className="nav-item nav-profile dropdown mx-2"
            style={{ position: "static" }}
          >
            <a
              className=" dropdown-toggle"
              id="profileDropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="nav-profile-text">
                <p className="mb-1 text-black">{props?.name?.toUpperCase()}</p>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <Link
                className="dropdown-item"
                to="#"
                onClick={handleChangePassword}
                style={{ color: "#000", fontWeight: "600" }}
              >
                <i className="mdi mdi-settings me-2 text-success" /> Change
                Password
              </Link>
              <div className="dropdown-divider" />
              <div
                to="#"
                className="dropdown-item"
                onClick={handleEdit}
                style={{ color: "#000", fontWeight: "600" }}
              >
                <i className="mdi mdi-pencil me-2 text-primary" />
                Edit Details
              </div>
              <div className="dropdown-divider" />
              <Link
                className="dropdown-item"
                to="#"
                onClick={logout}
                style={{ color: "#000", fontWeight: "600" }}
              >
                <i className="mdi mdi-logout me-2 text-primary" /> Logout{" "}
              </Link>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu" />
        </button>
      </div>

      <Modal
        title="Update Profile Details"
        open={open}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                name="firstname"
                placeholder="First Name"
                onChange={handleOnChange}
                style={{ border: "1px solid", borderRadius: "8px" }}
              />
              <span id="show_name" class="err">
                Please Enter FIRST Name
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="lastname"
                placeholder="Last Name"
                onChange={handleOnChange}
                style={{ border: "1px solid", borderRadius: "8px" }}
              />
              <span id="show_name" class="err">
                Please Enter Last Name
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="profile_pic">Profile Picture</label>
              <input
                type="file"
                className="form-control"
                id="profile_pic"
                name="file"
                placeholder=""
                onChange={handleOnChange}
                style={{ border: "1px solid", borderRadius: "8px" }}
              />
              <span id="show_password" class="err">
                Please Enter a Password
              </span>
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={handleOk}
                className="spButton1 mx-2 py-1 px-3"
                style={{ padding: "" }}
              >
                OK
              </button>
              <button onClick={handleCancel} className="spButton2 py-1 px-3">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={changePasswordOpen}
        footer={null}
        onCancel={handleCancel}
        style={{
          marginBlock: "auto",
          borderRadius: "12px",
          fontFamily: "Inter",
        }}
      >
        <div className="my-3 col-md-10 mx-auto">
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "32px",
              lineHeight: "38px",
              fontWeight: "700",
            }}
          >
            Change Password
          </h2>
          <div className="row">
            <div className="">
              <div className="form-group">
                <label htmlFor="username">Username / Email-Id</label>
                <input
                  style={{ border: "2px solid", borderRadius: "8px" }}
                  type="text"
                  className="form-control border-secondary"
                  id="username"
                  name="username"
                  placeholder="Enter your Username"
                  value={changePassword.username}
                  onChange={onUpdatePasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="old_password">Current Password</label>
                <input
                  style={{ border: "2px solid", borderRadius: "8px" }}
                  type="password"
                  className="form-control border-secondary"
                  id="old_password"
                  name="old_password"
                  placeholder="Enter your Current Password"
                  value={changePassword.old_password}
                  onChange={onUpdatePasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="new_password">New Password</label>
                <input
                  style={{ border: "2px solid", borderRadius: "8px" }}
                  type="password"
                  className="form-control border-secondary"
                  id="new_password"
                  name="new_password"
                  placeholder="Enter your New Password"
                  value={changePassword.new_password}
                  onChange={onUpdatePasswordChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm_new_password">
                  Re-enter New Password
                </label>
                <input
                  style={{ border: "2px solid", borderRadius: "8px" }}
                  type="password"
                  className="form-control border-secondary"
                  id="confirm_new_password"
                  name="confirm_new_password"
                  placeholder="Confirm your New Password"
                  value={changePassword.confirm_new_password}
                  onChange={onUpdatePasswordChange}
                />
              </div>
              <div>
                <button
                  className="col-md-12 py-2"
                  onClick={handleUpdatePassword}
                  style={{
                    backgroundColor: "#663399",
                    borderRadius: "8px",
                    color: "white",
                    fontFamily: "Inter",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "700",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.transform = "scale(1.02)")
                  }
                  onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </nav>
  );
};

export default NavBar;
