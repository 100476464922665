import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import ContactUsAPI from "../../utils/api/ContactUsAPI";
import { format } from "date-fns";
import { Badge, Tooltip } from "antd";
import { Table, Pagination ,Button} from "antd";
import { PlusCircleOutlined , RetweetOutlined} from "@ant-design/icons";

import "antd/dist/antd.css";
const ContactUsTable = () => {
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    top_loader(true, 45);
    getAllContactUs({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const handleRefresh=()=>{
    top_loader(true, 45);
    getAllContactUs({ page: activepage, limit: recperpage });
  }

  const getAllContactUs = (params) => {
    setLoading(true);
    ContactUsAPI.getAllContactUs(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };
  function handlePageChange(page) {
    SetActivePage(page);
    getAllContactUs({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllContactUs({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.sno}</span>
          </div>
        </div>
      )
    },
    {
      title: "Name",
      width: 100,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.name}</span>
          </div>
        </div>
      )
    },
    {
      title: "Email",
      width: 100,
      dataIndex: "email",
      key: "email",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.email}</span>
          </div>
        </div>
      )
    },
    {
      title: "Mobile",
      width: 100,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.mobile_number}</span>
          </div>
        </div>
      )
    },
    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "dd/MM/yyyy")}</span>
          </div>
        </div>
      )
    }
  ];

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        name: item.name,
        email: item.email,
        mobile_number: item.mobile_number,
        program: item.program,
        created_at: item.created_at,
        form_location: item.form_location,
        enquiry_type: item.enquiry_type
        // operation:{
        //     _id:item._id,
        //     downloadLink:item.downloadLink
        // }
      });
    });
  }
  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title="Contact-Us List" />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
           <div className="row">
            <div className="col-md-3 form-inline">
              <div className="row align-items-center mb-3">
                <div className="col-md-4">
                  <label className="control-label">Show :</label>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(event) => applyFilter(event)}
                    value={recperpage}
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-4">per page</div>
              
              </div>
          
            </div>
            <div className="col-md-9">
            <Tooltip placement="top" title="refresh" color="geekblue">         
                <Button
                 type="primary"
                 className="mb-3 me-1"
                 style={{ float: "right",backgroundColor:'green'}}
                 onClick={handleRefresh}
                 icon={<i class="fa-solid fa-rotate-right"></i>}
                 size={"large"}
               />
             </Tooltip>
             </div>
             </div>

            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              //   scroll={{ x: 1500, y: 300 }}
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsTable;
