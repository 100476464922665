import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Container, Row, Col, Image, Badge } from "react-bootstrap";

const FeedbackCalendar = ({ filter, setFilter, clearDate, setClearDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Call the function to update the filter data when startDate or endDate changes
    pushDatesIntoArray(startDate, endDate);
  }, [startDate, endDate]);

  const handleClear = () => {
    if (startDate && endDate) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    if (clearDate) {
      handleClear();
      setClearDate((prev) => !prev);
    }
  }, [clearDate, setClearDate]);

  const handleThisWeek = () => {
    const now = moment();
    const startOfWeek = moment(now).startOf("week");
    const endOfWeek = moment(now).endOf("week");

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };

  const handleToday = () => {
    const now = moment();
    const startOfDay = moment(now).startOf("day");
    const endOfDay = moment(now).endOf("day");

    setStartDate(startOfDay);
    setEndDate(endOfDay);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleThisYear = () => {
    const now = moment();
    const startOfYear = moment([now.year(), 0, 1]);
    const endOfYear = moment(startOfYear).endOf("year");

    setStartDate(startOfYear);
    setEndDate(endOfYear);
  };

  const handleThisMonth = () => {
    const now = moment();
    const startOfMonth = moment([now.year(), now.month(), 1]);
    const endOfMonth = moment(startOfMonth).endOf("month");

    // Check if the current date is at the end of the month
    const currentDate = moment();
    if (currentDate.isSame(endOfMonth, "day")) {
      endOfMonth.subtract(1, "day");
    }

    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    if (start !== startDate || end !== endDate) {
      setStartDate(start);
      setEndDate(end);
    }
    setIsOpen(false);
  };

  const pushDatesIntoArray = (startDate, endDate) => {
    setFilter((prevData) => {
      const updatedData = [...prevData];
      const dateFieldIndex = updatedData.findIndex(
        (item) => item.field === "date"
      );

      if (dateFieldIndex !== -1) {
        updatedData[dateFieldIndex].selected = {
          start: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          end: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        };
      }

      return updatedData;
    });
  };

  return (
    <>
      <Container className="bg-white mt-3">
        <Row>
          <Col
            className="d-flex p-0 bg-white "
            style={{ borderRadius: "4px", flex: "1" }}
          >
            <div
              className="d-flex flex-column flex-grow-1 justify-content-center align-items-center py-2"
              onClick={handleToday}
            >
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                Today
              </span>
              <span className="ms-1 flex-grow-0">
                <input
                  type="radio"
                  name="date"
                  style={{
                    accentColor: "#663399" /* Custom selected color */,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>

            <div
              className="d-flex flex-column flex-grow-1   justify-content-center align-items-center py-2"
              onClick={handleThisWeek}
            >
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                This Week
              </span>
              <span className="ms-1 flex-grow-0">
                <input
                  type="radio"
                  name="date"
                  style={{
                    accentColor: "#663399" /* Custom selected color */,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            <div
              className="d-flex flex-column flex-grow-1 gap-2 justify-content-center align-items-center py-2"
              onClick={handleThisMonth}
            >
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                This Month
              </span>
              <span className="ms-1 flex-grow-0">
                <input
                  type="radio"
                  name="date"
                  style={{
                    accentColor: "#663399" /* Custom selected color */,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            <div
              className="d-flex flex-column flex-grow-1  py-2 justify-content-center align-items-center"
              onClick={handleThisYear}
            >
              <span
                className="flex-grow-1 "
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                This Year
              </span>
              <span className="ms-1 flex-grow-0">
                <input
                  type="radio"
                  name="date"
                  style={{
                    accentColor: "#663399" /* Custom selected color */,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1  justify-content-center align-items-center py-2">
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                Set up
              </span>
              <span className="ms-1 flex-grow-0">
                <input
                  type="radio"
                  name="date"
                  onClick={() => setIsOpen(true)}
                  style={{
                    accentColor: "#663399" /* Custom selected color */,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={handleDateChange}
              open={isOpen}
              onOpenChange={setIsOpen}
              onCalendarBlur={handleBlur}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FeedbackCalendar;
