import React, { useEffect, useState } from "react";
import LawAPI from "../../utils/api/LawAPI";
import { notify_error, notify_success } from "../Notify";
import { serverAddress } from "../../constant";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";

const LeadFeedbackProfile = ({ data }) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);

  useEffect(() => {

    // console.log(key);
  }, [key]);
  useEffect(() => {
   // console.log(key);
 }, [data]);

  function getFirstWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords;
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  return (
    <>
      {data && data._id && (
        <div
          className="spWrapper pb-4"
          style={{
            height: "100vh",
            boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
            overflowY: "auto",
          }}
        >
          <div className="spContent">
            {data.name_of_organization ? (
              <>
                <div className="spContent01">
                  {getFirstWords(data.name_of_organization)}
                </div>
                <div className="spContent03">
                  {capitalizeWords(data.name_of_organization)}
                </div>
              </>
            ) : (
              <>
                <div className="spContent01">{getFirstWords(data.name)}</div>
                <div className="spContent03">{capitalizeWords(data.name)}</div>
              </>
            )}
          </div>

          <div className="spContent1">
            <img
              height={"20px"}
              src="/assets/images/dashboard/Calender.png"
            ></img>
            <div className="spContent11">Created at:</div>
            <div className="spContent12">{data.created_at?.slice(0, 10)}</div>
          </div>

          <div className="spContent2"></div>
          {Object.entries(data)
            .filter(
              ([key, value]) =>
                value !== null && value !== undefined && value !== ""
            )
            .filter(
              ([key]) =>
                ![
                  "questionSet",
                  "feedback",
                  "other_suggestions",
                  "feedbackType",
                  "__v",
                  ,
                  "_id",
                  "created_at",
                  "name",
                ].includes(key)
            )
            .map(([key, value]) => (
              <div className="spContent31 mt-3 mx-3" key={key}>
                <div className="spContent32">
                  {key.charAt(0).toUpperCase() + key.slice(1)}:
                </div>
                <div className="spContent33">
                  {typeof value === "object" ? JSON.stringify(value) : value}
                </div>
              </div>
            ))}

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Feedback Type :</div>
            <div className="spContent33"> {data.feedbackType}</div>
          </div>
        </div>
      )}
      {data && data.admission_number && (
        <div
        className="spWrapper pb-4"
        style={{
          height: "100vh",
          boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
          overflowY: "auto",
        }}
      >
        
        <div className="spContent">
            {data.student_name ? (
              <>
                <div className="spContent01">
                  {getFirstWords(data.student_name)}
                </div>
                <div className="spContent03">
                  {capitalizeWords(data.student_name)}
                </div>
              </>
            ) : (
              <>
                <div className="spContent01">{getFirstWords(data.student_name)}</div>
                <div className="spContent03">{capitalizeWords(data.student_name)}</div>
              </>
            )}
          </div>

          <div className="spContent2" style={{marginTop:'10px'}}></div>

        <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Name :</div>
          <div className="spContent33"> {data.student_name}</div>
        </div>

        <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Email :</div>
          <div className="spContent33"> {data.student_email}</div>
        </div>

        <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Admission No :</div>
          <div className="spContent33"> {data.admission_number}</div>
        </div>

        <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Mobile :</div>
          <div className="spContent33"> {data.student_number}</div>
        </div>

        <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Course :</div>
          <div className="spContent33"> {data.program}</div>
        </div>

        <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Semester :</div>
          <div className="spContent33"> {data.semester}</div>
        </div>
      </div>
      )}
    </>
  );
};
export default LeadFeedbackProfile;
