import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import OnlineEnquiryAPI from "../../utils/api/OnlineEnquiryAPI";
import { format } from "date-fns";
import { Badge, Tooltip } from "antd";
import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import { useSearchParams } from "react-router-dom";
import { parse } from "querystring";
import "./OnlineEnquiryTable.css";
import EnquiryAPI from "../../utils/api/EnquiryAPI";

import { Table, Pagination, Button } from "antd";
import {
  PlusCircleOutlined,
  RetweetOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import "antd/dist/antd.css";
const EnquiryTable = () => {
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState([]);
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState("All Enquiry List");

  // Convert the searchParams object to a regular JavaScript object
  const queryObject = parse(searchParams.toString());

  useState(() => {
    // Access the individual query parameters
    const { filter } = queryObject;
    if (filter) {
      SetRecPerPage(150);
    }
    if (filter == "ad") {
      setDefaultFilter(["ad-page"]);
      setTitle("Ad-Page Enquiry List");
    } else if (filter == "walk_in") {
      setDefaultFilter(["walk-in"]);
      setTitle("Walk-In Enquiry List");
    } else if (filter == "organic") {
      setDefaultFilter(["website-pop-up", "online-query"]);
      setTitle("Organic Enquiry List");
    }
  }, []);

  useEffect(() => {
    top_loader(true, 45);
    getAllEnquiry({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const handleRefresh = () => {
    top_loader(true, 45);
    getAllEnquiry({ page: activepage, limit: recperpage });
  };

  const getAllEnquiry = (params) => {
    setLoading(true);
    OnlineEnquiryAPI.getAllOnlineEnquiry(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handleAssignment = (_id) => {
    EnquiryAPI.updateAssignment({
      _id: _id,
      username: cookie.get("name"),
    })
      .then((result) => {
        if (result.data.status) {
          notify_success(result.data.message);
          getAllEnquiry({ page: activepage, limit: recperpage });
        } else {
          notify_error("Failed to update");
        }
      })
      .catch((err) =>
        notify_error("Unauthorized Access. Only For Counsellors.")
      );
  };

  function handleStatus(id) {
    OnlineEnquiryAPI.updateStatus({
      _id: id,
      username: cookie.get("name"),
    }).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        getAllEnquiry({ page: activepage, limit: recperpage });
      } else {
        notify_error("Failed to update");
      }
    });
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  function handlePageChange(page) {
    SetActivePage(page);
    getAllEnquiry({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllEnquiry({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            {record.repeat == false || undefined ? (
              <span>{record.sno}</span>
            ) : (
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "",
                }}
              >
                {record.sno}{" "}
                <Badge.Ribbon
                  style={{
                    fontSize: "11px",
                    right: "-20px",
                    top: "1px",
                    position: "absolute",
                  }}
                  color="#f50"
                  placement="end"
                  text="DUP"
                />
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Name",
      width: 100,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{capitalizeWords(record.name)}</span>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Email",
    //   width: 100,
    //   dataIndex: "email",
    //   key: "email",
    //   render: (text, record, index) => (
    //     <div className="table-td-div">
    //       <div className="table-td-div-inner">
    //         <span>{record.email}</span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Mobile",
      width: 100,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.mobile_number}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Program",
      width: 100,
      dataIndex: "program",
      key: "program",
      filters: [
        {
          text: "BBA",
          value: "bba",
        },
        {
          text: "BCA",
          value: "bca",
        },
        {
          text: "B.COM(H)",
          value: "b.com(h)" && "b.com",
        },
        {
          text: "MBA",
          value: "mba",
        },
        {
          text: "MCA",
          value: "mca",
        },
        {
          text: "B.A.LLB",
          value: "b.a.llb" && "ba.llb",
        },
        {
          text: "LLB",
          value: "llb",
        },
        {
          text: "B.COM LLB",
          value: "b.com llb" && "b.com.llb",
        },
        {
          text: "B.ED",
          value: "b.ed",
        },
      ],
      onFilter: (value, record) => record.program.indexOf(value) === 0,
      filterSearch: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record?.program?.toUpperCase()}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Origin",
      width: 100,
      dataIndex: "form_location",
      key: "form_location",
      filters: [
        {
          text: "Website Pop-Up",
          value: "website-pop-up",
        },
        {
          text: "Walk-In",
          value: "walk-in",
        },
        {
          text: "Online Query",
          value: "online-query",
        },
        {
          text: "Ad Page",
          value: "ad-page",
        },
      ],
      filteredValue: defaultFilter.length > 0 ? defaultFilter : undefined,
      onFilter: (value, record) => record.form_location.indexOf(value) === 0,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner d-flex flex-column">
            <span>
              {record.form_location === "walk-in" ? (
                <Badge
                  className="site-badge-count-109"
                  count={record?.form_location?.toUpperCase()}
                  style={{
                    backgroundColor: "#52c41a",
                  }}
                />
              ) : record.form_location === "query" ? (
                <Badge
                  className="site-badge-count-109"
                  count={record?.form_location?.toUpperCase()}
                  style={{
                    backgroundColor: "#faad14",
                  }}
                />
              ) : record.form_location === "website-pop-up" ? (
                <Badge
                  className="site-badge-count-109"
                  count={record?.form_location?.toUpperCase()}
                  style={{
                    backgroundColor: "#1890ff",
                  }}
                />
              ) : (
                <Badge
                  className="site-badge-count-109"
                  count={record?.form_location?.toUpperCase()}
                  style={{
                    backgroundColor: "#1890ff",
                  }}
                />
              )}
            </span>
            <div className="table-td-div">
              <div className="table-td-div-inner">
                <span>{record.enquiry_type?.toUpperCase()}</span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Enquiry Type",
    //   width: 100,
    //   dataIndex: "enquiry_type",
    //   key: "enquiry_type",
    //   render: (text, record, index) => (
    //     <div className="table-td-div">
    //       <div className="table-td-div-inner">
    //         <span>{record.enquiry_type?.toUpperCase()}</span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Location",
      width: 100,
      dataIndex: "candidate_location",
      key: "candidate_location",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.candidate_location?.toUpperCase()}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Enquiry Status",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index1) => (
        <div>
          {record.operation.enquiryStatus === false ? (
            <button
              className="btn btn-sm"
              style={{
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.8)",
                minWidth: "94px",
                backgroundColor: "#efdff7",
              }}
              onClick={() => handleStatus(record.operation._id)}
            >
              PENDING
            </button>
          ) : record.operation.handled_by ? (
            <>
              <p
                style={{ color: "darkgoldenrod", fontWeight: "bold" }}
              >{`HANDLED BY ${record.operation.handled_by.toUpperCase()}`}</p>
            </>
          ) : (
            <>
              <Badge
                className="site-badge-count-109"
                count={"HANDLED"}
                style={{
                  backgroundColor: "#3BB272",
                  borderRadius: "0.1875rem",
                  color: "white",
                  fontSize: "14px",
                  width: "94px",
                  height: "34px",
                  lineHeight: "34px",
                }}
              />
              {record?.assignedTo && (
                <p className="py-2 fw-bold text-start text-primary">
                  {record.assignedTo.toUpperCase()}
                </p>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "dd/MM/yyyy")}</span>
          </div>
        </div>
      ),
    },
  ];

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        name: item.name,
        email: item.email,
        mobile_number: item.mobile_number,
        program: item.program,
        created_at: item.created_at,
        form_location: item.form_location,
        enquiry_type: item.enquiry_type,
        candidate_location: item.state ? `${item?.state}-${item?.city}` : "N/A",
        repeat: item?.repeat,
        assignedTo: item?.assignedTo,
        operation: {
          _id: item._id,
          enquiryStatus: item.is_enquiry_done,
          handled_by: item.handled_by,
        },
      });
    });
  }

  const getRowClassName = (record, index) => {
    if (record.operation.enquiryStatus) {
      return "highlight-row";
    }
    return "";
  };
  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title={title} />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
            <div className="row">
              <div className="col-md-3 form-inline">
                <div className="row align-items-center mb-3">
                  <div className="col-md-4">
                    <label className="control-label">Show :</label>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      onChange={(event) => applyFilter(event)}
                      value={recperpage}
                    >
                      {/* <option value="5">5</option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                  <div className="col-md-4">per page</div>
                </div>
              </div>
              <div className="col-md-9">
                <Tooltip placement="top" title="refresh" color="geekblue">
                  <Button
                    type="primary"
                    className="mb-3 me-1"
                    style={{ float: "right", backgroundColor: "green" }}
                    onClick={handleRefresh}
                    icon={<i class="fa-solid fa-rotate-right"></i>}
                    size={"large"}
                  />
                </Tooltip>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              rowClassName={getRowClassName}
              // scroll={{ x: 1500, y: 700 }}
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquiryTable;
