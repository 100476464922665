import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';

const UserAPI = {

    loginUser:async(params,signedIn)=>{
        // const url = `${serverAddress}/api/user/login`;
        const url = `${serverAddress}/user/login?signedIn=${signedIn}`;
       
        return await fetch(url, {
            method: "POST",
            headers:{
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
            body: params,
          });

    },
    verifyToken:async()=>{
        let url=serverAddress+`/user/check-login?token=${cookie.get('ajwt')}`;
        return await fetch (url,{
            headers:{
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
        })
    },
    searchQuery: (params,page,limit)=>{
        // let url=serverAddress+`/api/user/search?page=${page}&limit=${limit}`;
        let url=serverAddress+`/user/search?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },

    verifyEmail: (params) => {
        // let url = serverAddress + `/api/user/verify-email`;
        let url = serverAddress + `/user/verify-email`
        return axios.post(url, params,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },

    verifyOTP: (params) => {
        // let url = serverAddress + `/api/user/verify-otp`;
        let url = serverAddress + `/user/verify-otp`;
        return axios.post(url, params,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },

    UpdatePassword: (params) => {
        // let url = serverAddress + `/api/user/update-password`;
        let url = serverAddress + `/user/update-password`;
        let data = {
            username: params?.username,
            password: params?.password,
        }
        return axios.patch(url, data,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },


    // get all users 
    getAllUsers: (params) => {
        // let url = serverAddress + `/api/user/get-all?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/user/get-all?page=${params?.page}&limit=${params?.limit}`;
        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },
 // createUser

 createUser:(params)=>{
    // let url = serverAddress + `/api/user/create`
    let url = serverAddress + `/user/create`

    return axios.post(url,params, 
        {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
        }
        
        )
 },

 /* UPDATE PASSWORD NEW */

 updatePasswordNew:async (params,signedIn)=>{
    let response;
        // let url= `${serverAddress}/api/user/update-password`;
        let url= `${serverAddress}/user/update-password?signedIn=${signedIn}`;
        return  await fetch(url, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
            body: params,
          });
        
 },


 //deleteUser
  
 deleteUser:async(params)=>{
    // let url= `${serverAddress}/api/user/delete`;
    let url= `${serverAddress}/user/delete`;
        return await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
            body: params,
          });
 },

 /*Get Profile Details*/

 getProfileDetails:async (params)=>{
    return await fetch(
        `${serverAddress}/user/get/${cookie.get("user")}`
      );

 },


 /*Update Profile details*/

 updateProfileDetails:async(params)=>{
    let url=`${serverAddress}/user/updateDetails`;
    return await fetch(url,{
        method: "PATCH",
        body: params,
        
    })
 },

 // Update Role
updateRole:async(params)=>{
    let url=`${serverAddress}/user/update-role`;
    return await fetch(url,{
        method: "PATCH",
        body: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${cookie.get('ajwt')}`
        },
    })
 },




 // Create 



 createRole:(params)=>{
    let url = serverAddress + `/role/create`
    return axios.post(url, params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
      });
},

getallRoles: (params) => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url = serverAddress + `/role/get-all`;
    return axios.get(url,
        {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
        }
    );
},





};







/* Forget Password */
export default UserAPI;
