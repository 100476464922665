import React, { useState, useEffect } from "react";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import { Container, Row, Col, Form } from "react-bootstrap";
import ReactEcharts from "echarts-for-react";
import { echartPieOption } from "../echarts/echartOptions";

export default function EnquiryChart() {
  const [chartData, setChartData] = useState([]);
  const [countOrganic, setCountOrganic] = useState(0);
  const [countWalkIn, setCountWalkIn] = useState(0);
  const [countAdPage, setCountAdPage] = useState(0);
  const [program, setProgram] = useState("total");

  useEffect(() => {
    // Fetch data based on the program selected
    if (program !== "total") {
      OnlineEnquiryAPI.dynamicFilter(
        [
          { field: "program", selected: [program] },
          { field: "form_location", selected: ["walk-in"] },
        ],
        1,
        25
      ).then((res) => setCountWalkIn(res.data.total));

      OnlineEnquiryAPI.dynamicFilter(
        [
          { field: "program", selected: [program] },
          { field: "form_location", selected: ["ad-page", "college-dekho"] },
        ],
        1,
        25
      ).then((res) => setCountAdPage(res.data.total));

      OnlineEnquiryAPI.dynamicFilter(
        [
          { field: "program", selected: [program] },
          {
            field: "form_location",
            selected: ["website-pop-up", "online-query", "telephonic"],
          },
        ],
        1,
        25
      ).then((res) => setCountOrganic(res.data.total));
    } else {
      // For total data
      OnlineEnquiryAPI.dynamicFilter(
        [{ field: "form_location", selected: ["walk-in"] }],
        1,
        25
      ).then((res) => setCountWalkIn(res.data.total));

      OnlineEnquiryAPI.dynamicFilter(
        [{ field: "form_location", selected: ["ad-page", "college-dekho"] }],
        1,
        25
      ).then((res) => setCountAdPage(res.data.total));

      OnlineEnquiryAPI.dynamicFilter(
        [
          {
            field: "form_location",
            selected: ["website-pop-up", "online-query", "telephonic"],
          },
        ],
        1,
        25
      ).then((res) => setCountOrganic(res.data.total));
    }
  }, [program]);

  useEffect(() => {
    // Update chart data once countWalkIn, countAdPage, and countOrganic are updated
    const data = [
      { value: countWalkIn, name: "Walk-In" },
      { value: countAdPage, name: "Ad Page" },
      { value: countOrganic, name: "Organic" },
    ];
    setChartData(data);
  }, [countWalkIn, countAdPage, countOrganic]);

  return (
    <>
      <Container
        className="mt-1 pt-3"
        style={{
          marginTop: "200px",
          backgroundColor: "white",
          paddingBottom: "19px",
          boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
        }}
      >
        <Row>
          <Col>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "28px",
                fontWeight: "500",
              }}
            >
              Enquiry Count
            </p>
          </Col>
          <Col>
            <Form.Select
              className="me-2"
              style={{ width: "200px", float: "right" }}
              name="program"
              onChange={(e) => setProgram(e.target.value)}
              value={program}
              aria-label="Default select example"
            >
              <option value="total">Total</option>
              <option value="bba">BBA</option>
              <option value="bca">BCA</option>
              <option value="b.com(h)">B.COM</option>
              <option value="mba">MBA</option>
              <option value="b.ed">B.ED</option>
              <option value="mca">MCA</option>
              <option value="ba.llb">B.A.LLB</option>
              <option value="llb">LLB</option>
              <option value="b.com.llb">B.COM.LLB</option>
              <option value="llm">LLM</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <div className="card">
              <ReactEcharts
                style={{ height: "250px" }}
                option={{
                  ...echartPieOption,
                  series: [
                    {
                      ...echartPieOption.series[0],
                      data: chartData, // Pass dynamic chart data here
                    },
                  ],
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
