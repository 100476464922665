import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/index";
import cookie from "js-cookie";
import validator from "validator";
import base64 from "base-64";
import utf8 from "utf8";
import { notify_error, notify_success } from "./Notify";
import { serverAddress } from "../../src/state/constants/content_types";
import UserAPI from "../utils/api/UserAPI";
import './Login.css';

// import Spinner from './spinner/Spinner';
const Login = (props) => {
  const initialState = {
    email: "",
    password: "",
  };
  const style = {
    color: "red",
  };



  const navigate = useNavigate();


  const [credentials, setCredentials] = useState(initialState);
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [forget, setForget] = React.useState({ form_step: 0 });
  let [otpTimer, setOtpTimer] = React.useState(59);
  const [timerFlag, setTimerFlag] = React.useState(0);
  const [email, setEmail] = React.useState(null);
  const [otp, setOtp] = React.useState({ otp: "", username: "" });
  const [resetPassword, setResetPassword] = React.useState({
    password: "",
    c_password: "",
    username: "",
  });
  const [updatePassword, setUpdatePassword] = React.useState({
    new_password: "",
    old_password: "",
    username: "",
  });

  const [start, setStart] = React.useState(1);
  const [signedIn, setSignedIn] = useState(false);

  const handleCheckboxChange = () => {
    setSignedIn((prevValue) => !prevValue); // Toggle the state value (true to false or false to true)
  };
  // const [loading, setLoading] = useState(true);
  var flag = 0;


  // useEffect(()=>{
  //     console.log(signedIn)
  // },[signedIn])

  useEffect(() => {
    const ajwt = cookie.get("ajwt");
    if (ajwt) {
      // Redirect to dashboard if user is logged in
      navigate("/dashboard");
    }
  }, [navigate]);






  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    document.getElementById("sign_in").disabled = true;
    document.getElementById("sign_in").innerHTML = "Loading...";
    if (!validator.isEmail(updatePassword.username)) {
      flag = 1;
      // } if (validator.isEmpty(updatePassword.old_password) || validator.isEmpty(updatePassword.new_password)) {
      //    flag = 1;
      // } else {
      flag = 0;
    }
    if (flag === 1) {
      return false;
    }
    const bytes = utf8.encode(updatePassword.old_password);

    top_loader(true, 10);

    top_loader(true, 30);
    const bodyContent = JSON.stringify(updatePassword);
    const response = await UserAPI.updatePasswordNew(bodyContent,signedIn);

    top_loader(true, 70);
    const json = await response.json();
    top_loader(true, 90);
    // // console.log(json);
    if (
      json.success &&
      // (json.user.role === "admin" ||
      //   json.user.role === "super_admin" ||
      //   json.user.role == "admission" ||
      //   json.user.role == "event" ||
      //   json.user.role == "counsellor" ||
      //   json.user.role == "reception") 
      json.user.role && 
      json.user.status
    ) {
      top_loader(true, 100);
      if(signedIn){
        cookie.set("ajwt", json.token, { expires: 7*4 });

      }else if(!signedIn){
        
        cookie.set("ajwt", json.token, { expires: 7 });
      }
      cookie.set("user", json.user._id);
      cookie.set("role", json.user.role.join(',')); // Convert roles array to string
      cookie.set("feature",json.user.feature_access.join(','));
      cookie.set("name", `${json.user?.firstname} ${json.user?.lastname}`);
      cookie.set("firstname", json.user.firstname);
      cookie.set("lastname", json.user.lastname);
      if (json.user?.image) {
        cookie.set("image", json.user?.image);
      }

      notify_success("Password Updated Successfully..");
      navigate("/dashboard/");
      top_loader(false, 0);
    } else {
      notify_error(json.message);
      document.getElementById("sign_in").disabled = false;
      document.getElementById("sign_in").innerHTML = "SIGN IN";
      top_loader(false, 100);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("sign_in").disabled = true;
    document.getElementById("sign_in").innerHTML = "Loading...";
    if (!validator.isEmail(credentials.username)) {
      flag = 1;
    }
    if (validator.isEmpty(credentials.password)) {
      flag = 1;
    } else {
      flag = 0;
    }
    if (flag === 1) {
      return false;
    }
    const bytes = utf8.encode(credentials.password);

    top_loader(true, 10);
    const headers = {
      "Content-Type": "application/json",
    };
    top_loader(true, 30);
    const bodyContent = JSON.stringify({
      username: credentials.username,
      password: credentials.password,
    });
    const response = await UserAPI.loginUser(bodyContent,signedIn);
    top_loader(true, 70);
    const json = await response.json();
    top_loader(true, 90);
    if (
      json.success &&
      // (json.user.role === "admin" ||
      //   json.user.role === "super_admin" ||
      //   json.user.role == "admission" ||
      //   json.user.role == "event" ||
      //   json.user.role == "counsellor" ||
      //   json.user.role == "reception") 
      json.user.role && // Check if roles array is present in the response
      json.user.status
    ) {
      top_loader(true, 100);
      if(signedIn){
        cookie.set("ajwt", json.token, { expires: 7*4 });

      }else if(!signedIn){
        
        cookie.set("ajwt", json.token, { expires: 7 });
      }
      cookie.set("user", json.user._id);
      cookie.set("role", json.user.role.join(','));
      cookie.set("feature",json.user.feature_access.join(','));
      cookie.set("name", `${json.user?.firstname} ${json.user?.lastname}`);
      cookie.set("firstname", json.user.firstname);
      cookie.set("lastname", json.user.lastname);
      if (json.user?.image) {
        cookie.set("image", json.user?.image);
      }
      notify_success("Logged In Successfully..");
      navigate("/dashboard/");
      top_loader(false, 0);
    } else {
      notify_error(json.message);
      document.getElementById("sign_in").disabled = false;
      document.getElementById("sign_in").innerHTML = "SIGN IN";
      top_loader(false, 100);
    }
  };

  


  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleForgetPassword = (e) => {
    e.preventDefault();
    setForget({ form_step: 5 });
  };
  const gotToLoginForm = (e) => {
    e.preventDefault();
    setForget({ form_step: 0 });
  };
  const gotToOTPForm = (e) => {
    e.preventDefault();
    let flag = 0;
    if (!validator.isEmail(email?.username)) {
      flag = 1;
    }
    setOtpTimer(59);
    if (flag === 1) {
      document.getElementById("show_email").style.display = "block";
    } else {
      setTimerFlag(1);
      runTimer();
      UserAPI.verifyEmail(email)
        .then((result) => {
          if (result?.data?.status) {
            setForget({ form_step: 2 });
            // run resend otp timer
          } else {
            document.getElementById("show_email").style.display = "block";
            document.getElementById("show_email").innerHTML = "Invalid email.";
          }
        })
        .catch((err) => {
          // console.log(err, " error");
        });
    }
  };
  const gotToUpdatePwdForm = (e) => {
    e.preventDefault();
    let flag = 0;
    if (validator.isEmpty(otp?.otp)) {
      flag = 1;
    }
    if (flag === 1) {
      document.getElementById("show_otp").style.display = "block";
    } else {
      UserAPI.verifyOTP(otp)
        .then((result) => {
          if (result?.data?.status) {
            setForget({ form_step: 3 });
            setResetPassword({ ...resetPassword, username: otp?.username });
            // run resend otp timer
          } else {
            document.getElementById("show_otp").style.display = "block";
            document.getElementById("show_otp").innerHTML = "OTP not matched.";
          }
        })
        .catch((err) => {
          // // console.log(err, " error");
        });
    }
  };

  const runTimer = () => {
    let timer_interval = setInterval(() => {
      if (otpTimer === 0 && timerFlag === 0) {
        clearInterval(timer_interval);
        setTimerFlag(1);
        setOtpTimer(59);
      } else {
        otpTimer = otpTimer - 1;
        setOtpTimer(otpTimer);
        setTimerFlag(0);
        if (otpTimer === 0) {
          clearInterval(timer_interval);
          setOtpTimer(59);
          return;
        }
      }
    }, 1000);
  };

  const onEmailChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setEmail({ [name]: value });
    setOtp({ ...otp, username: value });
    document.getElementById("show_email").style.display = "none";
  };
  const onOTPChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setOtp({ ...otp, [name]: value });
    document.getElementById("show_otp").style.display = "none";
  };

  const onUpdatePasswordChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setUpdatePassword({ ...updatePassword, [name]: value });
    document.getElementById("show_" + name).style.display = "none";
  };

  const onPasswordChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setResetPassword({ ...resetPassword, [name]: value });
    document.getElementById("show_" + name).style.display = "none";
  };

  const updateResetPassword = (e) => {
    e.preventDefault();
    let flag = 0;
    if (validator.isEmpty(resetPassword?.password)) {
      flag = 1;
      document.getElementById("show_password").style.display = "block";
    }
    if (validator.isEmpty(resetPassword?.c_password)) {
      flag = 1;
      document.getElementById("show_c_password").style.display = "block";
    }
    if (resetPassword?.password !== resetPassword?.c_password) {
      flag = 1;
      document.getElementById("show_c_password").style.display = "block";
      document.getElementById("show_c_password").innerHTML =
        "Password Not Matched.";
    }
    if (flag === 1) {
      // document.getElementById("show_c_password").style.display = "block";
    } else {
      UserAPI.UpdatePassword(resetPassword)
        .then((result) => {
          if (result?.data?.status) {
            setForget({ form_step: 0 });
            notify_success("Password updated successfully");
          } else {
            document.getElementById("show_c_password").style.display = "block";
            document.getElementById("show_c_password").innerHTML =
              "Failed to update the password";
          }
        })
        .catch((err) => {
          // // console.log(err, " error");
        });
    }
  };

  return (
    <>
      {forget.form_step === 0 ? (
        <div className="" style={{fontFamily:"Inter"}}>
            {/* Login Page */}
            <div className="divLogin1  ">
              <img className="loginImage1 " height={'100%'} width={'100%'} src="/assets/images/Login1.png"></img>
              
              <div className="card1 col-lg-4 mx-auto my-auto login_page">
                  <div className="auth-form-light text-left p-5">
                    <h4 className="loginHeading1">Welcome to <span className="span1">IPEM</span></h4>
                    <h2 className="loginHeading2">Sign In</h2>
                    <form className="pt-2" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <div className="loginHeading3">Enter your username or email address</div>
                        <input
                          type="email"
                          name="username"
                          required
                          className="form-control form-control-lg"
                          id="email"
                          placeholder="Username or email address"
                          onChange={onChange}
                          style={{border:"solid" , borderWidth:"thin"}}
                        />
                        <span className="hide err" style={style} id="err_email">
                          Please Enter email
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="loginHeading3">Enter your Password</div>
                        <input
                          type="password"
                          name="password"
                          required
                          className="form-control form-control-lg"
                          id="password"
                          placeholder="Password"
                          onChange={onChange}
                          style={{border:"solid" , borderWidth:"thin"}}
                        />
                        <span className="hide err" id="err_password">
                          Please Enter password
                        </span>
                      </div>
                      <div className=" d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label text-muted">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={handleCheckboxChange}
                            />{" "}
                            Keep me signed in <i className="input-helper" />
                          </label>
                        </div>
                        <Link
                          to="#"
                          className="auth-link text-blue"
                          onClick={handleForgetPassword}
                        >
                          Update Password?
                        </Link>
                      </div>
                      <div className="divButton1 mt-3 mb-5  ">
                        <button
                          className="loginButton1 btn btn-block btn-lg   auth-form-btn"
                          id="sign_in"
                          type="submit"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              
            </div>
          </div>
      ) : (
        ""
      )}

      {forget.form_step === 1 ? (
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
              <div className="row flex-grow">
                <div className="col-lg-4 mx-auto login_page">
                  <div className="auth-form-light text-left p-5">
                    <div className="brand-logo">
                     <img src="/assets/images/maroon.png" /> 
                    {/*  <p>IPEM</p>  */}
                    </div>
                    <h4>Please enter your registered email</h4>
                    <form className="pt-3" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          name="username"
                          required
                          className="form-control form-control-lg"
                          id="email"
                          placeholder="email"
                          onChange={onEmailChange}
                        />
                        <span
                          className="hide err"
                          style={style}
                          id="show_email"
                        >
                          Please Enter correct email
                        </span>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={gotToOTPForm}
                        >
                          NEXT
                        </button>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label text-muted"></label>
                        </div>
                        <Link
                          to="#"
                          className="auth-link text-black"
                          onClick={gotToLoginForm}
                        >
                          Back to Login
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      ) : (
        ""
      )}

      {forget.form_step === 2 ? (
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
              <div className="row flex-grow">
                <div className="col-lg-4 mx-auto login_page">
                  <div className="auth-form-light text-left p-5">
                    <div className="brand-logo">
                      {/* <img src="/assets/images/logo.svg" /> */}
                      <p>IPEM</p>
                    </div>
                    <h4> Enter Your OTP</h4>
                    <p
                      style={{ fontSize: "1em" }}
                    >{`Please enter the otp send to your email (*******${email?.username?.slice(
                      email.username.length / 2
                    )}).`}</p>
                    <form className="pt-3" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="number"
                          name="otp"
                          required
                          className="form-control form-control-lg"
                          id="otp"
                          placeholder="Enter your OTP"
                          onChange={onOTPChange}
                        />
                        <span className="hide err" style={style} id="show_otp">
                          Please Enter otp
                        </span>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={gotToUpdatePwdForm}
                        >
                          NEXT
                        </button>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label
                            className="form-check-label text-muted"
                            id="otp_time"
                          >
                            Resend OTP In {otpTimer === 59 ? "0" : otpTimer}{" "}
                            Seconds
                          </label>
                        </div>
                        <Link
                          to="#"
                          className="auth-link"
                          style={{
                            cursor: "pointer",
                            color: `${
                              otpTimer == 0 || otpTimer === 59 ? "blue" : "#fff"
                            }`,

                            pointerEvents: `${
                              otpTimer == 0 || otpTimer === 59 ? "auto" : "none"
                            }`,
                          }}
                          onClick={gotToOTPForm}
                        >
                          Resend OTP
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      ) : (
        ""
      )}

      {forget.form_step === 3 ? (
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
              <div className="row flex-grow">
                <div className="col-lg-4 mx-auto login_page">
                  <div className="auth-form-light text-left p-5">
                    <div className="brand-logo">
                      {/* <img src="/assets/images/logo.svg" /> */}
                      <p>IPEM</p>
                    </div>
                    <h4> Update Your Password</h4>
                    <form className="pt-3">
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          required
                          className="form-control form-control-lg"
                          id="new_password"
                          placeholder="Enter your new password"
                          onChange={onPasswordChange}
                        />
                        <span
                          className="hide err"
                          style={style}
                          id="show_password"
                        >
                          Please enter your new password
                        </span>
                      </div>

                      <div className="form-group">
                        <input
                          type="password"
                          name="c_password"
                          required
                          className="form-control form-control-lg"
                          id="c_password"
                          placeholder="Enter your confirm password"
                          onChange={onPasswordChange}
                        />
                        <span
                          className="hide err"
                          style={style}
                          id="show_c_password"
                        >
                          Please enter your confirm password
                        </span>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={updateResetPassword}
                        >
                          NEXT
                        </button>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label text-muted"></label>
                        </div>
                        {/* <Link to="#" className="auth-link text-black" onClick={gotToEmailForm}>Back to Login</Link> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      ) : (
        ""
      )}

      {forget.form_step == 5 ? (
        <div className="">
            {/* Login Page */}
            <div className="divLogin1  ">
              <img className="loginImage1 " height={'100%'} width={'100%'} src="/assets/images/Login1.png"></img>
              <div className="card1 col-lg-4 mx-auto my-auto login_page">
                  <div className="auth-form-light text-left p-5">
                    <h4 className="loginHeading1">Welcome to <span className="span1">IPEM</span></h4>
                    <h2 className="loginHeading2">Sign In</h2>
                    <form className="pt-2" onSubmit={handleUpdatePassword}>
                      <div className="form-group">
                        <div className="loginHeading3">Enter your username or email address</div>
                        <input
                          type="email"
                          name="username"
                          required
                          className="form-control form-control-lg"
                          id="email"
                          placeholder="Username or email address"
                          onChange={onUpdatePasswordChange}
                        />
                        <span className="hide err" style={style} id="err_email">
                          Please Enter email
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="loginHeading3">Enter Your Old Password</div>
                        <input
                          type="password"
                          name="old_password"
                          required
                          className="form-control form-control-lg"
                          id="password"
                          placeholder="Password"
                          onChange={onUpdatePasswordChange}
                        />
                        <span className="hide err" id="err_password">
                          Please Enter Your Old password
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="loginHeading3">Enter Your New Password</div>
                        <input
                          type="password"
                          name="new_password"
                          required
                          className="form-control form-control-lg"
                          id="new_password"
                          placeholder="New Password"
                          onChange={onUpdatePasswordChange}
                        />
                        <span className="hide err" id="err_password">
                          Please Enter Your New password
                        </span>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                           <label className="form-check-label text-muted">
                            <input
                               type="checkbox"
                               className="form-check-input"
                          />{" "}
                            Keep me signed in <i className="input-helper" />
                          </label>
                        </div>
                        <Link
                          to="#"
                          className="auth-link text-blue"
                          onClick={gotToLoginForm}
                        >
                           Back to Login
                        </Link>
                      </div>
                      <div className="divButton1 mt-1 mb-3 ">
                        <button
                          className="loginButton1 btn btn-block btn-lg   auth-form-btn"
                          id="sign_in"
                          type="submit"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
          </div>
      ) : (
        ""
      )}








    </>
  );
};
export default Login;
