import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';



const AdminAPI={
    //total Ad-enquiry
    countAdEnquiry: (params) => {
        // let url = serverAddress + `/api/website/enquiry/exportData`;
        let url = serverAddress + `/website/enquiry/exportData`;
    return axios.post(url,params, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });
},
};

export default AdminAPI;