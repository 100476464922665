import { ALL_ORGANISATION,ALL_CATEGORY,ADD_CATEGORY,DELETE_CATEGORY,UPDATE_CATEGORY, GET_ORGANISATION_PROFILE } from "../constants/content_types";
const initialState = {
    status: "",
    totalResult: "",
    data: [],
}

const reducer = (state = initialState, action) => {

    if (action.type === ALL_ORGANISATION) {
        return action.payload;
    } 
    
    if (action.type === GET_ORGANISATION_PROFILE) {
        return action.payload;
    } 
    else if(action.type===ADD_CATEGORY){
        const data = action.payload;
        return{
            ...state,totalResult:state.totalResult+1,
            data:[
                ...state.data,{
                    cate_name:data.cate_name,
                    cate_code:data.cate_code,
                    status:"active"
                }
                
            ]
        }
            
    }else if(action.type===DELETE_CATEGORY){
            const cate_code = action.payload;
        const newCategory = state.data.filter((category)=>{
                return category.cate_code!==cate_code;
        });

        return {
            ...state,totalResult:state.totalResult-1,
            data:newCategory
        }

    }else if(action.type===UPDATE_CATEGORY){
        const {cate_code,cate_name,status} = action.payload;
        const newCategory = state.data;

        for(let index=0;index<newCategory.length;index++){
            const element = newCategory[index];
            if(element.cate_code===cate_code){
                newCategory[index].cate_name = cate_name;
                newCategory[index].status = status;
                break; 
            }
        }
        
        return {
            ...state,
            data:[
                ...state.data=newCategory
            ]
        }

    }else{
        return state;
    }
}

export default reducer;