import { createContext, useEffect, useState } from 'react';
import OnlineEnquiryAPI from '../../utils/api/OnlineEnquiryAPI';
import { notify_error } from '../../components/Notify';
import FeedbackAPI from '../../utils/api/FeedbackAPI';

export const FeedbackContext = createContext();


export default function FeedbackProvider({children}) {
    const [activepage,SetActivePage]=useState(1);
    const [recperpage,SetRecPerPage]=useState(25);
    const [data, setDate] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [filter,setFilter]=useState([{ field: "feedbackType", selected: ['student_feedback'] },
    { field: "course", selected: [] },
    { field: "batch", selected: [] },
    { field: "campus", selected: [] },
    { field: "department", selected: [] },
    { field: "date", selected: { start: "", end: "" } },]);
    const [search,SetSearch] = useState("");
    const [activeFeedback,setActiveFeedback]=useState('student_feedback');


    const filterEmptySelected = (arr) => {
        return arr.filter((obj) => {
          if (obj.field === "date" && obj.selected && obj.selected.start !== "" && obj.selected.end !== "") {
            return true; // Retain the "date" object if start and end values are not empty
          }

          const hasSelectedValues =
            obj.selected &&
            obj.selected.length > 0 &&
            (!Array.isArray(obj.selected) ||
              (Array.isArray(obj.selected) &&
                obj.selected.length > 0 &&
                obj.selected[0] !== null));
          return hasSelectedValues;
        });
      };

      useEffect(()=>{
        applyFilter();
      },[recperpage])


      function applyFilter(event){
        // SetRecPerPage(event.target.value);
        // SetActivePage(1);
    

        if(filterEmptySelected(filter).length>0){
            const newData=filterEmptySelected((filter));
            FeedbackAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(totalData);
                return;
                
              })
        }else{
            FeedbackAPI.getAllStudentFeedback({page:activepage,limit:recperpage}).then((result)=>{
                // console.log(result,"result");
                if(result.data.status){
                    setDate(result.data.data);
                    setTotalData(result.data.total);
                    return;
                

                }else{
                    setDate([]);
                    setTotalData(0)
                    notify_error("Something went wrong")
                    return;
                }

            })
        }

      }


      useEffect(()=>{
        filter.filter((obj)=>{
            if(obj.field ==='feedbackType' && obj.selected.includes("student_feedback")){
                setActiveFeedback('student_feedback');
            }else if(obj.field ==='feedbackType' && obj.selected.includes("faculty_feedback")){
                    setActiveFeedback('faculty_feedback');
            }else if(obj.field ==='feedbackType' && obj.selected.includes("employer_feedback")){
                setActiveFeedback('employer_feedback');
            }else if(obj.field ==='feedbackType' && obj.selected.includes('alumni_feedback')){
              setActiveFeedback('alumni_feedback');

            }else if(obj.field ==='feedbackType' && obj.selected.includes('parent_feedback')){
              setActiveFeedback('parent_feedback')
            }
            else if(obj.field ==='feedbackType' && obj.selected.includes('student_satisfaction')){
              setActiveFeedback('student_satisfaction')
            }
            else if(obj.field ==='feedbackType' && obj.selected.includes('subject_feedback')){
              setActiveFeedback('subject_feedback')
            }
        })
        handleApplyFilter();

      },[filter]);

    //   useEffect(()=>{
    //     // console.log(activeFeedback);
    //   },[activeFeedback])


      const handleApplyFilter = () => {
        const newData = filterEmptySelected(filter);
        SetActivePage(1);
        // console.log(newData);
    
        FeedbackAPI.dynamicFilter(newData, activepage, recperpage).then((res) => {
          setDate(res.data.data);
          setTotalData(res.data.total);
          // console.log(totalData);
        });
      };


useEffect(()=>{
  handlePageChange(activepage);
},[activepage])


    const handlePageChange=(page)=>{
        SetActivePage(page);
      

        if(filterEmptySelected(filter).length>0){
            const newData=filterEmptySelected((filter));
            FeedbackAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(totalData);
                return;
                
              })
        }else{
            FeedbackAPI.getAllStudentFeedback({page:activepage,limit:recperpage}).then((result)=>{
                // console.log(result,"result");
                if(result.data.status){
                    setDate(result.data.data);
                    setTotalData(result.data.total);
                    return;
                

                }else{
                    setDate([]);
                    setTotalData(0)
                    notify_error("Something went wrong")
                    return;
                }

            })
        }
    }



    const value={
        activepage,
        recperpage,
        data,
        SetActivePage,
        SetRecPerPage,
        setDate,
        totalData,
        setTotalData,
        filter,
        setFilter,
        search,
        SetSearch,
        handlePageChange,
        applyFilter,
        activeFeedback,
        setActiveFeedback
    }

    return (
        <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>
    )


}