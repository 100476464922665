import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ remarkData, setRemarkData, handleRemarks }) => {
  const [content, setContent] = useState("");

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSave = (e) => {
    const cleanedContent = content.replace(/<\/?[^>]+(>|$)/g, ""); // remove all tags
    handleRemarks(cleanedContent);
    setContent("");
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
        ["clean"],
      ],
    },
  };

  return (
    <div className="text-editor-container">
      <ReactQuill
        value={content}
        onChange={handleChange}
        modules={modules}
        className="text-editor"
      ></ReactQuill>
      <div className="save-button-div ">
        <button
          onClick={handleSave}
          className="save-button mt-2 px-4"
          style={{ border: "none" }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default TextEditor;
