import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { programsColumn, feedbackOptions, serverAddress } from "../../constant";

const batchOptions = ["2019-23", "2020-24"];

const initialValues = {
  feedback: "",
  program: "",
  batch: "",
};

const validationSchema = Yup.object().shape({
  feedback: Yup.string().required("Feedback is required"),
  program: Yup.string().when("feedback", {
    is: (val) =>
      ["Student Feedback", "Alumni Feedback", "Student Satisfaction"].includes(
        val
      ),
    then: () =>
      Yup.string().required(
        "Program is required when feedback is Student Feedback, Alumni Feedback, or Student Satisfaction"
      ),
    otherwise: () => Yup.string().notRequired(),
  }),
  batch: Yup.string().when("feedback", {
    is: "Alumni Feedback",
    then: () =>
      Yup.string().required(
        "Batch is required when feedback is Alumni Feedback"
      ),
    otherwise: () => Yup.string().notRequired(),
  }),
});

function ReportModal({ onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    if (isLoading) return; // Prevent multiple requests
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${serverAddress}/feedback/report/export`,
        {
          feedback: values.feedback ?? "",
          program: values.program ?? "",
          batch: values.batch ?? "",
        },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "FeedbackReport.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      // Delay by 2 second
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  return (
    <div
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(31, 41, 55, 0.5)",
        position: "fixed",
        zIndex: "100",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "1rem",
          borderRadius: "0.375rem",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)",
          maxWidth: "24rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <span>Generate Report</span>
        </div>
        <div
          style={{
            borderTop: "1px solid rgba(116, 134, 212, 0.2)",
            width: "100%",
          }}
        ></div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form style={{ width: "100%" }}>
              <div style={{ marginBottom: "1rem" }}>
                <label
                  htmlFor="feedback"
                  style={{ display: "block", marginBottom: "0.5rem" }}
                >
                  Feedback
                </label>
                <Field
                  as="select"
                  name="feedback"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "0.375rem",
                  }}
                >
                  <option value="">Select Feedback</option>
                  {feedbackOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="feedback"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              {[
                "Student Feedback",
                "Alumni Feedback",
                "Student Satisfaction",
              ].includes(values.feedback) && (
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor="program"
                    style={{ display: "block", marginBottom: "0.5rem" }}
                  >
                    Program
                  </label>
                  <Field
                    as="select"
                    name="program"
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <option value="">Select Program</option>
                    {programsColumn.map((program) => (
                      <option key={program} value={program}>
                        {program}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="program"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              )}

              {values.feedback === "Alumni Feedback" && (
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    htmlFor="batch"
                    style={{
                      display: "block",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Batch
                  </label>
                  <Field
                    as="select"
                    name="batch"
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <option value="">Select Batch</option>
                    {batchOptions.map((batch) => (
                      <option key={batch} value={batch}>
                        {batch}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="batch"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <button
                  type="submit"
                  disabled={isLoading}
                  className="spButton1"
                  style={{
                    // backgroundColor: "#007BFF",
                    // color: "white",
                    padding: "0.5rem 1rem",
                    // borderRadius: "0.375rem",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    cursor: isLoading ? "not-allowed" : "pointer",
                    transition: "filter 0.2s",
                    outline: "none",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "1rem",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.transform = "scale(1.02)")
                  }
                  onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                >
                  {isLoading && (
                    <ClipLoader
                      color="white"
                      size={20}
                      loading={isLoading}
                      speedMultiplier={0.8}
                    />
                  )}
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="spButton2"
                  style={{
                    // backgroundColor: "#D9534F",
                    // color: "white",
                    padding: "0.5rem 1rem",
                    // borderRadius: "0.375rem",
                    width: "100%",
                    cursor: "pointer",
                    transition: "filter 0.2s",
                    outline: "none",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "1rem",
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ReportModal;
