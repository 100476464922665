import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import UllasAPI from "../../utils/api/UllasAPI";
import { format } from "date-fns";
import { Table, Pagination, Button, Badge,DatePicker, Tooltip } from "antd";
import { PlusCircleOutlined , RetweetOutlined} from "@ant-design/icons";

import moment from "moment";
import { serverAddress } from "../../constant";
import "antd/dist/antd.css";
import ViewUllasData from "./ViewUllasData";
const CareerTable = () => {
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);

  useEffect(() => {
    top_loader(true, 45);
    getAllUllas({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  const handleRefresh=() => {
    top_loader(true, 45);
    getAllUllas({ page: activepage, limit: recperpage });
  }

  const getAllUllas = (params) => {
    setLoading(true);
    UllasAPI.getAllUllas(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handleRowClick = (record) => {
    if (expandedRowKey === record.sno) {
      setExpandedRowKey(null);
    } else {
      setExpandedRowKey(record.sno);
    }
  };

  function handlePageChange(page) {
    SetActivePage(page);
    getAllUllas({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllUllas({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      fixed: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.sno}</span>
          </div>
        </div>
      )
    },
    {
      title: "Name",
      width: 100,
      dataIndex: "applicant_name",
      key: "applicant_name",
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.name}</span>
          </div>
        </div>
      )
    },
    {
      title: "Father Name",
      width: 100,
      dataIndex: "father_name",
      key: "father_name",
      sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.father_name}</span>
          </div>
        </div>
      )
    },
    {
      title: "Applicant Email",
      width: 100,
      dataIndex: "email",
      key: "email",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.email}</span>
          </div>
        </div>
      )
    },
    {
      title: "Mobile",
      width: 100,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.mobile_number}</span>
          </div>
        </div>
      )
    },

    {
      title: "Program",
      width: 100,
      dataIndex: "program",
      key: "program",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.program}</span>
          </div>
        </div>
      )
    },
    {
      title: "Institute Name",
      width: 100,
      dataIndex: "institute_name",
      key: "institute_name",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.institute_name}</span>
          </div>
        </div>
      )
    },
    {
      title: "Institute Contact",
      width: 100,
      dataIndex: "institute_mobile_number",
      key: "institute_mobile_number",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.institute_mobile_number}</span>
          </div>
        </div>
      )
    },
    {
      title: "Contact Person",
      width: 100,
      dataIndex: "contact_person",
      key: "contact_person",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.contact_person}</span>
          </div>
        </div>
      )
    },
    {
      title: "Postal Address",
      width: 100,
      dataIndex: "postal_address",
      key: "postal_address",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.postal_address}</span>
          </div>
        </div>
      )
    },
    {
      title: "HOD/Co-Ordinator Contact",
      width: 100,
      dataIndex: "mobile_number_hod_coordiantor",
      key: "mobile_number_hod_coordiantor",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.mobile_number_hod_coordiantor}</span>
          </div>
        </div>
      )
    },
    {
      title: "Email ID",
      width: 100,
      dataIndex: "email_id",
      key: "email_id",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.email_id}</span>
          </div>
        </div>
      )
    },
    {
      title: "Payment Status",
      width: 100,
      dataIndex: "isPaymentDone",
      key: "isPaymentDone",
      fixed: "right",
      filters: [
        {
          text: "Done",
          value: true
        },
        {
            text: "Not Paid",
            value: false
          },
   
      ],
      onFilter: (value, record) => record.isPaymentDone === value,
      filterSearch: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            {record.isPaymentDone ? (
              <Badge
                className="site-badge-count-109"
                count={"Done"}
                style={{
                  backgroundColor: "#52c41a"
                }}
              />
            ) : (
              <Badge count={"Not Paid"} color="#faad14" />
            )}
          </div>
        </div>
      )
    },
    {
      title: "Amount",
      width: 100,
      dataIndex: "amount",
      key: "amount",
      fixed: "right",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>Rs.{record.amount}</span>
          </div>
        </div>
      )
    },

    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      fixed: "right",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
            onChange={(date) => setSelectedKeys(date ? [date.startOf('day').toDate()] : [])}
            onClear={() => {
              setSelectedKeys([]);
              clearFilters();
              confirm();
            }}
            disabledDate={(current) => current && current > moment().endOf('day')}
            picker="year"
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
          >
            Filter
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const date = moment(record.created_at).format('yyyy-MM-DD');
        return moment(date).isSame(moment(value).format('yyyy-MM-DD'),'year');
      },
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "MM/dd/yyyy")}</span>
          </div>
        </div>
      )
    }
    /* {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record, index1) => (
        <div>
          <Dropdown overlay={menu(record)}>
            <a onClick={(e) => e.preventDefault()}>
              <Space size={"small"}>
                <MoreOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      )
    } */
  ];

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        name: item.applicant_name,
        father_name: item.applicant_father_name,
        program: item.applicant_course,
        email: item.applicant_email,
        mobile_number: item.applicant_mobile,
        institute_name: item.institute_name,
        institute_mobile_number: item.institute_mobile_number,
        postal_address: item.postal_address,
        contact_person: item.contact_person,
        mobile_number_hod_coordiantor: item.mobile_number_hod_coordiantor,
        email_id: item.email_id,
        amount: item.amount,
        isPaymentDone: item.isPaymentDone,
        created_at: item.created_at,
        day_one_activity: item.day_one_activity,
        day_two_activity: item.day_two_activity,
        operation: {
          _id: item._id,
          image: item.image,
          downloadLink: item.downloadLink
        }
      });
    });
  }





  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title="Ullas List" />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
           <div className="row">
            <div className="col-md-3 form-inline">
              <div className="row align-items-center mb-3">
                <div className="col-md-4">
                  <label className="control-label">Show :</label>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(event) => applyFilter(event)}
                    value={recperpage}
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25000</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-4">per page</div>
              </div>
            </div>
            <div className="col-md-9">
            <Tooltip placement="top" title="refresh" color="geekblue"> 
              <Button
                  type="primary"
                  className="mb-3 me-1"
                  style={{ float: "right",backgroundColor:'green'}}
                  onClick={handleRefresh}
                  icon={<i class="fa-solid fa-rotate-right"></i>}
                  size={"large"}
                />
                </Tooltip>
              
              </div>




            </div>
            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              scroll={{ x: 1700, y: 500 }}
              expandable={{
                expandedRowRender: (record) =>
                  record.sno === expandedRowKey ? (
                    <ViewUllasData props={record}/>
                  ) : (
                    ""
                  ),
                onExpand: (expanded, record) => {
                  if (expanded) {
                    handleRowClick(record);
                  } else {
                    setExpandedRowKey(null);
                  }
                },
              }}
              
              onRow={(record) => ({
                onClick: () => {
                  handleRowClick(record);
                }
              })
              }
              
        
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerTable;
