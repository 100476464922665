import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Badge,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import cookie from "js-cookie";
import { notify_error } from "./Notify";
import FileSaver from "file-saver";
// import {Button } from 'antd'
import jsonexport from "jsonexport/dist";

import RangeDatePicker from "./Calendar";
// import UserAPI from "../../utils/api/UserAPI";
// import OnlineEnquiryAPI from "../../utils/api/OnlineEnquiryAPI";
import "./FilterOnlineEnquiry.css";
import UserAPI from "../utils/api/UserAPI";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import { EnquiryContext } from "../state/context/EnquiryProvider";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import { DownloadOutlined } from "@ant-design/icons";
// import {notify_error} from "./Notify";
import { top_loader } from "../state/action-creators";
// console.log(result.data?.data.filter((item)=>item.role === 'counsellor'));

export default function FilterOnlineEnquiry(props) {
  const {
    filter,
    setFilter,
    search,
    SetSearch,
    recperpage,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(EnquiryContext);
  const [clearDate, setClearDate] = useState(false);
  // const [search, SetSearch] = useState("");
  // const [filter, setFilter] = useState([
  //   { field: "form_location", selected: [] },
  //   { field: "program", selected: [] },
  //   { field: "handled_by", selected: [] },
  //   { field: "assignedTo", selected: [] },
  //   { field: "admissionStatus", selected: [] },
  //   { field: "date", selected: { start: "", end: "" } },
  // ]);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [handlers, setHandlers] = useState([]);
  const [showHandler, setShowHandler] = useState(false);
  const [countInProcess, setCountInProcess] = useState(0);
  const [countAdmissionTaken, setCountAdmissionTaken] = useState(0);
  const [countNotInterested, setCountNotInterested] = useState(0);
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [totalOnlineEnquiry, setTotalOnlineEnquiry] = useState(0);
  const [totalAdOnlineEnquiry, setTotalAdOnlineEnquiry] = useState(0);
  const [isUnseen, setIsUnseen] = useState(false);

  let hand;

  useEffect(async () => {
    const fetchData = async () => {
      const params = { page: activepage, limit: 100 };

      try {
        const result = await UserAPI.getAllUsers(params);

        console.log("Check users ", result);

        const userRoles = cookie.get("role")
          ? cookie.get("role").split(",")
          : [];

        const mainRoles = ["counsellor", "admission"];

        console.log("user roles", userRoles);

        const filteredHandlers = result.data?.data?.filter((item) =>
          item.role?.some((role) => mainRoles.includes(role))
        );

        console.log("Filtered handlers:", filteredHandlers);

        setHandlers(filteredHandlers);

        if (userRoles.includes("counsellor")) {
          const res = await OnlineEnquiryAPI.dynamicFilter(
            [
              { field: "isReassigned", selected: [true] },
              { field: "assignedTo", selected: [cookie.get("name")] },
              { field: "hasNewAssigneeResponded", selected: [false] },
            ],
            1,
            25
          );
          setIsUnseen(res.data.total);
          // console.log(res.data.total);
        } else if (userRoles.includes("admission")) {
          const res = await OnlineEnquiryAPI.dynamicFilter(
            [
              { field: "isReassigned", selected: [true] },
              { field: "handled_by", selected: [cookie.get("name")] },
              { field: "hasNewAssigneeResponded", selected: [false] },
            ],
            1,
            25
          );
          setIsUnseen(res.data.total);
        }
      } catch (error) {
        notify_error(error.message);
      }
    };

    fetchData();
  }, [activepage, recperpage]);

  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      // Pad month and day with leading zeros if necessary
      month = month.toString().padStart(2, "0");
      day = day.toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    return "";
  };

  useEffect(() => {
    console.log("hanadlers list", handlers);
  });

  const handleSearch = () => {
    OnlineEnquiryAPI.searchQuery(
      { search: search },
      activepage,
      recperpage
    ).then((res) => {
      setDate(res.data.data);
      setTotalData(res.data.total);
      SetActivePage(1);
      // console.log(res.data)
    });
  };

  useEffect(() => {
    handleCountAdmissionStatus();
  }, []);

  const handleButtonClick = (buttonValue) => {
    SetActivePage(1);
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "form_location"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected?.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "form_location" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "form_location"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleClearFilter = () => {
    SetActivePage(1);
    setFilter([
      { field: "form_location", selected: [] },
      { field: "program", selected: [] },
      { field: "handled_by", selected: [] },
      { field: "assignedTo", selected: [] },
      { field: "admissionStatus", selected: [] },
      { field: "hasNewAssigneeResponded", selected: [] },

      { field: "isReassigned", selected: [] },
      { field: "date", selected: { start: "", end: "" } },
    ]);
    setClearDate(true);
  };

  const filterEmptySelected = (arr) => {
    return arr.filter((obj) => {
      if (
        obj.field === "date" &&
        obj.selected &&
        obj.selected.start !== "" &&
        obj.selected.end !== ""
      ) {
        return true; // Retain the "date" object if start and end values are not empty
      }
      if (
        obj.field === "admissionStatus" &&
        Array.isArray(obj.selected) &&
        obj.selected.includes("all-enquiry")
      ) {
        obj.selected.length = 0;
        return false; // Empty out the object if admissionStatus includes "all-enquiry"
      }

      if (
        obj.field === "isReassigned" &&
        Array.isArray(obj.selected) &&
        obj.selected.includes(false)
      ) {
        obj.selected.length = 0;
        // const fieldsToEmpty = ["handled_by", "assignedTo"];
        // fieldsToEmpty.forEach((field) => {
        //   const fieldObj = arr.find((f) => f.field === field);
        //   console.log(fieldObj);
        //   if (fieldObj) {
        //     fieldObj.selected.length = 0;
        //   }
        // });
        handleClearFilter();
        return false; // Empty out the object if admissionStatus includes "all-enquiry"
      }

      const hasSelectedValues =
        obj.selected &&
        obj.selected.length > 0 &&
        (!Array.isArray(obj.selected) ||
          (Array.isArray(obj.selected) &&
            obj.selected.length > 0 &&
            obj.selected[0] !== null));
      return hasSelectedValues;
    });
  };

  // const filterEmptySelected = (arr) => {
  //   return arr.filter((obj) => {
  //     return (
  //       obj.selected &&
  //       obj.selected.length > 0 &&
  //       (!Array.isArray(obj.selected) ||
  //         (Array.isArray(obj.selected) &&
  //           obj.selected.length > 0 &&
  //           obj.selected[0] !== null))
  //     );
  //   });
  // };

  // function convertRequestForm(requestForm) {
  //   // convert request form to array of objects
  //   // const originalList = JSON.parse(requestForm);
  //   const originalList =requestForm;

  //   // create new array of objects with desired format
  //   const newList = originalList.map(item => ({
  //     field: item.field,
  //     selected: item.selected
  //   }));

  //   // convert new array back to JSON
  //   const newRequest = JSON.stringify(newList);

  //   return newRequest;
  // }

  const handleApplyFilter = () => {
    const newData = filterEmptySelected(filter);
    SetActivePage(1);
    console.log(activepage);

    OnlineEnquiryAPI.dynamicFilter(newData, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(totalData);
      }
    );
  };

  const handleFilterExport = () => {
    SetActivePage(1);
    const newData = filterEmptySelected(filter);
    OnlineEnquiryAPI.downloadDynamicFilter(newData, activepage, recperpage)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link element to trigger the file download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "results.csv");
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the temporary link element and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error while downloading CSV:", error);
      });
  };

  const handleCountAdmissionStatus = () => {
    OnlineEnquiryAPI.dynamicFilter(
      [{ field: "admissionStatus", selected: ["in-process"] }],
      1,
      25
    ).then((res) => {
      setCountInProcess(res.data.total);
      // console.log(res);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [{ field: "admissionStatus", selected: ["not-interested"] }],
      1,
      25
    ).then((res) => {
      setCountNotInterested(res.data.total);
      // console.log(res);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [{ field: "admissionStatus", selected: ["admission-taken"] }],
      1,
      25
    ).then((res) => {
      setCountAdmissionTaken(res.data.total);
      // console.log(res);
    });

    EnquiryAPI.countTotatlEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setTotalEnquiry(result?.data?.data);
      } else {
        setTotalEnquiry(0);
      }
    });

    OnlineEnquiryAPI.countTotatlEnquiry().then((result) => {
      if (result?.data?.status) {
        setTotalOnlineEnquiry(result?.data?.data);
      } else {
        setTotalOnlineEnquiry(0);
      }
    });

    OnlineEnquiryAPI.countAdEnquiry().then((result) => {
      if (result?.data?.status) {
        setTotalAdOnlineEnquiry(result?.data?.data);
      } else {
        setTotalAdOnlineEnquiry(0);
      }
    });
  };

  const handleButtonClickStatus = (buttonValue) => {
    SetActivePage(1);
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "admissionStatus"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected?.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "admissionStatus"
            ? { ...f, selected: updatedSelected }
            : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter?.map((f) =>
          f.field === "admissionStatus"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleshowDuplicates = async (repeatValue) => {
    setFilter((prevFilter) => {
      const existingRepeatFilter = prevFilter.find((f) => f.field === "repeat");

      // If the filter for "repeat" already exists
      if (existingRepeatFilter) {
        // If the provided repeat value is already selected, remove it
        if (existingRepeatFilter.selected.includes(repeatValue)) {
          const updatedSelected = existingRepeatFilter.selected.filter(
            (value) => value !== repeatValue
          );
          return prevFilter.map((f) =>
            f.field === "repeat" ? { ...f, selected: updatedSelected } : f
          );
        } else {
          // If the provided repeat value is not selected, add it
          return prevFilter.map((f) =>
            f.field === "repeat"
              ? {
                  ...f,
                  selected: [...existingRepeatFilter.selected, repeatValue],
                }
              : f
          );
        }
      } else {
        // If the filter for "repeat" doesn't exist, create it
        return [...prevFilter, { field: "repeat", selected: [repeatValue] }];
      }
    });
  };

  const handleButtonClickProgram = (buttonValue) => {
    SetActivePage(1);
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "program"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "program" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "program"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleButtonClickReassign = (buttonValue) => {
    handleClearFilter();
    SetActivePage(1);
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "isReassigned"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, deselect the field by setting "selected" to an empty array
        return prevFilter.map((f) =>
          f.field === "isReassigned" ? { ...f, selected: [] } : f
        );
      } else {
        const userRoles = cookie.get("role")
          ? cookie.get("role").split(",")
          : [];

        if (userRoles.includes("admission")) {
          handleButtonAdmission(cookie.get("name"));
        } else if (userRoles.includes("counsellor")) {
          handleButtonHandler(cookie.get("name"));
        }

        // If not selected, update the "selected" array with the clicked buttonValue only
        return prevFilter.map((f) =>
          f.field === "isReassigned" ? { ...f, selected: [buttonValue] } : f
        );
      }
    });
  };

  const handleButtonHandler = (buttonValue) => {
    SetActivePage(1);
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "assignedTo"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "assignedTo" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "assignedTo"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleButtonAdmission = (buttonValue) => {
    SetActivePage(1);
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "handled_by"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "handled_by" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "handled_by"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  return (
    <>
      <Container
        className="py-3 mx-auto"
        style={{
          width: "100%",
          marginTop: "80px",
          backgroundColor: "white",
          // marginBottom: "15px",
          boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
          paddingLeft: "30px",
          fontFamily: "Inter",
          paddingRight: "40px",
        }}
      >
        {/* Filter By Team Members */}
        <Row style={{ left: "0px" }}>
          <div className="">Filter By Team Members :</div>
        </Row>

        <Row
          id="handler-container"
          style={{
            gap: "10px",
            flexWrap: "nowrap",
            overflowX: "scroll",
            marginLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {handlers &&
            handlers.map((user) => {
              // Check if the user has either or both roles
              const isAdmission = user.role?.includes("admission");
              const isCounsellor = user.role?.includes("counsellor");

              if (isAdmission || isCounsellor) {
                return (
                  <Col
                    key={user._id}
                    className="p-2"
                    onClick={() => {
                      handleButtonAdmission(
                        `${user?.firstname} ${user?.lastname}`
                      );
                    }}
                    style={{
                      fontSize: "15px",
                      backgroundColor: filter.some(
                        (f) =>
                          f.field === "handled_by" &&
                          f.selected.length > 0 &&
                          f.selected.includes(
                            `${user?.firstname} ${user?.lastname}`
                          )
                      )
                        ? "#F2F5F7"
                        : "white",
                    }}
                  >
                    <Image
                      src="https://img.freepik.com/premium-vector/user-customer-avatar-vector-illustration_276184-160.jpg"
                      fluid={true}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                    />
                    <span className="team-member-2">
                      {capitalizeWords(user.firstname)}
                    </span>
                  </Col>
                );
              }
              return null; // Render nothing for users without relevant roles
            })}
        </Row>

        {/* Filter By Status */}
        <Row className="team-member-1  my-2">
          <div className="team-member-1">Filter By Status :</div>
        </Row>
        <Row
          className="text-start gap-2 my-2"
          style={{ marginLeft: "0px", paddingRight: "0px" }}
        >
          <Col
            lg={12}
            onClick={() => handleButtonClickStatus("not-interested")}
            className={`border border-2 p-2 d-flex justify-content-between  ${
              filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("not-interested")
              )
                ? "border-danger"
                : "border-danger"
            }`}
            style={{
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("not-interested")
              )
                ? "#F7685B" // Dark background color when selected
                : "",
              color: filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("not-interested")
              )
                ? "#FFF" // Light text color when selected
                : "",
            }}
          >
            <span> Not-Interested</span>
            <span>
              <Badge bg="danger" style={{ borderRadius: "50%" }}>
                {countNotInterested}
              </Badge>
            </span>
          </Col>

          <Col
            lg={12}
            onClick={() => handleButtonClickStatus("in-process")}
            className={`border border-2 p-2 d-flex justify-content-between  ${
              filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("in-process")
              )
                ? "border-warning"
                : "border-warning"
            }`}
            style={{
              borderRadius: "4PX",
              cursor: "pointer",
              backgroundColor: filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("in-process")
              )
                ? "#FBEC5D" // Dark background color when selected
                : "",
              color: filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("in-process")
              )
                ? "#FFF" // Light text color when selected
                : "",
            }}
          >
            <span> In-Process</span>
            <span>
              <Badge bg="warning" style={{ borderRadius: "50%" }}>
                {countInProcess}
              </Badge>
            </span>
          </Col>

          <Col
            lg={12}
            onClick={() => handleButtonClickStatus("admission-taken")}
            className={`border border-2 p-2 d-flex justify-content-between  ${
              filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("admission-taken")
              )
                ? "border-success"
                : "border-success"
            }`}
            style={{
              borderRadius: "4px",
              cursor: "pointer",

              backgroundColor: filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("admission-taken")
              )
                ? "#46CF85" // Dark background color when selected
                : "",
              color: filter.some(
                (f) =>
                  f.field === "admissionStatus" &&
                  f.selected.includes("admission-taken")
              )
                ? "#FFF" // Light text color when selected
                : "",
            }}
          >
            <span>Admission-taken</span>
            <span>
              <Badge bg="success" style={{ borderRadius: "50%" }}>
                {countAdmissionTaken}
              </Badge>
            </span>
          </Col>
        </Row>

        {/* Filter Seen status*/}

        {isUnseen > 0 && (
          <>
            <Row className="team-member-1 text-start my-2">
              <Col>Filter By Reassign Status :</Col>
            </Row>

            <Row className="d-flex row-cols-3 ">
              <Col className=" my-1">
                <Badge
                  className="filter-program"
                  bg=""
                  onClick={() => handleButtonClickReassign(true)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: filter.some(
                      (f) =>
                        f.field === "isReassigned" && f.selected.includes(true)
                    )
                      ? "#F27B1D"
                      : "#F8D99E",

                    color: filter.some(
                      (f) =>
                        f.field === "isReassigned" && f.selected.includes(true)
                    )
                      ? "#FFFFFF"
                      : "red",
                  }}
                >
                  Unseen ({isUnseen})
                </Badge>
              </Col>

              {filter.some(
                (f) => f.field === "isReassigned" && f.selected.includes(true)
              ) && (
                <Col className=" my-1 ">
                  <Badge
                    className="filter-program"
                    bg=""
                    onClick={() => handleButtonClickReassign(false)}
                    style={{
                      cursor: "pointer",
                      borderRadius: "50%",

                      backgroundColor: filter.some(
                        (f) =>
                          f.field === "isReassigned" &&
                          f.selected.includes(false)
                      )
                        ? ""
                        : "lightcoral",

                      color: filter.some(
                        (f) =>
                          f.field === "isReassigned" &&
                          f.selected.includes(false)
                      )
                        ? "#FFFFFF"
                        : "#663399",
                    }}
                  >
                    X
                  </Badge>
                </Col>
              )}
            </Row>
          </>
        )}

        {/* Filter By Program */}

        <Row className="team-member-1 text-start my-2">
          <Col>Filter By Program :</Col>
        </Row>
        <Row className="d-flex row-cols-3 ">
          <Col className=" my-1">
            <Badge
              className="filter-program"
              bg=""
              onClick={() => handleButtonClickProgram("bba")}
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("bba")
                )
                  ? "#663399"
                  : "#F2F2F2",

                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("bba")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              BBA
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              className="filter-program"
              onClick={() => handleButtonClickProgram("bca")}
              bg=""
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("bca")
                )
                  ? "#663399"
                  : "#F2F2F2",

                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("bca")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              BCA
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              className="filter-program"
              onClick={() => handleButtonClickProgram("ba.llb")}
              bg=""
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("ba.llb")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("ba.llb")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              BA.LLB
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("llb")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("llb")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("llb")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              LLB
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("mba")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("mba")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("mba")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              MBA
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("b.com(h)")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "program" && f.selected.includes("b.com(h)")
                )
                  ? "#663399"
                  : "#F2F2F2",

                color: filter.some(
                  (f) =>
                    f.field === "program" && f.selected.includes("b.com(h)")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              B.COM(H)
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("mca")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("mca")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("mca")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              MCA
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("b.ed")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("b.ed")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("b.ed")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              B.ED
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("b.com.llb")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "program" && f.selected.includes("b.com.llb")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) =>
                    f.field === "program" && f.selected.includes("b.com.llb")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              B.COM.LLB
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("D.El.Ed")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("D.El.Ed")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("D.El.Ed")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              D.El.Ed
            </Badge>
          </Col>

          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgram("llm")}
              bg=""
              className="filter-program"
              style={{
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "program" && f.selected.includes("llm")
                )
                  ? "#663399"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "program" && f.selected.includes("llm")
                )
                  ? "#FFFFFF"
                  : "#663399",
              }}
            >
              LLM
            </Badge>
          </Col>
        </Row>

        {/* <Row className="team-member-1 text-start my-2">
          <Col>Filter duplicates :</Col>
        </Row> */}

        {/* <Row className="my-2 row-cols-2">
          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-duplicates ${
                  filter.some(
                    (f) => f.field === "repeat" && f.selected.includes("true")
                  )
                    ? ""
                    : ""
                } `}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  // textAlign:"center",
                  width: "100%",
                  color: filter.some(
                    (f) => f.field === "repeat" && f.selected.includes(true)
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) => f.field === "repeat" && f.selected.includes(true)
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleshowDuplicates(true)}
              >
                Show duplicates
              </button>
            </div>
          </Col>
        </Row> */}

        {/* Filter By origin */}
        <Row className="team-member-1 text-start my-2">
          <Col>Filter By Origin :</Col>
        </Row>

        <Row className="my-2 row-cols-2">
          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("website-pop-up")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  // textAlign:"center",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("website-pop-up")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("website-pop-up")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("website-pop-up")}
              >
                Website Pop-Up
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("telephonic")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",

                  border: "1px solid #663399",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("telephonic")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("telephonic")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("telephonic")}
              >
                Telephonic
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("college-dekho")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("college-dekho")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("college-dekho")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("college-dekho")}
              >
                College Dekho
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("existing-student")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",

                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("existing-student")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("existing-student")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("existing-student")}
              >
                Existing-Student
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("get-admission-walk-in")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",

                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("get-admission-walk-in")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("get-admission-walk-in")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("get-admission-walk-in")}
              >
                Get Admission Walk-In
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("walk-in")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",

                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("walk-in")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("walk-in")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("walk-in")}
              >
                Walk-In
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("college-dekho-walk-in")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",

                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("college-dekho-walk-in")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("college-dekho-walk-in")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("college-dekho-walk-in")}
              >
                College Dekho Walk-In
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("online-query")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("online-query")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("online-query")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("online-query")}
              >
                Online Query
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("walk-in")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("other-consultant-walk-in")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("other-consultant-walk-in")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("other-consultant-walk-in")}
              >
                Other Consultant Walk-In
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="py-2">
              <button
                className={`filter-origin ${
                  filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("ad-page")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  border: "1px solid #663399",
                  borderRadius: "4px",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  textAlign: "center",
                  color: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("ad-page")
                  )
                    ? "white"
                    : "#663399",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "form_location" &&
                      f.selected.includes("ad-page")
                  )
                    ? "#663399"
                    : "white",
                }}
                onClick={() => handleButtonClick("ad-page")}
              >
                Ad-Page
              </button>
            </div>
          </Col>
        </Row>

        <Row className=" "></Row>

        <Row className="team-member-1 text-start mt-2 mb-2">
          <Col>Filter By Date :</Col>
        </Row>

        <RangeDatePicker
          filter={filter}
          setFilter={setFilter}
          clearDate={clearDate}
          setClearDate={setClearDate}
        />
        <Row className="mt-3 ">
          <Col className="d-flex mx-4 justify-content-center">
            <button
              className=" me-2 px-3 py-2"
              style={{
                backgroundColor: "#663399",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "8px",
              }}
              onClick={handleClearFilter}
            >
              Reset Filters{" "}
            </button>
            <button
              type="primary"
              className=" px-3 py-2"
              onClick={handleFilterExport}
              style={{
                backgroundColor: "#663399",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "8px",
              }}
              icon={<DownloadOutlined />}
            >
              Export
            </button>
          </Col>
        </Row>
      </Container>
      {showHandler && (
        <HandlerModal
          filter={filter}
          handlers={handlers}
          handleButtonAdmission={handleButtonAdmission}
          handleButtonHandler={handleButtonHandler}
        />
      )}
    </>
  );
}

const HandlerModal = ({
  handlers,
  handleButtonAdmission,
  handleButtonHandler,
  filter,
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Handler</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        {handlers &&
          handlers.map((user) => (
            <div
              onClick={
                user.role === "admission"
                  ? () =>
                      handleButtonAdmission(
                        `${user?.firstname} ${user?.lastname}`
                      )
                  : () =>
                      handleButtonHandler(
                        `${user?.firstname} ${user?.lastname}`
                      )
              }
              key={user._id}
              style={{
                fontSize: "15px",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "assignedTo" &&
                    f.selected.includes(`${user?.firstname} ${user?.lastname}`)
                )
                  ? "#663399"
                  : "red",
              }}
            >
              <Image
                src="https://img.freepik.com/premium-vector/user-customer-avatar-vector-illustration_276184-160.jpg"
                fluid={true}
                style={{ width: "35px", height: "35px" }}
              />
              <span>
                {" "}
                {user.firstname} {user.lastname}{" "}
              </span>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
