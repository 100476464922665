import React, { useEffect, useState } from "react";

const StudentProfile = ({ data }) => {
  const [key, setKey] = useState("");


  useEffect(() => {
     console.log(data);
    // console.log(key);
  }, [key]);

 

  return (
    <>
      {data && data._id && (
        <div
          className="spWrapper  "
          style={{
            height: "400px",
            boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
          }}
        >
          <div className="spContent">
            <div className="spContent01">
              {data.name}
            </div>

            <div className="spContent02">
              {data.name}
            </div>
            <div className="spContent03">+91{data.mobile_number}</div>
          </div>
          
        </div>
      )}
    </>
  );
};
export default StudentProfile;
