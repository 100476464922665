import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";
import { MdOutlineFileDownload } from "react-icons/md";
import { format } from "date-fns";
import { serverAddress, originsRow, programsColumn } from "../constant";
import "./LeadCountByOrigin.css";

function LeadCountByOrigin() {
  const [leadData, setLeadData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${serverAddress}/website/enquiry/getLeadCountByOrigin`,
        {
          startDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
          endDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
          session: "2024-2025",
        }
      );
      setLeadData(response.data.responses ?? []);
    } catch (error) {
      console.error("Error fetching data", error);
      setError("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  const normalizeString = (str) => str.replace(/[-\s.]/g, "").toLowerCase();

  const getCountForProgramAndOrigin = (program, origin) => {
    const normalizedProgram = normalizeString(program);
    const normalizedOrigin = normalizeString(origin);

    const programData = leadData.find(
      (data) => normalizeString(data.program) === normalizedProgram
    );

    if (programData) {
      const originData = programData.origins.find(
        (data) => normalizeString(data.origin) === normalizedOrigin
      );
      return originData?.count ?? 0;
    }
    return 0;
  };

  const handleExport = async () => {
    if (isSubmitDisabled) return;

    setIsExporting(true);
    try {
      const response = await axios.post(
        `${serverAddress}/website/enquiry/exportLeadCountByOrigin`,
        {
          startDate: startDate?.toISOString().split("T")[0] ?? "",
          endDate: endDate?.toISOString().split("T")[0] ?? "",
          session: "2024-2025",
        },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LeadCounts.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data", error);
    } finally {
      setIsExporting(false);
    }
  };

  const isSubmitDisabled =
    !startDate || !endDate || isLoading || startDate > endDate;

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          gap: "1rem",
        }}
        className="container_top"
      >
        <div className="container_title">Lead Count By Origin Table</div>
        <div className="date-picker-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <label htmlFor="startDate">From Date</label>
            <DatePicker
              name="startDate"
              id="startDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              maxDate={endDate}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <label htmlFor="endDate">To Date</label>
            <DatePicker
              name="endDate"
              id="endDate"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
            />
          </div>

          <button
            onClick={fetchData}
            className="submit-button"
            disabled={isSubmitDisabled}
          >
            {isLoading ? (
              <>
                <ClipLoader size={20} color="#fff" />
                <span className="button-text">Fetching...</span>
              </>
            ) : (
              "Submit"
            )}
          </button>
          <button
            onClick={handleExport}
            className="export-button"
            disabled={isSubmitDisabled || isExporting}
          >
            {isExporting ? (
              <>
                <ClipLoader size={20} color="#fff" />
                <span className="button-text">Exporting...</span>
              </>
            ) : (
              <>
                <MdOutlineFileDownload />
                Export
              </>
            )}
          </button>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      <table className="lead-table">
        <thead>
          <tr>
            <th>No.</th>
            {programsColumn.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {originsRow.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row}</td>
              {programsColumn.map((column, colIndex) => (
                <td key={colIndex}>
                  {getCountForProgramAndOrigin(column, row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeadCountByOrigin;
