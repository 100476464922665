import { data } from "autoprefixer";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import LawAPI from "../../utils/api/LawAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import cookie from "js-cookie";

import moment from "moment";
import { notify_error, notify_success } from "../Notify";
import { verify } from "crypto";
import UserAPI from "../../utils/api/UserAPI";
//import FilterLawConference from "./FilterLawConference";
import { LawContext } from "../../state/context/LawProvider";
import { serverAddress } from "../../state/constants/content_types";
import GradingIcon from "@mui/icons-material/Grading";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

const MICTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const fullName = "${item.first_name} ${item.last_name}";
  const [search, SetSearch] = useState("");
  const filterPanelRef = useRef(null);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  useEffect(() => {
    top_loader(true, 45);
    getAll({ page: activepage, limit: recperpage });
  }, []);

  useEffect(()=>{
    top_loader(true, 45);
    SetActivePage(1);
    getAll({ page: activepage, limit: recperpage });
  },[recperpage]);

   useEffect(()=>{
        top_loader(true, 45);
        getAll({ page: activepage, limit: recperpage });
      },[activepage]);

  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleOnChange = (e) => {
    setTransactionId(e.target.value);
  };

  const handlePaymentLink = (_id) => {
    LawAPI.sendLinkLaw(_id)
      .then((result) => {
        if (result) {
          window.alert("email sent Successfully!");
        }
      })
      .catch((err) => {
        notify_error(err?.message || err);
      });
  };

  const handlePaymentStatus = async (_id) => {
    try {
      const response = await fetch(`${serverAddress}/mic-2025/mark-payment/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        notify_success("Payment status updated successfully");
        setCandidateId(_id); // Optional: If needed for further processing
        setOpen(false); // Close any modals or popups if applicable
        getAll({ page: activepage, limit: recperpage }); // Refresh the data list
      } else {
        const errorData = await response.json();
        notify_error(errorData.message || "Error: Couldn't update payment status");
      }
    } catch (error) {
      notify_error(error.message || "Something went wrong");
    }
  };

  const handleApprove = async (id) => {
    try {
      const response = await fetch(`${serverAddress}/mic-2025/accept-abstract/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        notify_success("Successfully approved");
        top_loader(true, 45); // Optional loader trigger
        getAll({ page: activepage, limit: recperpage }); // Refresh data
      } else {
        const errorData = await response.json();
        notify_error(errorData.message || "Error: Couldn't update approval status");
      }
    } catch (error) {
      notify_error(error.message || "Something went wrong");
    }
  };
  
  const handleReject = async (id) => {
    try {
      const response = await fetch(`${serverAddress}/mic-2025/reject-abstract/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        notify_success("Successfully rejected the abstract");
        top_loader(true, 45); // Optional loader trigger
        getAll({ page: activepage, limit: recperpage }); // Refresh data
      } else {
        const errorData = await response.json();
        notify_error(errorData.message || "Error: Couldn't update approval status");
      }
    } catch (error) {
      notify_error(error.message || "Something went wrong");
    }
  };

  const getAll = async (params) => {
    setLoading(true);
    try {
      // Construct the query string from params (e.g., { page: 1, limit: 10 } becomes "?page=1&limit=10")
      const queryString = new URLSearchParams(params).toString();

      // Make the API call
      const response = await fetch(
        `${serverAddress}/mic-2025/get-all?${queryString}`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch alumni: ${response.statusText}`);
      }

      // Parse the response data
      const datas = await response.json();
      const data= datas.students;
      console.log(data);

      // Update the data state with the fetched data
      setDate(data);
      setTotalData(datas.totaldata)
  
    } catch (error) {
      console.error("Error fetching alumni:", error);
    } finally {
      setLoading(false);
      top_loader(true, 100);
      top_loader(false, 0); // Set loading to false after the API call completes
    }
    /*  LawAPI.getAllLaw(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });*/
  };

  

  let timer;

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      if (value.trim() === "") {
        getAll(); // You can define fetchData to handle empty search case
      } else {
        fetchDataWithSearch(value.trim());
      }
    }, 300);
  };

  const fetchDataWithSearch = async (searchTerm) => {
    try {
      setLoading(true); // Start the loading indicator
  
      // Construct the URL for the API request
      const url = `${serverAddress}/mic-2025/search?page=${activepage}&limit=${recperpage}`;
  
      // Make the fetch call
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${cookie.get("ajwt")}`, // Add the JWT token
        },
        body: JSON.stringify({ search: searchTerm }), // Pass the search term in the body
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      // Parse the JSON response
      const data = await response.json();
  
      // Update state with the fetched data
      setDate(data.data); // Set the data array
      setTotalData(data.total); // Set the total number of records
      SetActivePage(1); // Reset to the first page after search (optional)
  
      setLoading(false); // Stop the loading indicator
    } catch (error) {
      console.error("Error in fetchDataWithSearch:", error); // Log the error for debugging
      setLoading(false); // Ensure loading indicator is stopped
    }
  };
  

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleRefresh = () => {
    // Clear search
    SetSearch("");
    getAll({ page: activepage, limit: recperpage });
  };

  function handlePageChange(page) {
    SetActivePage(page);
    getAll({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAll({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  return (
    <>
      <Container
        fluid
        className="p-0 custom-sidebar"
        style={{
          backgroundColor: "#F2F5F7",
          fontFamily: "Inter",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  {" "}
                  {/* Set width to 20% */}
                  <InputGroup
                    className="my-2"
                    style={{
                      borderRadius: "8px", // Rounded corners
                      border: "1px solid #D1D5DB", // Light border
                      backgroundColor: "#F8F9FB", // Light background
                      height: "43px", // Fixed height
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        height: "100%", // Ensures the icon container takes the full height
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                    </div>

                    <Form.Control
                      placeholder="Search In registrations by Name, Email and Mobile Number"
                      aria-label="Search"
                      name="search"
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none", // No inner border
                        fontFamily: "Inter",
                        paddingLeft: "0px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        fontSize: "16px", // Adjust font size
                        color: "#6B7280", // Placeholder color
                        height: "100%", // Ensures the input field takes the full height
                        fontSize: "12px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "450px", width: "auto" }}
              >
                <table className="col-md-12 enquiryTable">
                  <thead className="bg-transparent ">
                    <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th style={{ width: "10%" }}>Payment Status</th>
                      <th style={{ width: "15%" }}>Action</th>

                      {/* <th style={{ width: "15%" }}>Nationality</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            <tr
                              className="tableRows "
                              key={item._id}
                              onClick={() =>
                                navigate(`/dashboard/micprofile/${item._id}`)
                              }
                            >
                              <td>{sno + index + 1}</td>
                              <td>
                                {item.firstName} {item.lastName}
                              </td>
                              <td>{item.email}</td>

                              <td>{item.mobile}</td>

                              {item.paymentStatus === false && (
                                <td className="">
                                  <span
                                    className=""
                                    style={{
                                      border: "1px solid #FFE3E0",
                                      backgroundColor: "#FFE3E0",
                                      color: "#F7685B",
                                      paddingTop: "3px",
                                      paddingBottom: "3px",
                                      paddingLeft: "13px",
                                      paddingRight: "13px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Not Paid
                                  </span>
                                </td>
                              )}
                              {item.paymentStatus === true && (
                                <td className="">
                                  <span
                                    className=""
                                    style={{
                                      border: "1px solid #CAEEDB",
                                      backgroundColor: "#CAEEDB",
                                      color: "#46CF85",
                                      paddingTop: "3px",
                                      paddingBottom: "3px",
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Payment Done
                                  </span>
                                </td>
                              )}

                              <td className=" ">
                                {!item.isPaymentDone && (
                                  <Tooltip
                                    placement="top"
                                    title="Confirm Payment"
                                  >
                                    <span
                                      className="mx-2 px-1 "
                                      style={{
                                        paddingBlock: "10px",
                                        backgroundColor: "#2B73FF",
                                        border: "none",
                                        color: "white",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                        lineHeight: "35px",
                                        borderRadius: "4px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handlePaymentStatus(
                                          item._id,
                                          
                                        );
                                      }}
                                    >
                                      <CreditScoreIcon />
                                    </span>
                                  </Tooltip>
                                )}
                                {item.abstractStatus == null && item.category !='Attendees Only'  && (
                                      <Tooltip
                                        placement="top"
                                        title="Approve Abstract"
                                      >
                                        <span
                                          className="mx-2 px-1 "
                                          style={{
                                            paddingBlock: "10px",
                                            backgroundColor: "lightseagreen",
                                            border: "none",
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "35px",
                                            borderRadius: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleApprove(item._id);
                                          }}
                                        >
                                          <GradingIcon />
                                        </span>
                                      </Tooltip>
                                    )}
                                {item.abstractStatus == null && item.category !='Attendees Only'  && (
                                      <Tooltip
                                        placement="top"
                                        title="Reject Abstract"
                                      >
                                        <span
                                          className="mx-2 px-1 "
                                          style={{
                                            paddingBlock: "10px",
                                            backgroundColor: "lightcoral",
                                            border: "none",
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "35px",
                                            borderRadius: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleReject(item._id);
                                          }}
                                        >
                                          <PlaylistRemoveIcon />
                                        </span>
                                      </Tooltip>
                                    )}

                                {item.abstractStatus == 1 && (
                                  <>
                                    <span className="text-success">
                                      Approved
                                    </span>
                                  </>
                                )}
                                {item.abstractStatus == 2 && (
                                  <>
                                    <span className="text-danger">
                                      Rejected
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {data.length == "0" && (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <h1 className="text-muted">NO DATA</h1>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="tableEnd "></div>

              <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    // onChange={(event) => applyFilter(event)}
                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div
                  className="col-md-8 d-flex gap-2"
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                    paddingRight: "60px",
                  }}
                >
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    current={activepage}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      ></div>
      {/* {open && <CreateEnquiry open={open} setOpen={setOpen} />} */}
    </>
  );
};

export default MICTable;
