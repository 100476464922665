import React from "react";
import { Link } from "react-router-dom";

const PageHeader = (props) => {
  return (
    <div className="page-header ">
    <div className="mx-auto border-bottom border-3" style={{ textDecoration: "none",}} to="">
    <h3 className="page-title" style={{fontFamily:"ubuntu-bold"}}>
          {/* <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home" />
          </span> */}
          {props.title}{" "}
          {/* Dashboard */}
        </h3>
      </div>


      {/* <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <span />
            {props.title}{" "}
            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle" />
          </li>
        </ul>
      </nav> */}

      
    </div>
  );
};

export default PageHeader;
