import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from "js-cookie";
const EnquiryAPI = {
  createEnquiry: (params) => {
    // let url = serverAddress + `/api/enquiry/create`;
    let url = serverAddress + `/enquiry/create`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  updateStatus: (params) => {
    // let url = serverAddress + `/api/enquiry/update/enquiry/status`;
    let url = serverAddress + `/enquiry/update/enquiry/status`;

    return axios.put(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  updateAssignment: (params) => {
    // let url = serverAddress + `/api/enquiry/update/enquiry/assign`;
    let url = serverAddress + `/enquiry/update/enquiry/assign`;
    return axios.put(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  getOneEnquiry: (params) => {
    // let url=serverAddress+`/api/website/enquiry/get/${params}`
    let url = serverAddress + `/website/enquiry/get/${params}`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countLastMonthWalkInEnquiry: () => {
    // let url = serverAddress + `/api/website/enquiry/count/month?type=walk-in`
    let url = serverAddress + `/website/enquiry/count/month?type=walk-in`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countLastMonthAdEnquiry: () => {
    // let url = serverAddress + `/api/website/enquiry/count/month?type=ad`;
    let url = serverAddress + `/website/enquiry/count/month?type=ad`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countLastMonthOrganicEnquiry: () => {
    // let url = serverAddress + `/api/website/enquiry/count/month?type=organic`;
    let url = serverAddress + `/website/enquiry/count/month?type=organic`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countTotatlEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url = serverAddress + `/enquiry/get/total/enquiry`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countLastYearWalkInEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url = serverAddress + `/website/enquiry/count/last/year?type=walk-in`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countLastYearAdPageEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url = serverAddress + `/website/enquiry/count/last/year?type=ad`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countLastYearOrganicEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url = serverAddress + `/website/enquiry/count/last/year?type=organic`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countCurrentYearWalkInEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url =
      serverAddress + `/website/enquiry/count/current/year?type=walk-in`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countCurrentYearAdPageEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url = serverAddress + `/website/enquiry/count/current/year?type=ad`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  countCurrentYearOrganicEnquiry: () => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url =
      serverAddress + `/website/enquiry/count/current/year?type=organic`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //update Admission Status
  updateAdmissionStatus: (params) => {
    let url = serverAddress + `/website/enquiry/update/lead/admission/status`;
    return axios.put(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
      // withCredentials: true,  // This ensures cookies (if any) are sent
    });
  },

  //update Remarks

  updateAdmissionRemarks: (params) => {
    // console.log(params);
    // let url=serverAddress+`/api/website/enquiry/update/admission/remarksCreate`;
    let url = serverAddress + `/website/enquiry/update/admission/remarksCreate`;

    return axios.post(url, params, {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    });
  },

  //get All Reamrks

  getAllRemarks: (params) => {
    // // console.log(params);
    // let url=serverAddress+`/api/website/enquiry/update/admission/remarksGetAll?_id=${params}`;
    let url =
      serverAddress +
      `/website/enquiry/update/admission/remarksGetAll?_id=${params}`;
    return axios.get(url, {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    });
  },

  //filterByAdmisssionStatus

  filterAdmissionStatus: (params) => {
    let url =
      serverAddress +
      `/api/website/enquiry/filter/admissionStatus?admissionStatus=${params.admissionStatus}&page=${params.page}&limit=${params?.limit}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //getStatus

  //filter program
  //under wrok

  filterProgramEnqyiry: (params) => {
    let url =
      serverAddress +
      `/enquiry/get/program/?page=${params.page}&limit=${params.limit}&program=${params.program}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  /* Forget Password */
};

export default EnquiryAPI;
