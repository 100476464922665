import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
// import ChartData from "./Chart";
import ProgramChart from "./ProgramChart";
import EnquiryChart from "./DoughnutChart";
import { Container, Row, Col } from "react-bootstrap";

const StretchCard = () => {
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [totalOnlineEnquiry, setTotalOnlineEnquiry] = useState(0);
  const [totalAdOnlineEnquiry, setTotalAdOnlineEnquiry] = useState(0);
  const [lastMonthWalkInEnquiry, setLastMonthWalkInEnquiry] = useState(0);
  const [currentMonthWalkInEnquiry, setCurrentMonthWalkInEnquiry] = useState(0);
  const [currentMonthAdEnquiry, setCurrentMonthAdEnquiry] = useState(0);
  const [currentMonthOrganicEnquiry, setCurrentMonthOrganicEnquiry] =
    useState(0);
  const [lastMonthAdEnquiry, setLastMonthAdEnquiry] = useState(0);
  const [lastMonthOrganicEnquiry, setLastMonthOrganicEnquiry] = useState(0);
  const [lastYearWalkInEnquiry, setLastYearWalkInEnquiry] = useState(0);
  const [currentYearWalkInEnquiry, setCurrentYearWalkInEnquiry] = useState(0);
  const [currentYearAdEnquiry, setCurrentYearAdEnquiry] = useState(0);
  const [currentYearOrganicEnquiry, setCurrentYearOrganicEnquiry] = useState(0);
  const [lastYearAdEnquiry, setLastYearAdEnquiry] = useState(0);
  const [lastYearOrganicEnquiry, setLastYearOrganicEnquiry] = useState(0);

  useEffect(() => {
    EnquiryAPI.countLastYearWalkInEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setLastYearWalkInEnquiry(result?.data?.count);
      } else {
        setLastYearWalkInEnquiry(0);
      }
    });

    EnquiryAPI.countLastYearAdPageEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setLastYearAdEnquiry(result?.data?.count);
      } else {
        setLastYearAdEnquiry(0);
      }
    });

    EnquiryAPI.countLastYearOrganicEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setLastYearOrganicEnquiry(result?.data?.count);
      } else {
        setLastYearOrganicEnquiry(0);
      }
    });

    EnquiryAPI.countCurrentYearWalkInEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setCurrentYearWalkInEnquiry(result?.data?.count);
      } else {
        setCurrentYearWalkInEnquiry(0);
      }
    });

    EnquiryAPI.countCurrentYearAdPageEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setCurrentYearAdEnquiry(result?.data?.count);
      } else {
        setCurrentYearAdEnquiry(0);
      }
    });

    EnquiryAPI.countCurrentYearOrganicEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setCurrentYearOrganicEnquiry(result?.data?.count);
      } else {
        setCurrentYearOrganicEnquiry(0);
      }
    });

    EnquiryAPI.countLastMonthWalkInEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setLastMonthWalkInEnquiry(result?.data?.data?.lastMonth);
      } else {
        setLastMonthWalkInEnquiry(0);
      }
    });

    EnquiryAPI.countLastMonthAdEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setLastMonthAdEnquiry(result?.data?.data?.lastMonth);
      } else {
        setLastMonthAdEnquiry(0);
      }
    });

    EnquiryAPI.countLastMonthOrganicEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setLastMonthOrganicEnquiry(result?.data?.data?.lastMonth);
      } else {
        setLastMonthOrganicEnquiry(0);
      }
    });

    EnquiryAPI.countLastMonthWalkInEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setCurrentMonthWalkInEnquiry(result?.data?.data?.currentMonth);
      } else {
        setCurrentMonthWalkInEnquiry(0);
      }
    });

    EnquiryAPI.countLastMonthAdEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setCurrentMonthAdEnquiry(result?.data?.data?.currentMonth);
      } else {
        setCurrentMonthAdEnquiry(0);
      }
    });

    EnquiryAPI.countLastMonthOrganicEnquiry().then((result) => {
      // console.log(result);
      if (result?.data?.status) {
        setCurrentMonthOrganicEnquiry(result?.data?.data?.currentMonth);
      } else {
        setCurrentMonthOrganicEnquiry(0);
      }
    });

    EnquiryAPI.countTotatlEnquiry().then((result) => {
      // // console.log(result);
      if (result?.data?.status) {
        setTotalEnquiry(result?.data?.data);
      } else {
        setTotalEnquiry(0);
      }
    });

    OnlineEnquiryAPI.countTotatlEnquiry().then((result) => {
      if (result?.data?.status) {
        setTotalOnlineEnquiry(result?.data?.data);
      } else {
        setTotalOnlineEnquiry(0);
      }
    });

    OnlineEnquiryAPI.countAdEnquiry().then((result) => {
      if (result?.data?.status) {
        setTotalAdOnlineEnquiry(result?.data?.data);
      } else {
        setTotalAdOnlineEnquiry(0);
      }
    });

    // eslint-disable-next-line
  }, []);

  // console.log("countLastYearAdPageEnquiry", currentYearOrganicEnquiry)

  // Calculation of Walk-in growth (MONTH)
  let walk_in_growth =
    ((currentMonthWalkInEnquiry - lastMonthWalkInEnquiry) /
      lastMonthWalkInEnquiry) *
    100;
  if (!isFinite(walk_in_growth) || isNaN(walk_in_growth)) {
    walk_in_growth = 100;
  }
  const formatted_walk_in_growth = walk_in_growth.toFixed(2);

  // Calculation of Walk-in growth (YEAR)
  let year_walk_in_growth =
    ((currentYearWalkInEnquiry - lastYearWalkInEnquiry) /
      lastYearWalkInEnquiry) *
    100;
  if (!isFinite(year_walk_in_growth) || isNaN(year_walk_in_growth)) {
    year_walk_in_growth = 100;
  }
  const formatted_year_walk_in_growth = year_walk_in_growth.toFixed(2);

  // Calculation of online/organic growth (MONTH)
  let online_growth =
    ((currentMonthOrganicEnquiry - lastMonthOrganicEnquiry) /
      lastMonthOrganicEnquiry) *
    100;
  if (!isFinite(online_growth) || isNaN(online_growth)) {
    online_growth = 100;
  }
  const formatted_online_growth = online_growth.toFixed(2);

  // Calculation of online/organic growth (YEAR)
  let year_online_growth =
    ((currentYearOrganicEnquiry - lastYearOrganicEnquiry) /
      lastYearOrganicEnquiry) *
    100;
  if (!isFinite(year_online_growth) || isNaN(year_online_growth)) {
    year_online_growth = 100;
  }
  const formatted_year_online_growth = year_online_growth.toFixed(2);

  // Calculation of Advertisement growth (MONTH)
  let ad_growth =
    ((currentMonthAdEnquiry - lastMonthAdEnquiry) / lastMonthAdEnquiry) * 100;
  if (!isFinite(ad_growth) || isNaN(ad_growth)) {
    ad_growth = 100;
  }
  const formatted_ad_growth = ad_growth.toFixed(2);

  // Calculation of Advertisement growth (YEAR)
  let year_ad_growth =
    ((currentYearAdEnquiry - lastYearAdEnquiry) / lastYearAdEnquiry) * 100;
  if (!isFinite(year_ad_growth) || isNaN(year_ad_growth)) {
    ad_growth = 100;
  }
  const formatted_year_ad_growth = year_ad_growth.toFixed(2);

  // Calculation of Total growth (MONTH)
  let total_growth =
    ((currentMonthAdEnquiry +
      currentMonthOrganicEnquiry +
      currentMonthWalkInEnquiry -
      (lastMonthAdEnquiry + lastMonthOrganicEnquiry + lastMonthWalkInEnquiry)) /
      (lastMonthAdEnquiry + lastMonthOrganicEnquiry + lastMonthWalkInEnquiry)) *
    100;
  if (!isFinite(total_growth) || isNaN(total_growth)) {
    total_growth = 100;
  }
  const formatted_total_growth = total_growth.toFixed(2);

  // Calculation of Total growth (YEAR)
  let year_total_growth =
    ((currentYearWalkInEnquiry +
      currentYearAdEnquiry +
      currentYearOrganicEnquiry -
      (lastYearAdEnquiry + lastYearOrganicEnquiry + lastYearWalkInEnquiry)) /
      (lastYearAdEnquiry + lastYearOrganicEnquiry + lastYearWalkInEnquiry)) *
    100;
  if (!isFinite(year_total_growth) || isNaN(year_total_growth)) {
    year_total_growth = 100;
  }
  const formatted_year_total_growth = year_total_growth.toFixed(2);

  return (
    <div className=" w-100 h-100  d-flex justify-content-center">
      <div className="row">
        <Container
          className="mt-0"
          style={{ backgroundColor: "#F2F5F7", height: "auto" }}
        >
          <Row>
            {/* Walk-In Enquiry Card */}
            <Col xl={3} lg={6} className="px-1 mt-1">
              <div
                className="d-flex flex-column px-3 py-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  minHeight: "120px", // Ensure minimal height for card
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-0 ">
                  <div style={{ width: "50px" }} className="me-3">
                    <img
                      src="/assets/images/dashboard/Import.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  {/* Wrap the heading and count inside a flex container */}
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6
                      className="mb-0 statHeading1"
                      style={{ fontSize: "1rem" }}
                    >
                      Walk-In Enquiry
                    </h6>
                    <span
                      // style={{
                      //   fontSize: "1.25rem",
                      //   fontWeight: "600",
                      //   marginLeft: "10px",
                      // }}
                      className="statHeading1"
                    >
                      {totalEnquiry}
                    </span>
                  </div>
                </div>
                <hr style={{ backgroundColor: "#E5E9EB", width: "100%" }} />
                <div>
                  <div className="d-flex justify-content-between ">
                    <span className="statHeading2">Month on Month</span>
                    <span className="statHeading3">
                      {formatted_walk_in_growth}%
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span className="statHeading2">Year on Year</span>
                    <span className="statHeading3">
                      {formatted_year_walk_in_growth}%
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            {/* Organic Enquiry Card */}
            <Col xl={3} lg={6} className="px-1 mt-1">
              <div
                className="d-flex flex-column px-3 py-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  minHeight: "120px",
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-0">
                  <div style={{ width: "50px" }} className="me-3">
                    <img
                      src="/assets/images/dashboard/Read Online.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  {/* Wrap the heading and count inside a flex container */}
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6 className="mb-0 statHeading1">Organic Enquiry</h6>
                    <span className="statHeading1">{totalAdOnlineEnquiry}</span>
                  </div>
                </div>
                <hr style={{ backgroundColor: "#E5E9EB", width: "100%" }} />
                <div>
                  <div className="d-flex justify-content-between">
                    <span className="statHeading2">Month on Month</span>
                    <span className="statHeading3">
                      {formatted_online_growth}%
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span className="statHeading2">Year on Year</span>
                    <span className="statHeading3">
                      {formatted_year_online_growth}%
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            {/* Ad Page Enquiry Card */}
            <Col xl={3} lg={6} className="px-1 mt-1">
              <div
                className="d-flex flex-column px-3 py-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  minHeight: "120px",
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-0">
                  <div style={{ width: "50px" }} className="me-3">
                    <img
                      src="/assets/images/dashboard/Commercial.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  {/* Wrap the heading and count inside a flex container */}
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6 className="mb-0 statHeading1">Ad Page Enquiry</h6>
                    <span className="statHeading1">{totalOnlineEnquiry}</span>
                  </div>
                </div>
                <hr style={{ backgroundColor: "#E5E9EB", width: "100%" }} />
                <div>
                  <div className="d-flex justify-content-between">
                    <span className="statHeading2">Month on Month</span>
                    <span className="statHeading3">{formatted_ad_growth}%</span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span className="statHeading2">Year on Year</span>
                    <span className="statHeading3">
                      {formatted_year_ad_growth}%
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            {/* Total Enquiry Card */}
            <Col xl={3} lg={6} className="px-1 mt-1">
              <div
                className="d-flex flex-column px-3 py-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  minHeight: "120px",
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-0">
                  <div style={{ width: "50px" }} className="me-3">
                    <img
                      src="/assets/images/dashboard/Archive List Of Parts.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  {/* Wrap the heading and count inside a flex container */}
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6 className="mb-0 statHeading1">Total Enquiry</h6>
                    <span className="statHeading1">
                      {" "}
                      {totalEnquiry + totalAdOnlineEnquiry + totalOnlineEnquiry}
                    </span>
                  </div>
                </div>
                <hr style={{ backgroundColor: "#E5E9EB", width: "100%" }} />
                <div>
                  <div className="d-flex justify-content-between">
                    <span className="statHeading2">Month on Month</span>
                    <span className="statHeading3">
                      {formatted_total_growth}%
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span className="statHeading2">Year on Year</span>
                    <span className="statHeading3">
                      {formatted_year_total_growth}%
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="bg-transparent mt-4">
          <Row className="">
            <Col className="p-0" lg={6}>
              {" "}
              <div style={{ paddingInline: "4px" }}>
                <ProgramChart />
              </div>
            </Col>
            <Col lg={6} className="p-0">
              {" "}
              <div style={{ paddingInline: "4px" }}>
                <EnquiryChart />
              </div>
              {/* <div className="col-md-6">
                  <div
                    className="d-flex border mb-2 justify-content-center align-items-center rounded  "
                    style={{ backgroundColor: "rgb(245,206,133)" }}
                  >
                    <h4 className="font-weight-normal m-2">Walk In :</h4>
                    <h4 className="font-weight-normal m-2">{totalEnquiry}</h4>
                  </div>

                  <div
                    className="d-flex mb-2 justify-content-center align-items-center rounded "
                    style={{ backgroundColor: " rgb(176,193,212)" }}
                  >
                    <h4 className="font-weight-normal m-2 ">Ad-Page :</h4>
                    <h4 className="font-weight-normal m-2">
                      {totalAdOnlineEnquiry}
                    </h4>
                  </div>

                  <div
                    className="d-flex mb-2 justify-content-center align-items-center rounded  "
                    style={{ backgroundColor: "  rgb(194,232,255)" }}
                  >
                    <h4 className="font-weight-normal m-2">Organic :</h4>
                    <h4 className="font-weight-normal m-2">
                      {totalOnlineEnquiry}
                    </h4>
                  </div>

                  <div
                    className="d-flex mb-2 justify-content-center align-items-center rounded  "
                    style={{ backgroundColor: " rgb(232,232,232)" }}
                  >
                    <h4 className="font-weight-normal m-2">Total :</h4>
                    <h4 className="font-weight-normal m-2">
                      {totalEnquiry + totalAdOnlineEnquiry + totalOnlineEnquiry}
                    </h4>
                  </div>
                </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default StretchCard;
