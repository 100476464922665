import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import { Container, Row, Col, Form } from "react-bootstrap";

export default function ProgramChart(props) {
  const [program, setProgram] = useState("bba");
  const [countInProcess, setCountInProcess] = useState(0);
  const [countAdmissionTaken, setCountAdmissionTaken] = useState(0);
  const [countNotInterested, setCountNotInterested] = useState(0);
  const [totalEnquiry, setTotalEnquiry] = useState(0);

  useEffect(() => {
    OnlineEnquiryAPI.dynamicFilter(
      [
        { field: "program", selected: [program] },
        { field: "admissionStatus", selected: ["in-process"] },
      ],
      1,
      25
    ).then((res) => {
      setCountInProcess(res.data.total);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [
        { field: "program", selected: [program] },
        { field: "admissionStatus", selected: ["not-interested"] },
      ],
      1,
      25
    ).then((res) => {
      setCountNotInterested(res.data.total);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [
        { field: "program", selected: [program] },
        { field: "admissionStatus", selected: ["admission-taken"] },
      ],
      1,
      25
    ).then((res) => {
      setCountAdmissionTaken(res.data.total);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [{ field: "program", selected: [program] }],
      1,
      25
    ).then((res) => {
      setTotalEnquiry(res.data.total);
    });
  }, [program]);

  const getOption = () => {
    return {
      legend: {
        borderRadius: 0,
        orient: "horizontal",
        x: "right",
        data: ["Program Wise Data"],
      },
      grid: {
        left: "8px",
        right: "8px",
        bottom: "0",
        containLabel: true,
      },
      tooltip: {
        show: true,
        backgroundColor: "rgba(0, 0, 0, .8)",
      },
      xAxis: {
        type: "category",
        data: [
          "All Enquiry",
          "Admission Taken",
          "In Process",
          "Not Interested",
        ],
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: true,
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          interval: "auto",
        },
      },
      series: [
        {
          name: "Program Wise Data",
          data: [
            totalEnquiry,
            countAdmissionTaken,
            countInProcess,
            countNotInterested,
          ],
          type: "bar",
          color: "#7569b3",
          barWidth: "25%",
        },
      ],
    };
  };

  return (
    <Container
      className="mt-1 pt-3"
      style={{
        marginTop: "200px",
        backgroundColor: "white",
        paddingBottom: "19px",
        boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
      }}
    >
      <Row>
        <Col>
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: "500",
              whiteSpace: "nowrap",
            }}
          >
            Admission Count
          </p>
        </Col>
        <Col>
          <Form.Select
            className="me-2"
            style={{ width: "200px", float: "right" }}
            name="program"
            onChange={(e) => setProgram(e.target.value)}
            value={program}
            aria-label="Default select example"
          >
            <option value="bba">BBA</option>
            <option value="bca">BCA</option>
            <option value="b.com(h)">B.COM</option>
            <option value="mba">MBA</option>
            <option value="b.ed">B.ED</option>
            <option value="mca">MCA</option>
            <option value="ba.llb">B.A.LLB</option>
            <option value="llb">LLB</option>
            <option value="b.com.llb">B.COM.LLB</option>
            <option value="llm">LLM</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: "100%" }}>
          <div className="card">
            <ReactEcharts style={{ height: "250px" }} option={getOption()} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
