import React, { useState } from "react";
import { Modal, DatePicker, Select } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import * as Yup from "yup";
import { notify_error, notify_success } from "./Notify";
import AlumniApi from "../utils/api/AlumniAPI";

const { Option } = Select;

const CreateAlumni = ({ open, setOpen, course }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Please enter a name"),
    email_address: Yup.string()
      .trim()
      .matches(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,"Please enter a valid email address")
      .required('Please enter email address'),
    mobile_number: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number") 
      // .matches(/^[6-9]\d{9}$/, "Please enter a valid 10-digit phone number")
      .required("Please enter a mobile number"),
    dob: Yup.string().trim().required("Please enter date of birth"),
    company: Yup.string().trim().required("Please enter a company"),
    designation: Yup.string().trim().required("Please enter a designation"),
    city: Yup.string().trim().required("Please enter a city"),
    state: Yup.string().trim().required("Please enter a state"),
    start_batch: Yup.string().trim().required("Please select a start batch"),
    end_batch: Yup.string().trim().required("Please select an end batch"),
  });

  const [newAlumni, setnewAlumni] = useState({
    name: "",
    email_address: "",
    mobile_number: "",
    profile_photo: "",
    program: course,
    dob: "",
    company: "",
    designation: "",
    city: "",
    state: "",
    start_batch: "",
    end_batch: "",
  });

  const handleDateChange = (date, dateString) => {
    // Ensure the date is a valid moment object
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setnewAlumni({ ...newAlumni, dob: formattedDate });
  };

  const handleEnquiryCancel = () => {
    setOpen(false);
  };

  const handleEnquiryOk = async () => {
    setConfirmLoading(true);
    try {
      await validationSchema.validate(newAlumni, { abortEarly: false });
      const { start_batch, end_batch, ...rest } = newAlumni;
      const myNewAlumni = {
        ...rest,
        batch: `${start_batch}-${end_batch.slice(2, 4)}`,
      };

      const result = await AlumniApi.createAlumni(myNewAlumni);
      if (result.data.status) {
        notify_success(result.data.message);
        setOpen(false);
        setConfirmLoading(false);
        resetEnquiryFormData();
        window.location.reload();
      } else {
        notify_error(result.data.message);
        setConfirmLoading(false);
      }
    } catch (err) {
      err.inner.forEach((error) => {
        notify_error(error.message);
      });
      setConfirmLoading(false);
    }
  };

  const resetEnquiryFormData = () => {
    setnewAlumni({
      name: "",
      email_address: "",
      mobile_number: "",
      profile_photo: "",
      program: "",
      dob: "",
      company: "",
      designation: "",
      city: "",
      state: "",
      start_batch: "",
      end_batch: "",
    });
  };

  const handleOnEnquiryChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target.value);
    if (name === "mobile_number") {
      if (/^\d*$/.test(value)) {
        setnewAlumni({ ...newAlumni, [name]: value });
      }
    }

    else {
      setnewAlumni({ ...newAlumni, [name]: value });
    }
  };

  const handleStartBatchChange = (value) => {
    setnewAlumni({ ...newAlumni, start_batch: String(value) });
  };

  const handleEndBatchChange = (value) => {
    setnewAlumni({ ...newAlumni, end_batch: String(value) });
  };

  return (
    <Modal
      visible={open}
      footer={null}
      onCancel={handleEnquiryCancel}
      style={{
        marginBlock: "auto",
        padding: "20px",
        borderRadius: "16px",
        fontFamily: "Inter",
        maxWidth: "800px",
        width: "100%",
      }}
    >
      <div className="row">
        <h2
          style={{
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "28px",
            fontWeight: "600",
            marginBottom: "20px",
          }}
        >
          New Alumni Form
        </h2>
      </div>

      <div className="row">
        <div className="col-md-6 mb-3 mx-auto">
          {/* Name Field */}
          <div className="form-group">
            <label
              htmlFor="name"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Enter name"
              onChange={handleOnEnquiryChange}
              value={newAlumni.name}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* Email Field */}
          <div className="form-group">
            <label
              htmlFor="email_address"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Email<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email_address"
              className="form-control"
              id="email_address"
              name="email_address"
              placeholder="Enter email"
              onChange={handleOnEnquiryChange}
              value={newAlumni.email_address}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* Mobile Field */}
          <div className="form-group">
            <label
              htmlFor="mobile_number"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Mobile<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="tel"
              className="form-control"
              id="mobile_number"
              name="mobile_number"
              placeholder="Mobile number"
              onChange={handleOnEnquiryChange}
              maxLength={10}
              value={newAlumni.mobile_number}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* Program Field */}
          <div className="form-group">
            <label
              htmlFor="program"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Program<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="program"
              name="program"
              placeholder="Enter program"
              onChange={handleOnEnquiryChange}
              value={newAlumni.program}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* Date of Birth Field */}
          <div className="form-group">
            <label
              htmlFor="dob"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Date of Birth<span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              className="form-control"
              id="dob"
              name="dob"
              value={newAlumni.dob ? moment(newAlumni.dob) : null}
              onChange={handleDateChange}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
                width: "100%",
              }}
            />
          </div>
        </div>

        <div className="col-md-6 mb-3 mx-auto">
          {/* Company Field */}
          <div className="form-group">
            <label
              htmlFor="company"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Company<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              placeholder="Enter company"
              onChange={handleOnEnquiryChange}
              value={newAlumni.company}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* Designation Field */}
          <div className="form-group">
            <label
              htmlFor="designation"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Designation<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="designation"
              name="designation"
              placeholder="Enter designation"
              onChange={handleOnEnquiryChange}
              value={newAlumni.designation}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* City Field */}
          <div className="form-group">
            <label
              htmlFor="city"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              City<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              placeholder="Enter city"
              onChange={handleOnEnquiryChange}
              value={newAlumni.city}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* State Field */}
          <div className="form-group">
            <label
              htmlFor="state"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              State<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="state"
              name="state"
              placeholder="Enter state"
              onChange={handleOnEnquiryChange}
              value={newAlumni.state}
              style={{
                height: "43px",
                borderRadius: "8px",
                border: "1px solid #CED4DA",
              }}
            />
          </div>

          {/* Start Batch Field */}
          <div className="form-group">
            <label
              htmlFor="start_batch"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              Start Batch<span style={{ color: "red" }}>*</span>
            </label>
            <Select
              className="form-control custom-select"
              id="start_batch"
              name="start_batch"
              placeholder="Select year"
              onChange={handleStartBatchChange}
              value={newAlumni.start_batch}
              // style={{
              //   height: "34px",
              //   borderRadius: "8px",
              //   border: "1px solid #CED4DA",
              //   width: "100%",
              //   boxSizing: "border-box", // Ensure padding and border are included in width
              // }}
            >
              {Array.from({ length: 50 }, (_, index) => (
                <Option key={index} value={moment().year() - index}>
                  {moment().year() - index}
                </Option>
              ))}
            </Select>
          </div>

          {/* End Batch Field */}
          <div className="form-group">
            <label
              htmlFor="end_batch"
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              End Batch<span style={{ color: "red" }}>*</span>
            </label>
            <Select
              className="form-control custom-select"
              id="end_batch"
              name="end_batch"
              placeholder="Select year"
              onChange={handleEndBatchChange}
              value={newAlumni.end_batch}
              // style={{
              //   height: "34px",
              //   borderRadius: "8px",
              //   border: "1px solid #CED4DA",
              //   width: "100%",
              //   boxSizing: "border-box", // Ensure padding and border are included in width
              // }}
            >
              {Array.from({ length: 50 }, (_, index) => (
                <Option key={index} value={moment().year() - index}>
                  {moment().year() - index}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="d-flex gap-3 align-items-center justify-content-center">
          <button
            onClick={handleEnquiryOk}
            className="spButton1"
            style={{
              // backgroundColor: "#663399",
              // color: "white",
              // border: "2px solid #663399",
              // borderRadius: "12px",
              fontSize: "15px",
              fontWeight: "700",
              padding: "8px 35px",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Submit
          </button>
          <button
            onClick={handleEnquiryCancel}
            className="spButton2"
            style={{
              // backgroundColor: "white",
              // color: "black",
              // border: "2px solid #C3BDBD",
              // borderRadius: "12px",
              fontSize: "15px",
              padding: "8px 35px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAlumni;
