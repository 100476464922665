// Main Server CMS Dashboard
// export const serverAddress  = "https://api.assertit.io/ipem";
 export const serverAddress = "https://server.assertit.io/ipem";

// Dev Server CMS Dashboard
//export const serverAddress = "https://api-dev.assertit.io/ipem";

// Local Server
// export const serverAddress = "http://localhost:4001/ipem";

// export const serverAddress=process.env.REACT_APP_BASE_URL

export const originsRow = [
  "WebsitePopUp",
  "CollegeDunia",
  "OnlineQuery",
  "WalkIn",
  "AdPage",
  "Telephonic",
  "CollegeDekho",
  "GetAdmissionWalkIn",
  "CollegeDekhoWalkIn",
  "OtherConsultantWalkIn",
  "ExistingStudent",
];

export const programsColumn = [
  "BBA",
  "BCA",
  "BA.LLB",
  "LLB",
  "MBA",
  "B.COM(H)",
  "MCA",
  "B.ED",
  "B.COM.LLB",
  "D.El.Ed",
];

export const feedbackOptions = [
  "Student Feedback",
  "Faculty Feedback",
  "Employer Feedback",
  "Alumni Feedback",
  "Parent Feedback",
  "Student Satisfaction",
];
