import { data } from "autoprefixer";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import LawAPI from "../../utils/api/LawAPI";
import { format } from "date-fns";
import { Badge, Tooltip, Modal, Pagination } from "antd";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
// import { EnquiryContext } from "../../state/context/EnquiryProvider";
// import {  Pagination} from "antd";
// import {
//   PlusCircleOutlined,
//   RetweetOutlined,
//   MailOutlined,
// } from "@ant-design/icons";​
import moment from "moment";
import { serverAddress } from "../../constant";
import { notify_error, notify_success } from "../Notify";
import { verify } from "crypto";
import UserAPI from "../../utils/api/UserAPI";
import { useNavigate } from "react-router-dom";
import { CareerContext } from "../../state/context/CareerProvider";
import CareerAPI from "../../utils/api/CareersAPI";
import FilterCareerTable from "./FilterCareerTable";

const NewCareerTable = () => {
  const navigate = useNavigate();
  const {
    filter,
    setFilter,
    // applyFilter,
    recperpage,
    handlePageChange,
    search,
    SetSearch,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(CareerContext);
  const dispatch = useDispatch();
  const filterPanelRef = useRef(null);
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const sno = recperpage * (activepage - 1);
  let timer;
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  useEffect(() => {
    top_loader(true, 45);
    SetActivePage(1);
    getAllCareer({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  const handleRefresh = () => {
    // top_loader(true, 45);
    SetActivePage(1);
    getAllCareer({ page: activepage, limit: recperpage });
  };

  useEffect(() => {
    SetRecPerPage(25);
  }, []);

  const getAllCareer = (params) => {
    setLoading(true);
    CareerAPI.getAllCareer(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handleSearch = (searchterm) => {

  
      CareerAPI.searchQuery({ search: searchterm }, activepage, recperpage).then(
        (res) => {
          setDate(res.data.data);
          setTotalData(res.data.total);
          SetActivePage(1);
          // console.log(res.data)
          return;
        }
      );

   
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
   SetSearch(value);

   clearTimeout(timer);

   timer = setTimeout(() => {
     if (value.trim() === "") {
       handleRefresh(); // You can define fetchData to handle empty search case
     } else {
       handleSearch(value.trim());
     }
   }, 300);
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleFilterRefresh = () => {
    // Clear search
    SetSearch("");
    // setIsSearch(false);

    // Clear filters
    setFilter([]);
    // setIsFilter(false);
    // filterEmptySelected();

    // Clear Checkbox
    // setSelectedRows([]);

    // Fetch data again
    getAllCareer({ page: activepage, limit: recperpage });
  };

  return (
    <>
      <Container
        fluid
        className="p-0 custom-sidebar"
        style={{
          backgroundColor: "#F2F5F7",
          fontFamily: "Inter",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  {" "}
                  {/* Set width to 20% */}
                  <InputGroup
                    className="my-2"
                    style={{
                      borderRadius: "8px", // Rounded corners
                      border: "1px solid #D1D5DB", // Light border
                      backgroundColor: "#F8F9FB", // Light background
                      height: "43px", // Fixed height
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        height: "100%", // Ensures the icon container takes the full height
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                    </div>

                    <Form.Control
                      placeholder="Search Applicant Name"
                      aria-label="Search"
                      name="search"
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none", // No inner border
                        fontFamily: "Inter",
                        paddingLeft: "0px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        fontSize: "16px", // Adjust font size
                        color: "#6B7280", // Placeholder color
                        height: "100%", // Ensures the input field takes the full height
                        fontSize: "12px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                    />
                  </InputGroup>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  {/* Button Group */}
                  <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                    {/* Filter Button */}
                    <div className="d-flex align-items-center mx-2">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        onClick={toggleFilterPanel}
                        style={{
                          backgroundColor: "#BBC5EA",
                          borderColor: "#BBC5EA",
                          color: "#000000",
                          height: "43px",
                          padding: "11px 15px",
                          borderRadius: "8px",
                          // marginLeft: "12px", // Added margin to give more space between buttons
                        }}
                      >
                        Filter&nbsp;
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>

                    {/* Refresh Icon */}
                    <div className="d-flex align-items-center mx-1">
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "auto", width: "auto" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  <thead className="bg-transparent ">
                    <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th style={{ width: "15%" }}>Position Applied For</th>
                      <th>Action</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            <tr
                              className="tableRows "
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/dashboard/candidate-profile/${item._id}`
                                )
                              }
                            >
                              <td>{sno + index + 1}</td>
                              <td>{`${item.firstname} ${item.lastname}`}</td>
                              <td>{item.email}</td>
                              <td>{item.contactno}</td>
                              <td className="d-flex flex-column ">
                                {item.applied_position}
                              </td>
                              <td>
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(
                                      `${serverAddress}/job_application/preview?file_path=${item.image}`
                                    );
                                  }}
                                  className="px-2"
                                  style={{
                                    backgroundColor: "#663399",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                    lineHeight: "35px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  Download Resume
                                </button>
                              </td>

                              <td>
                                {format(
                                  new Date(item.created_at),
                                  "dd/MM/yyyy"
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {data.length == "0" && (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <h1 className="text-muted">NO DATA</h1>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {data && data.length > 0 && (
                // <div className="tableEnd"></div>
                <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                  <div className="col-md-4 d-flex gap-2 align-baseline">
                    <label
                      className="control-label col-md-4"
                      style={{
                        fontWeight: "700",
                        paddingTop: "5px",
                        paddingLeft: "25px",
                      }}
                    >
                      Rows per page :
                    </label>
                    {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                    <Form.Select
                      className="form-control "
                      onChange={(event) => SetRecPerPage(event.target.value)}
                      // onChange={(event) => applyFilter(event)}
                      value={recperpage}
                      style={{
                        border: "solid",
                        borderWidth: "thin",
                        color: "#000",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </Form.Select>
                  </div>

                  <div
                    className="col-md-8 d-flex gap-2"
                    style={{
                      justifyContent: "end",
                      alignItems: "center",
                      paddingRight: "60px",
                    }}
                  >
                    <Pagination
                      total={totalData}
                      showSizeChanger={false}
                      current={activepage}
                      size="small"
                      // showQuickJumper
                      pageSize={recperpage}
                      onChange={(page, pageSize) => {
                        SetActivePage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <FilterCareerTable />
      </div>
    </>
  );
};
export default NewCareerTable;
