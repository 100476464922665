import React from 'react'

const AlumniProgress = () => {
  return (
    <>

<div className='ProgressBar mx-3 '>
         
        </div>
    </>
  )
}

export default AlumniProgress;