import React, { useEffect, useState } from "react";
import { serverAddress } from "../../state/constants/content_types";
import LawAPI from "../../utils/api/LawAPI";

const StudentInfo = ({ data }) => {
  const [key, setKey] = useState("");

  useEffect(() => {
     console.log(data);
    // console.log(key);
  }, [key]);

  return (
    <>
      {data && data._id && (
        <div
          className="lead-remarks mx-3"
          style={{ marginTop: "96px", height: "100vh", overflowY: "auto" }}
        >
          <div className="">
            <div
              className=""
              style={{
                fontSize: "22px",
                fontWeight: "500",
                paddingLeft: "22px",
              }}
            >
              PERSONAL INFORMATION
            </div>
            <div
              className=""
              style={{
                marginInline: "15px",
                marginTop: "5px",
                height: "2px",
                backgroundColor: "#E5E9EB",
                width: "97%",
              }}
            >
              {" "}
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="spContent31 mx-3">
              <div className="lpContent32">Name :</div>
              <div className="lpContent33">
                {data.name}
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Mobile :</div>
              <div className="lpContent33">{data?.mobile_number}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">E-mail :</div>
              <div className="lpContent33">{data?.email_address}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Course Name :</div>
              <div className="lpContent33">{data?.course}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Batch :</div>
              <div className="lpContent33">
                {data?.batch} 
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Company :</div>
              <div className="lpContent33">{data.company} </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Designation :</div>
              <div className="lpContent33">{data?.designation}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Presentation :</div>
              <div className="lpContent33">{data?.presentation}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Presentation Detail:</div>
              <div className="lpContent33">{data?.presentation_detail}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Support :</div>
              <div className="lpContent33">{data?.support}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Suggestion :</div>
              <div className="lpContent33">{data?.suggestion}</div>
            </div>

            
          </div>

        </div>
      )}
    </>
  );
};

export default StudentInfo;
